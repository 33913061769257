import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const PrivacyStyled = styled.section`
  position: relative;

  .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 100%;
    padding: 0;
    .inner {
      margin: 0 auto;
      padding: 0 40px;
      max-width: 1280px;
      ${mq[0]} {
        padding: 0 18px;
      }
      h1 {
        margin-bottom: 22px;
        padding: 20px 0 0;
        text-align: left;
        ${mq[0]} {
          margin-bottom: 20px;
        }
        img {
          ${mq[0]} {
            width: 80px;
          }
        }
      }
    }
  }

  .contents {
    ${mq[0]} {
      padding: 0 20px;
    }
    .inner {
      margin: 0 auto;
      padding: 119px 0;
      max-width: 1200px;
      font-size: 14px;
      line-height: 2;
      ${mq[0]} {
        padding: 42px 0;
        font-size: 13px;
      }
      .title1 {
        text-align: center;
        font-family: 'NanumSquareB';
        font-size: 35px;
        line-height: normal;
        margin-bottom: 80px;
        ${mq[0]} {
          font-size: 25px;
          line-height: 1.6;
          margin-bottom: 13px;
        }
      }
      .title2 {
        font-family: 'NanumSquareB';
        font-size: 25px;
        margin-top: 80px;
        margin-bottom: 30px;
        line-height: normal;
        ${mq[0]} {
          font-size: 18px;
          line-height: 1.6;
          margin-top: 40px;
          margin-bottom: 15px;
        }
      }
      .normalText {
        word-break: keep-all;
        ${mq[0]} {
          word-break: break-all;
        }
      }
      .table {
        font-size: 12px;
        padding-top: 30px;
        ${mq[0]} {
          overflow-x: auto;
        }
        table {
          width: 1200px;
          caption {
            width: 0px;
            height: 0px;
            font-size: 0px;
            line-height: 0;
            margin: 0px;
            padding: 0px;
            overflow: hidden;
          }
          thead {
            tr {
              th {
                text-align: center;
                background: #f5f5f5;
                padding: 8px 0;
              }
            }
          }
          tbody {
            tr {
              th,
              td {
                padding: 13px 20px;
                border-bottom: 1px solid #ddd;
                word-break: keep-all;
                font-family: 'NanumSquareR';
                font-weight: 400;
              }
            }
          }
        }
      }

      .tableS {
        table {
          font-size: 12px;
          width: 100%;
          caption {
            width: 0px;
            height: 0px;
            font-size: 0px;
            line-height: 0;
            margin: 0px;
            padding: 0px;
            overflow: hidden;
          }
          tbody {
            th {
              vertical-align: top;
            }
          }
        }
      }

      .tableList {
        border-left: 1px solid #ddd;
        padding-left: 20px;
        margin-top: 30px;
        dt {
          font-size: 16px;
          line-height: 1.75;
          font-family: 'NanumSquareB';
          padding-bottom: 10px;
        }
      }

      .column2 {
        padding-bottom: 30px;
        ${mq[0]} {
          overflow-x: auto;
        }
        .tableWrap {
          width: 1200px;
          overflow: hidden;
          .table {
            float: left;
            width: calc(100% - 612px);
            &:first-of-type {
              margin-right: 24px;
            }
            table {
              width: 100%;
            }
          }
        }
      }
      .tableDescription {
        padding: 60px 0 30px;
      }
      .ulListTitle {
        font-family: 'NanumSquareB';
        padding-top: 30px;
      }
      .ulListDescription {
        padding-bottom: 30px;
        font-family: 'NanumSquareB';
      }
      .ulList {
        li {
          display: table;
          span {
            display: table-cell;
            word-break: keep-all;
            &.no {
              padding-right: 10px;
            }
          }
        }
      }
      .olList {
        dt {
          padding-top: 30px;
          font-family: 'NanumSquareR';
          font-weight: 400;
        }
      }
      .date {
        padding-top: 30px;
        font-family: 'NanumSquareB';
        line-height: 2;
      }
    }
  }
`;

export default PrivacyStyled;
