import React, { useState, useEffect } from 'react';
import {
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid,
  BarChart,
  Bar,
  Cell,
  LabelList
} from 'recharts';
import { Colors } from '../../pages/ChargeUsage';

const CustomLabel = props => {
  const { x, y, width, value, colors, index } = props;
  return (
    <text
      x={x + width / 2}
      y={y}
      dy={-15}
      fill={index === 0 ? '#888' : colors}
      fontSize={22}
      textAnchor="middle"
    >{`${value}㎥`}</text>
  );
};

function ProperUsage({ usageData, condition, activeRequestYm }) {
  const [description, setDescription] = useState();
  const [properColors, setProperColors] = useState();
  const requestYmParser = () => {
    return (
      activeRequestYm.substr(0, 4) +
      '년 ' +
      Number(activeRequestYm.substr(4, 2)) +
      '월'
    );
  };
  useEffect(() => {
    if (usageData) {
      console.log('ProperUsage : ' + JSON.stringify(usageData));
      let usage = usageData[1].usage - usageData[0].usage;
      if (usage === 0) {
        setDescription(
          <p className="description">
            에너지 절약을 실천하고 계시네요!
            <br />
            적정 사용량만큼 사용했어요
          </p>
        );
        setProperColors(Colors.YELLOW);
      } else if (usage > 0) {
        setDescription(
          <p className="description">
            에너지 절약을 위한 적정 사용량보다 <br />
            <span className="" style={{ color: Colors.ORANGE }}>
              {usage}㎥ 더 많이 사용
            </span>{' '}
            했어요
          </p>
        );
        setProperColors(Colors.ORANGE);
      } else {
        setDescription(
          <p className="description">
            에너지 절약을 실천하고 계시네요!
            <br />
            적정 사용량보다{' '}
            <span className="" style={{ color: Colors.GREEN }}>
              {Math.abs(usage)}㎥ 더 절약
            </span>
            했어요
          </p>
        );
        setProperColors(Colors.GREEN);
      }
    }
    return () => {};
  }, [usageData]);

  return (
    <div className="proper-usage">
      {/* {activeRequestYm && (
        <p className="sub_title etc">
          {requestYmParser()} 사용량 분석{' '}
          <span className="">
            | {condition.conditionCity.name}, {condition.conditionArea.name},{' '}
            {condition.conditionMemberCount.value}인 가구
          </span>
        </p>
      )} */}

      <p className="sub_title">적정 사용량</p>
      <div className="charts">
        <div>
          <BarChart
            data={usageData}
            margin={{ top: 20, right: 30, left: 30, bottom: 0 }}
            width={360}
            height={180}
          >
            <XAxis
              dataKey="label"
              axisLine={false}
              tickLine={false}
              fontSize={14}
            />
            <YAxis hide={true} padding={{ top: 20 }} />
            <CartesianGrid
              vertical={false}
              horizontal={true}
              horizontalPoints={[150]}
              strokeDasharray={['1 0']}
              stroke={'#ccc'}
            />
            <ReferenceLine
              y={usageData[0].usage}
              stroke="#ddd"
              strokeDasharray="3 3"
            />

            <Bar
              dataKey="usage"
              barSize={64}
              animationBegin={0}
              animationDuration={0}
            >
              <LabelList
                dataKey="usage"
                content={<CustomLabel colors={properColors} />}
              />
              {usageData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === 1 ? properColors : '#ddd'}
                  radius={[6, 6, 0, 0]}
                />
              ))}
            </Bar>
          </BarChart>
          {description}
        </div>
      </div>
    </div>
  );
}

export default ProperUsage;
