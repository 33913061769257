import React from 'react';
import { css } from '@emotion/core';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import PayStyled from './Pay.Styled';
import {
  img_02_2x_en,
  img_02_paper_2x,
  pay_circle
} from '../../../resources/images';
import { contents } from '../../../constants/contents';

const id = 'PAY';

export default function Pay(props) {
  return (
    <PayStyled id={id} className={`payAni1`}>
      <div>
        <div className={`contentsText`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.fr[id].content1}
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>

        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-100}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.5
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`circle`} src={pay_circle} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-200}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: -120,
                    y: 70,
                    rotation: -20
                  }}
                  to={{
                    opacity: 1,
                    x: 0,
                    y: 0,
                    rotation: 0
                  }}
                >
                  <img className={`paper`} src={img_02_paper_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-150}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: 100
                  }}
                  to={{
                    opacity: 1,
                    x: 0
                  }}
                >
                  <img className={`phone`} src={img_02_2x_en} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </PayStyled>
  );
}
