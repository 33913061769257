import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const PayStyled = styled.section`
  padding: 368px 0 369px;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    position: relative;
    padding: 0 40px 0 345px;
    ${mq[0]} {
      padding: 0;
    }

    .contentsText {
      ${mq[0]} {
        padding: 0 14px;
        max-width: 380px;
        margin: 0 auto;
      }
    }

    span {
      font-size: 20px;
      display: block;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.4);
      ${mq[0]} {
        font-size: 10px;
      }
    }
    h2 {
      font-size: 60px;
      margin-bottom: 18px;
      ${mq[0]} {
        font-size: 30px;
        margin-bottom: 15px;
      }
      &.en {
        line-height: 1em;
      }
    }
    p {
      font-family: 'NanumSquareL';
      font-size: 20px;
      line-height: 1.75;
      ${mq[0]} {
        font-size: 13px;
        margin-bottom: 67px;
      }
    }
    figure {
      position: absolute;
      top: 0;
      right: 38px;
      ${mq[0]} {
        text-align: center;
        position: relative;
        top: auto;
        right: auto;
      }
      .circle {
        position: absolute;
        top: 45px;
        right: 30px;
        ${mq[0]} {
          width: 125px;
          height: 125px;
          top: 72px;
          right: auto;
          left: 50%;
        }
      }
      .phone {
        position: absolute;
        width: 230px;
        top: -73px;
        right: 105px;
        ${mq[0]} {
          position: relative;
          width: 165px;
          top: auto;
          right: auto;
          margin-left: 25px;
        }
      }
      .paper {
        position: absolute;
        width: 241px;
        right: 248px;
        top: -135px;
        ${mq[0]} {
          width: 151px;
          top: -45px;
          left: auto;
          right: 50%;
        }
      }
    }
  }
`;
export default PayStyled;
