import React from 'react';
import GateStyled from '../components/cash/Gate.Styled';
import YouTube from 'react-youtube';

function NaverGateLiveBill() {
  const videoOptions = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0
    }
  };
  return (
    <React.Fragment>
      <GateStyled>
        <div>
          <h3 className="title">
            가스요금 폭탄, 걱정되시죠?
            <br />
            실시간 요금을 확인해보세요
          </h3>
          <p className="sub-title">
            우리집 계량기를 찰칵! 찍기만해도
            <br />
            지금까지 얼마나 사용했는지는 물론
            <br />
            이번 달 예상 요금까지 확인할 수 있어요.
          </p>
          <div className="image-wrap">
            <YouTube videoId={'Fm6FsnP30GU'} opts={videoOptions} />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              window.open('https://mobileservicecenter.page.link/livebill');
            }}
          >
            가스앱 설치하고 실시간 요금조회
          </button>
        </div>
      </GateStyled>
    </React.Fragment>
  );
}

export default NaverGateLiveBill;
