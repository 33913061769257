import { css } from '@emotion/core';
import ResetStyled from './Reset.Styled';

const ResetTranslationStyled = css`
  ${ResetStyled}

  html {
    font-family: 'Noto Sans' !important; // 2
    font-size: 12px;
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 120%; // 4
    -webkit-tap-highlight-color: transparent; // 5
  }

  body {
    margin: 0; // 1
    font-family: 'Noto Sans' !important; // 4
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    text-align: left; // 3
    background-color: #ffffff; // 2
  }

  // Code
  pre,
  code,
  kbd,
  samp {
    font-family: 'Noto Sans', SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
    // 모든 브라우저에서 글꼴 단위를 em 으로 수정.
    font-size: 1em;
  }
`;

export default ResetTranslationStyled;
