import styled from '@emotion/styled';

export const StyledLoadingBar = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 200;
  cursor: pointer;

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 200;
    height: 1.5rem;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 12rem;
  }

  .loader span {
    opacity: 0;
    margin: 0 0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    float: left;
  }

  .loader .l1 {
    animation: load1 3.5s infinite;
    -webkit-animation: load1 3.5s infinite;
    background-color: var(--color-blue);
  }

  .loader .l2 {
    animation: load2 3.5s infinite;
    -webkit-animation: load2 3.5s infinite;
    background-color: var(--color-green);
  }

  .loader .l3 {
    animation: load3 3.5s infinite;
    -webkit-animation: load3 3.5s infinite;
    background-color: var(--color-yellow);
  }

  .loader .l4 {
    animation: load4 3.5s infinite;
    -webkit-animation: load4 3.5s infinite;
    background-color: var(--color-purple);
  }

  .loader .l5 {
    animation: load5 3.5s infinite;
    -webkit-animation: load5 3.5s infinite;
    background-color: var(--color-mint);
  }

  .loader .l6 {
    animation: load6 3.5s infinite;
    -webkit-animation: load6 3.5s infinite;
    background-color: var(--color-red);
  }

  @keyframes load1 {
    0%,
    80% {
      opacity: 0;
    }

    1%,
    79% {
      opacity: 1;
    }
  }

  @keyframes load2 {
    0%,
    10%,
    80% {
      opacity: 0;
    }

    11%,
    79% {
      opacity: 1;
    }
  }

  @keyframes load3 {
    0%,
    20%,
    80% {
      opacity: 0;
    }

    21%,
    79% {
      opacity: 1;
    }
  }

  @keyframes load4 {
    0%,
    30%,
    80% {
      opacity: 0;
    }

    31%,
    79% {
      opacity: 1;
    }
  }

  @keyframes load5 {
    0%,
    40%,
    80% {
      opacity: 0;
    }

    41%,
    79% {
      opacity: 1;
    }
  }

  @keyframes load6 {
    0%,
    50%,
    80% {
      opacity: 0;
    }

    51%,
    79% {
      opacity: 1;
    }
  }
`;
