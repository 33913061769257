import React from 'react';
import GasAppStyled from './GasApp.Styled';
import {
  logo,
  img_new_01,
  img_new_02,
  img_new_03,
  bubbleMsg01,
  bubbleMsg02,
  bubbleMsg03,
  bubbleMsg04
} from '../../../resources/images/';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Link } from 'react-router-dom';
import { contents } from '../../../constants/contents';

const id = 'GASAPP';

export default function GasApp(props) {
  return (
    <GasAppStyled id={id}>
      <div className={`top`}>
        <div className={`inner`}>
          <h1>
            <img src={logo} alt={`SCGlab`} />
          </h1>
          <p className="origin">
            <span className="lang ">
              <Link className="active" to={`/`}>
                KR
              </Link>
            </span>
            <span className="bl"></span>
            <span className="lang ">
              <Link to={`/en`}>EN</Link>
            </span>
            <span className="bl"></span>
            <span className="lang ">
              <Link to={`/fr`} className="">
                FR
              </Link>
            </span>
          </p>
          <Tween
            delay={0.5}
            duration={0.7}
            ease="Cubic.easeInOut"
            stagger={0.2}
            staggerFrom={{
              opacity: 0,
              scale: 0.8,
              y: 30
            }}
            staggerTo={{
              opacity: 1,
              scale: 1,
              y: 0
            }}
          >
            {/* <span>2022년 </span> */}
            {contents.kor[id].content1}
          </Tween>
        </div>
        <ul className={`phones`}>
          <Tween
            delay={0.7}
            duration={0.5}
            ease="Cubic.easeInOut"
            stagger={0.2}
            staggerFrom={{
              opacity: 0,
              y: -100
            }}
            staggerTo={{
              opacity: 1,
              y: 0
            }}
          >
            <li>
              <img src={img_new_03} alt={``} className="img-phone" />
            </li>
            <li>
              <img src={img_new_02} alt={``} className="img-phone" />
              <img src={bubbleMsg01} alt="" className="img-msg img-msg01" />
              <img src={bubbleMsg02} alt="" className="img-msg img-msg02" />
            </li>
            <li>
              <img src={img_new_01} alt={``} className="img-phone" />
              <img src={bubbleMsg03} alt="" className="img-msg img-msg03" />
              <img src={bubbleMsg04} alt="" className="img-msg img-msg04" />
            </li>
          </Tween>
        </ul>
      </div>

      <div className={`bot`}>
        <div className={`inner`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.kor[id].content2}
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline wrapper={<ul className={`icon`} />}>
                <Tween
                  stagger={0.5}
                  staggerFrom={{
                    opacity: 0,
                    scale: 0.7
                  }}
                  staggerTo={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  {contents.kor[id].content_quantitative}
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.kor[id].content3}
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-280}>
              <Timeline wrapper={<div className={`award`} />}>
                <Tween
                  stagger={0.5}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.kor[id].content_award}
                </Tween>
              </Timeline>
            </Scene>

            <Scene indicators={false} duration="200" offset={-280}>
              <Timeline wrapper={<div className={`update-time`} />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <p className={`etcText`}>※ 2024년 03월 기준</p>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
      </div>
    </GasAppStyled>
  );
}
