import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid
} from 'recharts';

import { Colors } from '../../pages/ChargeUsage';

const MAX_USAGE = 200;

const CustomLabel = props => {
  const { value, viewBox, color } = props;
  return (
    <>
      <rect
        x={viewBox.x - 60}
        y={viewBox.y - 40}
        width={120}
        height={32}
        fill={color}
        rx={16}
      ></rect>
      <rect
        x={viewBox.x - 1}
        y={viewBox.y - 40}
        width={2}
        height={170}
        fill={color}
      ></rect>
      <text
        x={viewBox.x}
        y={viewBox.y - 19}
        fill="#fff"
        fontSize={16}
        textAnchor="middle"
      >
        우리집 {value}㎥
      </text>
    </>
  );
};
const AverageCustomLabel = props => {
  const { value, viewBox } = props;
  return (
    <>
      <rect
        x={viewBox.x - 45}
        y={viewBox.y + 50}
        width={90}
        height={32}
        fill={'rgba(255, 255, 255)'}
        rx={16}
        stroke="#ddd"
      />
      <text
        x={viewBox.x}
        y={viewBox.y + 70}
        fill="#999"
        fontSize={14}
        textAnchor="middle"
      >
        평균 {value}㎥
      </text>
    </>
  );
};

function UsageDistribution({ usageData, colors }) {
  const [usageDistributionLineData, setUsageDistribution] = useState(null);
  // const [distributionColors, setDistributionColors] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    onChangeUsageDistribution();
    if (usageData && usageData[1].percent) {
      console.log(
        'UsageDistribution : ' + usageData[0].usage + ' / ' + usageData[1].usage
      );
      let percent =
        usageData[0].usage === usageData[1].usage ? 50 : usageData[1].percent;
      if (50 > percent) {
        // setDistributionColors(Colors.GREEN);
        setDescription(
          <p className="description">
            우리집 사용량은 동일 그룹 내<br />
            <span style={{ color: colors }}>
              하위 {Math.max(percent, 0)}%
            </span>{' '}
            수준이에요
          </p>
        );
      } else if (50 < percent) {
        // setDistributionColors(Colors.ORANGE);
        setDescription(
          <p className="description">
            우리집 사용량은
            <br />
            동일 그룹 내{' '}
            <span style={{ color: colors }}>
              상위 {100 - Math.min(percent, 100)}%{' '}
            </span>{' '}
            수준이에요
          </p>
        );
      } else {
        // setDistributionColors(Colors.YELLOW);
        setDescription(
          <p className="description">
            우리집 사용량은 동일 그룹 내<br />
            <span style={{ color: colors }}>
              상위 {100 - Math.min(percent, 100)}%{' '}
            </span>{' '}
            수준이에요
          </p>
        );
      }
    }
    return () => {};
  }, [colors, usageData]);

  // 사용량 분포 차트 데이터
  const onChangeUsageDistribution = () => {
    const data = [];
    // const sigma = MAX_USAGE / 100; // 표준 편차
    let count = 0;
    const value = MAX_USAGE / 10 / 4;
    for (let i = -value; i <= value; i += 0.1) {
      // const x = i;
      // const y =
      //   (1 / (sigma * Math.sqrt(2 * Math.PI))) *
      //   Math.exp(-Math.pow(x, 2) / (2 * Math.pow(sigma, 2)));
      data.push({ count: count });
      ++count;
    }
    setUsageDistribution(data);
  };
  return (
    <div className="usage-distribution">
      <p className="sub_title">사용량 분포</p>
      <div className="charts">
        <div>
          <AreaChart
            data={usageDistributionLineData}
            margin={{ top: 40, bottom: 0 }}
            width={400}
            height={200}
          >
            <XAxis
              dataKey="count"
              interval={19}
              axisLine={false}
              tickLine={false}
              padding={{
                left: 60,
                right: 60
              }}
            />
            <YAxis hide={true} />
            <CartesianGrid
              vertical={false}
              horizontal={true}
              horizontalPoints={[170]}
              strokeDasharray={['1 0']}
              stroke={'#ccc'}
            />
            {/* <Area
                type="monotone"
                dataKey="value"
                stroke={'rgba(255, 255, 255,0)'}
                strokeWidth={1}
                fill={`none`}
                isAnimationActive={false}
              /> */}
            <ReferenceLine
              x={
                usageData[0].usage === usageData[1].usage
                  ? 50
                  : Math.min(Math.max(usageData[1].percent, 0), 100)
              }
              stroke={'rgba(255, 255, 255,0)'}
              strokeDasharray="1 0"
              // label={{
              //   position: 'top',
              //   value: usageData[1].label + ' ' + usageData[1].usage
              // }}
              label={<CustomLabel value={usageData[1].usage} color={colors} />}
            />
            <ReferenceLine
              x={50}
              yAxis={40}
              stroke={'rgba(255, 255, 255,0)'}
              strokeDasharray="3 3"
              // label={{
              //   position: 'top',
              //   value: usageData[0].label + ' ' + usageData[0].usage
              // }}
              label={<AverageCustomLabel value={usageData[0].usage} />}
            />
          </AreaChart>
          {description}
        </div>
      </div>
    </div>
  );
}

export default UsageDistribution;
