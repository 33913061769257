import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import Timer from '../components/lucky/Timer';
import ImgRaffleTitle from '../resources/images/lucky/img-market2.png';
import IcoShare from '../resources/images/lucky/ico-share.png';
import IcoLink from '../resources/images/lucky/ico-link.png';
import IcoKakao from '../resources/images/lucky/ico-kakao.png';
import ImgSummerEvent1 from '../resources/images/lucky/img-summer-1.png';
import ImgSummerEvent2 from '../resources/images/lucky/img-summer-2.png';
import ImgSummerEvent3 from '../resources/images/lucky/img-summer-3.png';
import ImgSummerEvent4 from '../resources/images/lucky/img-summer-4.png';
import ImgSummerEvent5 from '../resources/images/lucky/img-summer-5.png';
import ImgRouletteEvent from '../resources/images/lucky/img-roulette.png';
import ImgNoti from '../resources/images/lucky/img-noti3.png';
import ImgButton from '../resources/images/lucky/img-button.png';
import { productBg, iconBack, iconFront, iconTimer } from '../resources/images';
import {
  toRemLucky,
  StackStyle,
  FontStyle,
  Stack,
  RaffleColor,
  WunderPaper
} from '../utils/utils';
import axios from 'axios';
import DeviceUtil from '../utils/DeviceUtil';
import moment from 'moment/moment';
import { BottomSheet, Image, Button, Text } from '@gasapp/ui';

const TimerWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${toRemLucky(136)};
  background: rgba(224, 224, 224, 0.65);
  gap: ${toRemLucky(10)};
  img {
    width: ${toRemLucky(60)};
  }
`;

const SummerEventWrap = styled.div`
  .round_btn {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
    overflow: hidden;
    text-align: center;
    color: #fff !important;
    font-family: 'nanumsquareB';
    display: block;
  }
`;

const SummerWrap = styled.div`
  box-sizing: border-box;
  // max-width: 360px;
  padding: 0 ${toRemLucky(75)};
  margin: 0 auto;
  text-align: center;
  width: 100%;
  &.info {
    img {
      width: ${toRemLucky(300)};
    }
  }
  &.button {
    cursor: pointer;
  }
  .summer_bg {
    // height: ${toRemLucky(530)} !important;
    // &.summer_bg1 {
    //   background: #328bff1a url(${ImgSummerEvent1}) no-repeat right center !important;
    //   background-size: ${toRemLucky(552)} ${toRemLucky(530)} !important;
    // }
    // &.summer_bg2 {
    //   background: #29b6f61a url(${ImgSummerEvent2}) no-repeat right center !important;
    //   background-size: ${toRemLucky(552)} ${toRemLucky(530)} !important;
    // }
    // &.summer_bg3 {
    //   background: #c7e07c1a url(${ImgSummerEvent3}) no-repeat right center !important;
    //   background-size: ${toRemLucky(552)} ${toRemLucky(530)} !important;
    // }
    // &.summer_bg4 {
    //   background: #ffd16b1a url(${ImgSummerEvent4}) no-repeat right center !important;
    //   background-size: ${toRemLucky(552)} ${toRemLucky(530)} !important;
    // }
  }
`;
const DefaultBox = styled.div`
  width: 100%;

  margin: 0 auto;
  text-align: center;
  &.gray {
    background: #bbbbbb26;
  }
`;

function SummerEvent() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const [mid, setMid] = useState(null);
  const [env, setEnv] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const mid = queryParams.get('mid');
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
    setMid(mid);
  }, [queryParams]);

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content =
      'width=340, initial-scale=1, height=device-height, maximum-scale=1.0 ,user-scalable=no';

    // document.head에 메타태그 추가
    document.head.appendChild(metaTag);
    console.log(DeviceUtil.isMobile);
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  //   const onClickButton = useCallback(() => {
  //     console.log(navigator);
  //     window.gtag('event', '예스코_이벤트_가스앱설치');
  //     const url = `https://m.gasapp.co.kr/app/launcherGasApp.html`;
  //     window.open(url);
  //   }, []);

  const shareItem = {
    image:
      'https://cdn-app.gasapp.co.kr/attach/event/lucky/img-kakao-event-share.png',
    title: `가스앱 이벤트에 초대했어요!`,
    description:
      '행운의 마켓, 출석 체크, 냉방비 지원, 사다리 타기 등 돈버는 이벤트가 한 가득!',
    url: 'http://www.scglab.com/#/summerEvent',
    buttonName: '이벤트 둘러보기',
    linkShareUrl: 'summerEvent.html',
    webUrl: 'https://www.scglab.com/'
  };

  const copyLink = () => {
    let prevUrl =
      shareItem.webUrl + shareItem.linkShareUrl + '?link=' + shareItem.url;

    const el = document.createElement('textarea');
    el.value = prevUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('링크가 복사되었습니다!');
  };

  const shareKakao = () => {
    if (window.Kakao) {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          imageHeight: 800,
          imageWidth: 800,
          title: shareItem.title,
          description: shareItem.description,
          imageUrl: shareItem.image,
          link: {
            mobileWebUrl: shareItem.url
          }
        },
        installTalk: true,
        itemContent: {
          profileText: '가스앱',
          profileImageUrl:
            'https://cdn-app-dev.gasapp.co.kr/attach/raffle/kakaotalk/ico-gasapp.png'
        },
        buttons: [
          {
            title: '이벤트 둘러보기',
            link: {
              mobileWebUrl: shareItem.url
            }
          }
        ]
      });
    }
  };

  const onClickBanner = shareUrl => {
    let url = null;
    if (env === 'production') {
      url = `https://gasappmobile.page.link/${shareUrl}`;
    } else {
      url = `https://gasappdev.page.link/${shareUrl}`;
    }
    window.open(url);
  };

  return (
    <div
      style={{
        maxWidth: DeviceUtil.isMobile ? '500px' : '360px',
        background: '#fff',
        margin: '0 auto'
      }}
    >
      <SummerEventWrap>
        {DeviceUtil.isMobile && (
          <Stack
            style={{
              padding: `${toRemLucky(49)} ${toRemLucky(80)}`,
              textAlign: 'right',
              justifyContent: 'flex-end'
            }}
          >
            <div
              style={{ width: toRemLucky(70) }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <img src={IcoShare} alt="" width="100%" />
            </div>
          </Stack>
        )}

        <Stack direction="column" gap={100}>
          <DefaultBox
            onClick={() => {
              window.open('https://gasappmobile.page.link/summerEvent');
            }}
          >
            <img src={ImgRaffleTitle} alt="" width="100%" />
          </DefaultBox>
          <SummerWrap>
            <Stack gap={100} direction="column">
              {/* <WunderPaper
                className="nopadding summer_bg"
                onClick={() => onClickBanner('hanbillshare')}
              >
                <img src={ImgSummerEvent1} alt="" width="100%" />
              </WunderPaper> */}
              {/* <WunderPaper
                className="nopadding summer_bg"
                onClick={() => onClickBanner('luckyRoulette')}
              >
                <img src={ImgRouletteEvent} alt="" width="100%" />
              </WunderPaper> */}
              <WunderPaper
                className="nopadding summer_bg"
                onClick={() => onClickBanner('boilerSaleEvent')}
              >
                <img src={ImgSummerEvent5} alt="" width="100%" />
              </WunderPaper>
              <WunderPaper
                className="nopadding summer_bg"
                onClick={() => onClickBanner('laddershare')}
              >
                <img src={ImgSummerEvent4} alt="" width="100%" />
              </WunderPaper>
              <WunderPaper
                className="nopadding summer_bg"
                onClick={() => onClickBanner('nasMediashare')}
              >
                <img src={ImgSummerEvent2} alt="" width="100%" />
              </WunderPaper>
              {/* <WunderPaper
                className="nopadding summer_bg"
                onClick={() => onClickBanner('raffleshare')}
              >
                <img src={ImgSummerEvent3} alt="" width="100%" />
              </WunderPaper> */}
            </Stack>
          </SummerWrap>

          <DefaultBox className="gray">
            <img
              src={ImgNoti}
              alt=""
              width="100%"
              style={{ background: '#fff' }}
            />
          </DefaultBox>
        </Stack>
      </SummerEventWrap>
      <BottomSheet
        isOpen={isOpen}
        headerCloseButton={true}
        header={<Text variant="64B">가스앱 이벤트 공유하기</Text>}
        cancel={() => setIsOpen(false)}
        cancelButton={false}
        okButton={false}
      >
        <Stack
          justifyItems="center"
          direction="row"
          style={{ marginTop: '0.3rem' }}
        >
          <Stack
            gap={20}
            alignItems="center"
            justifyContent="center"
            direction="column"
            style={{ flex: '1' }}
            onClick={copyLink}
          >
            <Image image={IcoLink} width={200} />
            <Text variant="44" textAlign="center">
              링크 복사
            </Text>
          </Stack>
          <Stack
            gap={20}
            alignItems="center"
            justifyContent="center"
            direction="column"
            style={{ flex: '1' }}
            onClick={shareKakao}
          >
            <Image image={IcoKakao} width={200} />
            <Text variant="44" textAlign="center">
              카카오톡
            </Text>
          </Stack>
        </Stack>
      </BottomSheet>
    </div>
  );
}

export default SummerEvent;
