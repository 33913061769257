import React, { useReducer, useEffect } from 'react';
import { css } from '@emotion/core';
import SideMenuStyled from './SideMenu.Styled';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/src/ScrollToPlugin';
import { ExpoScaleEase, RoughEase, SlowMo } from 'gsap/EasePack';
import { menus } from '../constants/menu';
import _ from 'lodash';

gsap.registerPlugin(ScrollToPlugin, ExpoScaleEase, RoughEase, SlowMo);

function reducer(state, action) {
  switch (action.type) {
    case 'GASAPP':
      return { color: `#24c6ff`, top: 0, name: action.type, posY: action.posY };
    case 'NEWGASAPP':
      return {
        color: `#ff6c6d`,
        top: 47,
        name: action.type,
        posY: action.posY
      };
    case 'INTEGRATION':
      return {
        color: `#FFAB00`,
        top: 92,
        name: action.type,
        posY: action.posY
      };
    case 'PRIVATEHOME':
      return {
        color: `#24c6ff`,
        top: 137,
        name: action.type,
        posY: action.posY
      };
    case 'PAY':
      return {
        color: `#9bd847`,
        top: 182,
        name: action.type,
        posY: action.posY
      };
    case 'CHATBOT':
      return {
        color: `#ffcc00`,
        top: 227,
        name: action.type,
        posY: action.posY
      };
    case 'AMI':
      return {
        color: `#79e3db`,
        top: 272,
        name: action.type,
        posY: action.posY
      };
    case 'LIFE':
      return {
        color: `#ff6c6d`,
        top: 317,
        name: action.type,
        posY: action.posY
      };
    case 'GASLOCK':
      return {
        color: `#42dfd3`,
        top: 362,
        name: action.type,
        posY: action.posY
      };
    case 'EFFECT':
      return {
        color: `#ff9f35`,
        top: 407,
        name: action.type,
        posY: action.posY
      };
    default:
      return state;
  }
}

export default function SideMenu(props) {
  const [state, dispatch] = useReducer(reducer, {
    color: `#24c6ff`,
    top: 0,
    name: ``
  });

  useEffect(() => {
    const name = _.map(document.getElementsByTagName('section'), item => {
      if (props.scrollTop + window.innerHeight / 2 > item.offsetTop)
        return item.attributes.id.value;
    });

    let prevName = '';
    name.map(item => {
      if (item) {
        prevName = item;
      }
      return null;
    });

    let effectTop = document.getElementById('EFFECT')
        ? document.getElementById('EFFECT').offsetTop
        : 0,
      posY =
        effectTop - props.scrollTop < 0
          ? css`
              top: calc(50% - ${155 - (effectTop - props.scrollTop)}px);
            `
          : css`
              top: calc(50% - 155px);
            `;

    dispatch({ type: prevName, posY: posY });
  }, [props.scrollTop]);

  const bulletStyled = css`
      position: absolute;
      background-color: ${state.color};
      left: 0;
      top: ${state.top + 16}px;
      transition: all 0.1s linear;
    `,
    menuStyled = css`
      &[name=${state.name}] {
        color:${state.color};
        font-family:'NanumSquareB';
      } 
    `;

  const clickButton = e => {
    let pos = document.getElementById(e.target.name).offsetTop;
    gsap.to(window, { duration: 0.7, scrollTo: { y: pos } });
  };

  return (
    <SideMenuStyled css={state.posY}>
      <div>
        <ul>
          {menus.fr.map(menu => (
            <li key={menu.name}>
              <button css={menuStyled} name={menu.name} onClick={clickButton}>
                {menu.label}
              </button>
            </li>
          ))}
          <li className={`bullet`} css={bulletStyled}>
            &nbsp;
          </li>
        </ul>
      </div>
    </SideMenuStyled>
  );
}
