import React from 'react';
import FooterStyled from './Footer.Styled';
import { logo } from '../resources/images';
import ImgPlayStore from '../resources/images/img_play_store.svg';
import ImgAppleStore from '../resources/images/img_apple_store.svg';
import ImgBlog from '../resources/images/img_blog.svg';
import ImgYoutube from '../resources/images/img_youtube.svg';
import { Link } from 'react-router-dom';

export default function Footer(props) {
  return (
    <FooterStyled>
      <div>
        <div className="top">
          <img src={logo} alt="logo" />
        </div>

        <div className="bottom">
          <div className="left">
            <div className="info">
              에스씨지랩(주) | 사업자 등록번호 105-88-06501 | 대표 박동녘
            </div>
            <dl>
              <dt>
                <Link to={`/Policy`}>개인정보처리방침</Link>
              </dt>
              <dd>Copyright © 2020&nbsp; SCG LAB. All Rights Reserved.</dd>
            </dl>
          </div>

          <div className="center">
            <dl>
              <dt>Address</dt>
              <dd>
                서울특별시 마포구 월드컵북로6길 3 이노베이스 빌딩 3층 5층 6층
              </dd>
            </dl>
            <dl>
              <dt>E-mail</dt>
              <dd>hello@scglab.com</dd>
            </dl>
          </div>

          <div className="right">
            <div className="title">가스앱 앱 다운로드</div>
            <div className="icons">
              <span className="store">
                <img
                  src={ImgAppleStore}
                  alt="appleStore"
                  onClick={() =>
                    window.open('https://apps.apple.com/kr/app/id741949011')
                  }
                />
                <img
                  src={ImgPlayStore}
                  alt="playStore"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.biller.scg'
                    )
                  }
                />
              </span>
              <span className="bl"></span>
              <span className="sns">
                <img
                  src={ImgYoutube}
                  alt="youtube"
                  onClick={() => window.open('https://m.youtube.com/@energy_T')}
                />
                <img
                  src={ImgBlog}
                  alt="blog"
                  onClick={() => window.open('https://blog.naver.com/gasapp/')}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
}
