import React from 'react';
import { useState } from 'react';
import EnergyBillStyled from '../components/energyBill/Energy.Styled';
import EnergyHeader from '../components/energyBill/EnergyHeader';
import EnergyBody01 from '../components/energyBill/EnergyBody01';
import EnergyBody02 from '../components/energyBill/EnergyBody02';
import EnergyBody03 from '../components/energyBill/EnergyBody03';
import EnergyBody04 from '../components/energyBill/EnergyBody04';
import { useLocation } from 'react-router-dom';

function EnergyBill() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyCode = queryParams.get('companyCode');
  const userType = queryParams.get('userType');
  console.log(companyCode);

  const [active, setActive] = useState(0);
  const tabClick = active => {
    setActive(active);
  };
  return (
    <React.Fragment>
      <EnergyBillStyled>
        <EnergyHeader active={active} tabClick={tabClick} />
        {active === 0 && (
          <EnergyBody01 companyCode={companyCode} userType={userType} />
        )}
        {active === 1 && <EnergyBody02 />}
        {active === 2 && <EnergyBody03 />}
        {active === 3 && <EnergyBody04 />}
      </EnergyBillStyled>
    </React.Fragment>
  );
}

export default EnergyBill;
