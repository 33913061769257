import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import DeviceUtil from '../utils/DeviceUtil';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import VideoBackImage from '../resources/images/youtube/video-back.svg';

const YoutubeStyled = styled.div`
  .youtube-wrapper {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ytp-pause-overlay {
    display: none;
  }
  .youtube-cover {
    width: 30px;
    height: 30px;
    z-index: 999;
    position: absolute;
    left: 16px;
    top: 63px;
    img {
      width: 30px;
    }
  }

  .ytp-shorts-mode .ytp-pause-overlay-container {
    display: none;
  }

  .preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 999;
  }
  .preview.display {
    display: none;
  }
`;

function Youtube() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [style, setstyle] = useState('');

  const playerRef = useRef();

  return (
    <YoutubeStyled>
      <div onTouchStart={() => {}} onTouchMove={() => {}} onTouchEnd={() => {}}>
        <ReactPlayer
          ref={playerRef}
          className={`youtube-wrapper video-popup`}
          url={[
            `https://youtube.com/shorts/${queryParams.get(
              'id'
            )}?feature=share&playsinline=1?rel=0&amp;?autoplay=1&mute=1`
          ]}
          allow="fullscreen"
          playing={true}
          autoPlay={true}
          allowFullScreen={true}
          controls={false}
          width="100%"
          height="100%"
          onBufferEnd={e => setstyle('display')}
          onBuffer={() => setstyle('')}
          onProgress={e => {
            if (e.loadedSeconds === e.playedSeconds) {
              setstyle('');
            }
          }}
          config={{
            file: {
              attributes: { poster: queryParams.get('poster') },
              preload: 'auto'
            },
            youtube: {
              playerVars: {
                autoplay: 1,
                mute: 1,
                controls: 1,
                modestbranding: 1,
                enablejsapi: 1,
                fs: 0,
                loop: 1,
                playsinline: 1
              }
            }
          }}
        />
        <div
          className="youtube-cover"
          style={{ display: 'unset' }}
          onClick={() => {
            try {
              if (DeviceUtil.isAndroid) {
                window.android.closePopupWindow();
              } else if (DeviceUtil.isIOS) {
                window.webkit.messageHandlers.observe.postMessage(
                  JSON.stringify({
                    action: 'closePopupWindow'
                  })
                );
              }
            } catch (e) {}
          }}
        >
          <img className="small" src={VideoBackImage} alt="영상 닫기" />
        </div>

        <div
          id="loadingbar"
          className={`preview ${style}`}
          style={{
            background: 'no-repeat center / contain',
            backgroundColor: '#000',
            backgroundImage: `url(${queryParams.get('poster')})`
          }}
        ></div>
      </div>
    </YoutubeStyled>
  );
}

export default Youtube;
