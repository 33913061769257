import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const ContactStyled = styled.div`
  position: relative;
  padding: 90px 0 166px;
  background: #afa4e6;
  color: #fff;
  text-align: center;
  z-index: 1001;
  .w {
    display: none;
  }
  ${mq[0]} {
    padding: 40px 10px 63px;
    .w {
      display: block;
    }
  }
  > div {
    > i {
      display: block;
      margin-bottom: 44px;
      ${mq[0]} {
        margin-bottom: 20px;
      }
      > img {
        width: 100px;
        ${mq[0]} {
          width: 50px;
        }
      }
    }
    strong {
      display: block;
      font-size: 40px;
      margin-bottom: 14px;
      font-family: 'NanumSquareB';
      font-weight: 400;
      ${mq[0]} {
        font-size: 17px;
        margin-bottom: 10px;
      }
    }
    p {
      font-size: 40px;
      line-height: 0.88;
      margin-bottom: 58px;
      ${mq[0]} {
        font-size: 17px;
        margin-bottom: 30px;
      }
    }
    .btn-group {
      a {
        display: inline-block;
        width: 300px;
        line-height: 60px;
        font-family: 'NanumSquareB';
        font-size: 20px;
        background: #fff;
        border-radius: 35px;
        ${mq[0]} {
          font-size: 14px;
          line-height: 40px;
        }
        i {
          display: inline-block;
          margin-right: 10px;
          img {
            width: 32px;
          }
        }

        &:first-of-type {
          margin-right: 20px;
          ${mq[0]} {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }
    }
  }
`;
export default ContactStyled;
