import React from 'react';
import ChatbotStyled from './Chatbot.Styled';
import {
  img_03_2x_en,
  character,
  chatbot_circle
} from '../../../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';
import { contents } from '../../../constants/contents';

const id = 'CHATBOT';

export default function Chatbot(props) {
  return (
    <ChatbotStyled id={id}>
      <div>
        <div className={`contentsText m`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.fr[id].content1}
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-150}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.8
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`circle`} src={chatbot_circle} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-300}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.9,
                    x: -100
                  }}
                  to={{
                    opacity: 1,
                    scale: 1,
                    x: 0
                  }}
                >
                  <img className={`phone`} src={img_03_2x_en} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-50}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    y: 30
                  }}
                  to={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  <img className={`character`} src={character} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
        <div className={`contentsText pc`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.fr[id].content1}
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
      </div>
    </ChatbotStyled>
  );
}
