import styled from '@emotion/styled';
import { toRem } from '../../utils/utils';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const EnergyBillHeaderStyled = styled.section`
  > div {
    padding: 0;
  }
  .header {
    background: rgba(41, 182, 246, 0.1);
    padding: ${toRem(70)} ${toRem(70)};
    h1 {
      font-family: 'NanumSquareEB';
      font-weight: bold;
      font-size: ${toRem(68)};
      color: #29b6f6;
      margin-bottom: ${toRem(44)};
      span {
        color: #000;
        font-family: 'NanumSquareB';
      }
    }
    .tabs {
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;
      li {
        flex: 1 1 40%;
        margin: ${toRem(10)};
        cursor: pointer;
        div {
          text-align: center;
          border: solid ${toRem(2)} #b1c2c9;
          background-color: #fff;
          font-size: ${toRem(40)};
          font-family: 'NanumSquareB';
          padding: ${toRem(27)} 0;
          border-radius: ${toRem(20)};
          color: #567d8e;
        }
        &.active div {
          border: solid ${toRem(2)} #29b6f6;
          background-color: #29b6f6;
          color: #fff;
        }
      }
    }
  }
`;

export default EnergyBillHeaderStyled;
