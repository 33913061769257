import React from 'react';
import GasLockStyled from './GasLock.Styled';
import { img_09_2x, img_09_phone_2x } from '../../../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';
import { contents } from '../../../constants/contents';

const id = 'GASLOCK';

export default function GasLock(props) {
  return (
    <GasLockStyled id={id}>
      <div>
        <Controller>
          <Scene indicators={false} duration="200" offset={-150}>
            <Timeline
              wrapper={
                <div
                  css={css`
                    overflow: hidden;
                  `}
                />
              }
            >
              <Tween
                position="0"
                stagger={0.2}
                staggerFrom={{
                  opacity: 0,
                  y: 100
                }}
                staggerTo={{
                  opacity: 1,
                  y: 0
                }}
              >
                {contents.kor[id].content1}
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
        <figure>
          <Controller>
            <Scene indicators={false} duration="300" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    scale: 0.8,
                    opacity: 0
                  }}
                  to={{
                    scale: 1,
                    opacity: 1
                  }}
                >
                  <img className={`lock_arrow`} src={img_09_2x} alt={``} />
                </Tween>
                <Tween
                  position="0"
                  from={{
                    scale: 1.2,
                    opacity: 0
                  }}
                  to={{
                    scale: 1,
                    opacity: 1
                  }}
                  delay={0.3}
                >
                  <img
                    className={`lock_phone`}
                    src={img_09_phone_2x}
                    alt={``}
                  />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </GasLockStyled>
  );
}
