import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const FooterStyled = styled.footer`
  position: relative;
  background: #303637;
  padding: 50px 0;
  z-index: 1002;

  .footer_en {
    padding: 0 40px;
    ${mq[0]} {
      padding: 0 24px;
    }

    .title {
      ${mq[0]} {
        line-height: 1.6;
        min-width: 60px !important;
        width: 60px !important;
      }
    }
  }

  > div {
    color: #fff;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;
    display: flex;
    flex-direction: column;

    ${mq[0]} {
      padding: 0 24px;
    }

    .top {
      width: 100%;
      margin-bottom: 20px;
      ${mq[0]} {
        margin-bottom: 10px;
      }
      img {
        ${mq[0]} {
          width: 80px;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      ${mq[0]} {
        flex-direction: column;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      line-height: 2;
      opacity: 0.7;
      .info {
        ${mq[0]} {
          line-height: 1.6;
        }
      }
      dl {
        display: flex;
        ${mq[0]} {
          flex-direction: column;
        }
        font-size: 12px;

        dt a {
          font-family: 'NanumSquareB';
          margin-right: 5px;
          color: #ffffff;
          line-height: 2;

          ${mq[0]} {
            margin-top: 15px;
          }
        }

        dd {
          line-height: 2;
          font-family: NanumSquareL;
        }
      }
    }

    .center {
      line-height: 2;
      opacity: 0.7;
      ${mq[0]} {
        margin-top: 20px;
        line-height: 1.6;
      }
      dl {
        display: flex;
        font-size: 12px;
      }
      dt {
        font-family: NanumSquareB;
        width: 46px;
      }
      dd {
        font-family: NanumSquareL;
        margin-left: 18px;
        ${mq[0]} {
          margin-left: 10px;
        }
      }
    }

    .right {
      line-height: 2;
      display: flex;
      ${mq[0]} {
        margin-top: 20px;
      }
      .title {
        font-family: NanumSquareB;
        opacity: 0.7;
        ${mq[0]} {
          min-width: 95px;
        }
      }
      .icons {
        padding: 4px 0 0 18px;

        .bl {
          display: inline-block;
          width: 2px;
          height: 18px;
          background-color: #d9d9d9;
          margin: 4px 14px -6px;
          opacity: 0.3;

          ${mq[0]} {
            margin: 4px 4px -6px;
          }
        }
        .store {
          > img {
            cursor: pointer;
            width: 35px;
            margin-right: 10px;
          }
        }
        .sns {
          > img {
            cursor: pointer;
            width: 35px;
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

export default FooterStyled;
