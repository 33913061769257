import React, { useEffect } from 'react';
import EnergyBillHeaderStyled from './EnergyHeader.Styled';

export default function EnergyHeader(props) {
  const { active, tabClick } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <EnergyBillHeaderStyled>
      <div className="header">
        <h1>
          에너지 요금 지원 제도
          <br />
          <span> 한눈에 알아보기</span>
        </h1>
        <ul className="tabs">
          <li
            className={active === 0 ? 'active' : ''}
            onClick={() => {
              tabClick(0);
            }}
          >
            <div>도시가스 요금 경감 제도</div>
          </li>
          <li
            className={active === 1 ? 'active' : ''}
            onClick={() => {
              tabClick(1);
            }}
          >
            <div>에너지바우처</div>
          </li>
          <li
            className={active === 2 ? 'active' : ''}
            onClick={() => {
              tabClick(2);
            }}
          >
            <div>탄소포인트제</div>
          </li>
          <li
            className={active === 3 ? 'active' : ''}
            onClick={() => {
              tabClick(3);
            }}
          >
            <div>친환경 보일러 교체 지원</div>
          </li>
        </ul>
      </div>
    </EnergyBillHeaderStyled>
  );
}
