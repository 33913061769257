import styled from '@emotion/styled';

export const breakpoints = [768, 1024, 1280];
export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const AmiStyled = styled.section`
  padding: 270px 0 230px;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    position: relative;
    padding: 0 40px 0 345px;
    ${mq[0]} {
      padding: 0;
    }

    .contentsText {
      ${mq[0]} {
        padding: 0 14px;
        max-width: 380px;
        margin: 0 auto;
      }
    }

    figure {
      position: absolute;
      top: 0;
      right: 38px;
      ${mq[0]} {
        text-align: center;
        position: relative;
        top: auto;
        right: auto;
      }

      .circle {
        position: absolute;
        top: 45px;
        right: 135px;
        ${mq[0]} {
          width: 125px;
          left: auto;
          right: 50%;
          top: 48%;
        }
      }

      .phone {
        position: absolute;
        width: 230px;
        top: -73px;
        right: 20px;
        ${mq[0]} {
          position: relative;
          left: auto;
          top: auto;
          width: 166px;
          margin-left: 90px;
        }
      }

      .indicator {
        position: absolute;
        width: 241px;
        right: 215px;
        top: 150px;
        ${mq[0]} {
          display: none;
        }
      }
    }

    span {
      font-size: 20px;
      display: block;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.4);
      ${mq[0]} {
        font-size: 10px;
      }
    }
    h2 {
      font-size: 60px;
      margin-bottom: 18px;
      ${mq[0]} {
        font-size: 30px;
        margin-bottom: 15px;
      }
    }
    p {
      font-family: 'NanumSquareL';
      font-size: 20px;
      line-height: 1.75;
      strong {
        font-family: 'NanumSquareB';
      }
      ${mq[0]} {
        font-size: 13px;
        margin-bottom: 67px;
      }
    }
  }
`;

export default AmiStyled;
