import React from 'react';
import { StyledLoadingBar } from './LoadingBar.styles';

function LoadingBar({ open }) {
  return (
    <StyledLoadingBar open={open}>
      <div className="loader">
        <span className="l1">&nbsp;</span>
        <span className="l2">&nbsp;</span>
        <span className="l3">&nbsp;</span>
        <span className="l4">&nbsp;</span>
        <span className="l5">&nbsp;</span>
        <span className="l6">&nbsp;</span>
      </div>
    </StyledLoadingBar>
  );
}

export default LoadingBar;
