import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';

import { Stack, toRem, toRemLucky } from '../utils/utils';
import { Image, Text, Noti, NotiList, BottomSheet } from '@gasapp/ui';

import EventMain1 from '../resources/images/boilerEvent/event-main-1.png';
import EventMain2 from '../resources/images/boilerEvent/event-main-2.png';
import EventMain3 from '../resources/images/boilerEvent/event-main-3.png';
import Event1_1 from '../resources/images/boilerEvent/event-1-1.png';
import Event1_2 from '../resources/images/boilerEvent/event-1-2.png';
import Event1_3 from '../resources/images/boilerEvent/event-1-3.png';
import Event2_1 from '../resources/images/boilerEvent/event-2-1.png';
import Event2_2 from '../resources/images/boilerEvent/event-2-2.png';
import Event2_3 from '../resources/images/boilerEvent/event-2-3.png';
import Event2_4 from '../resources/images/boilerEvent/event-2-4.png';
import Event2_5 from '../resources/images/boilerEvent/event-2-5.png';
import IcoInstagram from '../resources/images/boilerEvent/ico-instagram.png';
import IcoBlog from '../resources/images/boilerEvent/ico-blog.png';
import { eventBg } from '../resources/images';
import IcoHeart from '../resources/images/boilerEvent/ico-heart.svg';
import axios from 'axios';
import IcoLink from '../resources/images/lucky/ico-link.png';
import IcoKakao from '../resources/images/lucky/ico-kakao.png';
import DeviceUtil from '../utils/DeviceUtil';
import IcoShare from '../resources/images/lucky/ico-share.png';
import { useLocation } from 'react-router-dom';
import { clickTracking, usePageTracking } from '../hook/useTracking';
import BtnMore from '../resources/images/boilerEvent/btn-more.png';

const BoilerEventWrap = styled.div`
  .join {
    background: #fff7e4;
    padding-top: ${toRem(80)};

    .wunder-inputbox {
      width: 100%;
    }

    .wunder-textarea .wunder-textarea-wrap textarea.wunder-textarea-area {
      font-family: nanumsquare;
    }

    .wunder-textarea .wunder-textarea-wrap .wunder-textarea-placeholder {
      font-size: ${toRem(48)} !important;
    }
    .wunder-button {
      &.btn-edit,
      &.btn-register {
        padding: ${toRem(25)} ${toRem(60)};
      }
      &.variant-primary {
        background-color: #ff6817;
      }
      &.variant-lower {
        width: fit-content;
        padding: ${toRem(25)} ${toRem(60)};
        color: #ff6817;
        border-color: #ff6817;
        background-color: white;
      }
    }

    // 탭
    .tabs {
      border-bottom: ${toRem(10)} solid #ff6817;
      padding: 0 ${toRem(80)};

      .tab {
        padding: ${toRem(40)} 0;
        border-radius: ${toRem(36)} ${toRem(36)} 0 0;
        width: 100%;
        text-align: center;
        font-size: ${toRem(56)};
        font-family: 'HakgyoansimDunggeunmisoB';

        &.active {
          background: #ff6817;
          color: white;
        }
        &.inactive {
          background: white;
          color: var(--color-gray-888888);
        }
      }
    }

    .tab-contents {
      width: 100%;
      .story-list {
        width: 100%;
        padding: ${toRem(100)} ${toRem(80)};
      }
      .story-box {
        width: 100%;
        background: var(--color-white);
        border-radius: 0 ${toRem(120)} ${toRem(120)} ${toRem(120)};
        padding: ${toRem(70)} ${toRem(80)};
        word-break: break-all;
        overflow-wrap: break-word;

        > div {
          width: 100%;
        }
      }
    }

    .my-story {
      background: var(--color-white);
      &-box {
        background: #fff7e4;
        padding: ${toRem(70)} ${toRem(80)};
        border-radius: ${toRem(120)} 0 ${toRem(120)} ${toRem(120)};
        word-break: break-all;
        overflow-wrap: break-word;
      }
    }

    .exclamation .icon > svg > path {
      text-align: center;
      fill: #ff6817 !important;
    }

    .link-url {
      display: inline-block !important;
      color: #ff6817 !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: underline;
      vertical-align: bottom !important;
    }

    .delete-btn {
      width: ${toRem(100)};
      height: ${toRem(100)};
      border-radius: 50%;
      border: 1px solid #ff6817;
      justify-content: center;
    }
  }

  .noti {
    background-color: #333333;
    padding: ${toRem(80)};

    .noti-top {
      align-items: center;
    }
    .noti-title {
      background-color: white;
      border-radius: ${toRem(10)};
      opacity: 80%;
      padding: ${toRem(10)} ${toRem(20)};
      width: fit-content;
    }
  }

  .how-to-join {
    background: url(${eventBg}) no-repeat;
    .event2-3 {
      position: relative;

      .icos {
        width: 19.16%;
        position: absolute;
        top: 16%;
        left: 48%;
      }
    }
  }
`;

function BoilerEvent() {
  usePageTracking('보일러이벤트');
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const joinRef = useRef(null);
  const [activeTab, setActiveTab] = useState('STORY');
  const [otherStoryData, setOtherStoryData] = useState([]);
  const [otherSnsData, setOtherSNSData] = useState([]);
  const [isStoryPageEnd, setIsStoryPageEnd] = useState(false);
  const [isSnsPageEnd, setIsSnsPageEnd] = useState(false);
  const [storyLastId, setStoryLastId] = useState(0);
  const [snsLastId, setSnsLastId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [env, setEnv] = useState(null);
  const contentList = [
    {
      title: '이벤트 1 안내',
      contents: [
        '본 이벤트는 가스앱에서 보일러를 구매한 회원만 참여 가능하며, 1인당 1개의 사연만 작성할 수 있습니다.',
        '보일러 구매 후 사연을 작성한 전원에게 1만 캐시가 지급됩니다. (2025년 1월 9일 캐시 지급)',
        '경품 당첨자는 이벤트 참여의 성실성, 사연 내용의 적합성 등을 종합적으로 검토하여 공정하게 선정합니다.',
        '보일러 경품 당첨 시 구매한 보일러와 동일 모델 또는 80만 캐시 중 선택 가능합니다.',
        '당첨자 발표 이전에 보일러 구매를 취소할 경우 당첨 대상에서 제외됩니다.'
      ]
    },
    {
      title: '이벤트 2 안내',
      contents: [
        '본 이벤트는 본인 인증을 완료한 만 14세 이상의 가스앱 회원 누구나 참여할 수 있습니다.',
        'SNS 공유 이벤트는 중복 참여가 가능하며, 참여 횟수가 많을수록 당첨 확률이 높아집니다.',
        '인스타그램 공유 시 이벤트 이미지, 가스앱 계정 언급, 필수 해시태그가 포함되어야 하며 전체 공개로 설정해야 참여가 인정됩니다.',
        '블로그, 카페, 기타 SNS 공유 시 이벤트 링크, 필수 해시태그가 포함되어야 하며 전체 공개로 설정해야 참여가 인정됩니다.',
        '당첨자 발표 이전에 SNS 게시물을 삭제한 경우 당첨 대상에서 제외됩니다.',
        '보일러 경품 당첨 시 거꾸로 ECO 콘덴싱 L11 보일러 모델 또는 80만 캐시 중 선택 가능합니다.'
      ]
    },
    {
      title: '공통 안내',
      contents: [
        '이벤트 참여 시 부적절한 내용이나 욕설이 포함된 댓글은 사전 안내 없이 삭제될 수 있으며 캐시 및 경품 당첨 대상에서 제외됩니다.',
        '보일러를 포함한 5만원 초과 경품에 대한 제세공과금(22%)은 당첨자 본인 부담이며, 제세공과금 신고를 위해 요청 드리는 신분증 사본 및 개인정보 확인에 대해 기간 내 제출하지 않거나 허위 제출 시 당첨이 취소될 수 있습니다. (서류 제출 방법은 별도 안내 예정)',
        '보일러 설치 시 현장 상황에 따라 추가 시공 비용이 발생할 수 있습니다.',
        '캐시는 캐시아웃 메뉴에서 당첨자 본인 계좌로 입금 받거나 캐시몰에서 모바일 쿠폰으로 교환할 수 있습니다. (캐시아웃은 신청일 이후 다음주 수요일 전후로 입금)',
        '당첨자는 공지사항 및 가스앱 PUSH로 경품 수령 및 관련 일정에 대해 개별 안내드립니다. 기본 PUSH 알림을 수신 상태로 설정해 주세요.',
        '본 이벤트는 내부 사정에 따라 예고 없이 내용이 변경되거나 조기 종료될 수 있습니다.',
        '비정상적인 방법으로 이벤트 응모 시 당첨이 취소될 수 있습니다.',
        '본 이벤트는 내부 사정에 따라 예고 없이 내용이 변경되거나 조기 종료될 수 있습니다.',
        '경품 이미지는 이해를 돕기 위함이며 실제 경품과 상이할 수 있습니다.'
      ]
    }
  ];

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content =
      'width=340, initial-scale=1, height=device-height, maximum-scale=1.0 ,user-scalable=no';

    // document.head에 메타태그 추가
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  useEffect(() => {
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
  }, [queryParams]);

  const getOtherJoinData = joinType => {
    const instance = axios.create({
      baseURL: 'https://app.gasapp.co.kr/api'
    });

    const fetchData = () => {
      instance
        .get(`nevent/boilermall-openevent/joins/${joinType}`, {
          params: {
            lastId: joinType === 'STORY' ? storyLastId : snsLastId,
            limit: 5
          }
        })
        .then(res => {
          let data = res.data;
          if (data.length < 5)
            joinType === 'STORY'
              ? setIsStoryPageEnd(true)
              : setIsSnsPageEnd(true);
          if (data.length) {
            if (joinType === 'STORY') {
              setOtherStoryData(prevState => {
                return [...prevState, ...data];
              });
              setStoryLastId(Number(data[data.length - 1].joinId));
            } else {
              setOtherSNSData(prevState => {
                return [...prevState, ...data];
              });
              setSnsLastId(Number(data[data.length - 1].joinId));
            }
          }
        });
    };
    fetchData();
  };

  useEffect(() => {
    if (activeTab === 'STORY' && otherStoryData.length === 0) {
      // 다른 사람 사연 조회
      getOtherJoinData(activeTab);
    }
    if (activeTab === 'SNS' && otherSnsData.length === 0) {
      // 다른 사람 SNS 조회
      getOtherJoinData(activeTab);
    }
  }, [activeTab]);

  const onClickButton = type => {
    if (type === 'share') alert('본 이벤트는 앱에서 참여 가능합니다.');

    let url = null;
    if (env === 'production') {
      url = `https://gasappmobile.page.link/${
        type === 'share' ? 'boilerSaleEvent' : 'boilerInputForm'
      }`;
    } else {
      url = `https://gasappdev.page.link/${
        type === 'share' ? 'boilerSaleEvent' : 'boilerInputForm'
      }`;
    }
    window.open(url);
  };

  const shareItem = {
    image:
      'https://cdn-app.gasapp.co.kr/attach/event/boiler/img-boiler-event-kakao-share.png?v=2',
    title: `가스앱 이벤트에 초대했어요!`,
    description:
      '보일러 사면 1대 더! 카본 매트, 히터 등 총 1,383분께 드리는 겨울 선물 받아가세요!',
    kakaoDescription:
      '우리집 보일러에 부모님댁까지! 특별한 사연을 남기면 따뜻한 겨울 선물이 쏟아집니다! 귀뚜라미 콘덴싱 보일러, 카본매트, 큐브히터, 캐시 등 1,388분께 푸짐한 경품을!',
    url:
      env === 'production'
        ? `http://www.scglab.com/#/boilerEvent?env=${env}`
        : `http://www-dev.scglab.com/#/boilerEvent?env=${env}`,
    buttonName: '이벤트 둘러보기',
    linkShareUrl: 'boilerEvent.html',
    webUrl:
      env === 'production'
        ? 'https://www.scglab.com/'
        : 'https://www-dev.scglab.com/'
  };

  const copyLink = () => {
    clickTracking('이벤트_링크_복사');
    let prevUrl =
      shareItem.webUrl + shareItem.linkShareUrl + '?link=' + shareItem.url;
    const el = document.createElement('textarea');
    el.value = prevUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('이벤트 링크가 복사되었습니다!');
  };

  const shareKakao = () => {
    if (window.Kakao) {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          imageHeight: 800,
          imageWidth: 800,
          title: shareItem.title,
          description: shareItem.kakaoDescription,
          imageUrl: shareItem.image,
          link: {
            mobileWebUrl: shareItem.url
          }
        },
        installTalk: true,
        itemContent: {
          profileText: '가스앱',
          profileImageUrl:
            'https://cdn-app-dev.gasapp.co.kr/attach/raffle/kakaotalk/ico-gasapp.png'
        },
        buttons: [
          {
            title: '이벤트 둘러보기',
            link: {
              mobileWebUrl: shareItem.url
            }
          }
        ]
      });
    }
  };

  return (
    <div
      style={{
        background: '#fff',
        margin: '0 auto'
      }}
    >
      <BoilerEventWrap>
        {DeviceUtil.isMobile && (
          <Stack
            style={{
              padding: `${toRemLucky(49)} ${toRemLucky(80)}`,
              textAlign: 'right',
              justifyContent: 'flex-end'
            }}
          >
            <div
              style={{ width: toRemLucky(70) }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <img src={IcoShare} alt="" width="100%" />
            </div>
          </Stack>
        )}

        <Stack gap={20} direction="column">
          <Stack gap={20} direction="column">
            <Stack direction="column">
              <Image image={EventMain1} />
              <Image
                image={EventMain2}
                onClick={() => {
                  clickTracking('역대급_할인_보일러_구매하기');
                  onClickButton('mall');
                }}
              />
              <Image image={EventMain3} />
            </Stack>
            <Stack direction="column">
              <Image image={Event1_1} />
              <Image
                image={Event1_2}
                onClick={() => {
                  clickTracking('사연_남기기');
                  onClickButton('share');
                }}
              />
              <Image image={Event1_3} />
            </Stack>
            <Stack direction="column" className="how-to-join">
              <Image image={Event2_1} />
              <Image image={Event2_2} onClick={copyLink} />
              <div className="event2-3">
                <Image image={Event2_3} />
                <Stack direction="row" gap={30} className="icos">
                  <Image
                    image={IcoInstagram}
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/p/DBLMso1vzDS/?img_index=1',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  />
                  <Image
                    image={IcoBlog}
                    onClick={() =>
                      window.open(
                        'https://blog.naver.com/gasapp',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  />
                </Stack>
              </div>
              <Image
                image={Event2_4}
                onClick={() => {
                  clickTracking('공유_인증하기');
                  onClickButton('share');
                }}
              />
              <Image image={Event2_5} />
            </Stack>
          </Stack>
          <Stack className="join" ref={joinRef} direction="column">
            <Stack direction="row" className="tabs" justifyContent="center">
              <div
                className={`tab ${
                  activeTab === 'STORY' ? 'active' : 'inactive'
                }`}
                onClick={() => setActiveTab('STORY')}
              >
                보일러 선물 사연
              </div>
              <div
                className={`tab ${activeTab === 'SNS' ? 'active' : 'inactive'}`}
                onClick={() => setActiveTab('SNS')}
              >
                SNS 공유 인증
              </div>
            </Stack>

            {activeTab === 'STORY' ? (
              <Stack gap={100} className="tab-contents">
                {otherStoryData && otherStoryData.length > 0 && (
                  <Stack
                    gap={80}
                    className="story-list"
                    direction="column"
                    alignItems="center"
                  >
                    {otherStoryData?.map(item => {
                      return (
                        <Stack
                          gap={40}
                          className="story-box"
                          direction="column"
                        >
                          <Stack direction="row" gap={20} alignItems="center">
                            {item.featuredYn === 'Y' && (
                              <Image image={IcoHeart} width={74} />
                            )}
                            <Text variant="48B">{item.maskedName}</Text>
                          </Stack>
                          <Text variant="48" color="gray-666666">
                            {item.content}
                          </Text>
                        </Stack>
                      );
                    })}
                    {!isStoryPageEnd && (
                      <Image
                        image={BtnMore}
                        width={400}
                        height={100}
                        onClick={() => getOtherJoinData('STORY')}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack gap={100} className="tab-contents">
                {otherSnsData && otherSnsData.length > 0 && (
                  <Stack
                    gap={80}
                    className="story-list"
                    direction="column"
                    alignItems="center"
                  >
                    {otherSnsData?.map(item => {
                      return (
                        <Stack
                          gap={40}
                          className="story-box"
                          direction="column"
                        >
                          <Stack direction="row" gap={20} alignItems="center">
                            {item.featuredYn === 'Y' && (
                              <Image image={IcoHeart} width={74} />
                            )}
                            <Text variant="48B">{item.maskedName}</Text>
                          </Stack>

                          <Text variant="48" color="gray-666666">
                            {item.content}
                          </Text>
                        </Stack>
                      );
                    })}
                    {!isSnsPageEnd && (
                      <Image
                        image={BtnMore}
                        width={400}
                        height={100}
                        onClick={() => getOtherJoinData('SNS')}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>

          <Stack gap={60} defaultPadding className="noti" direction="column">
            <Noti
              icon="ExclamationIcon"
              iconProps={{
                width: 65,
                height: 65,
                color: 'white'
              }}
              content="꼭 확인해 주세요."
              contentProps={{
                textSize: '60',
                textWeight: 'b',
                color: 'white',
                textAlign: 'center',
                lineHeight: '1.3rem'
              }}
              className="noti-top"
            />
            {contentList.map(item => {
              return (
                <Stack gap={20} key={item.title} direction="column">
                  <Text
                    textSize="45"
                    textWeight="b"
                    lineHeight={60}
                    className="noti-title"
                  >
                    {item.title}
                  </Text>
                  <NotiList
                    contents={item.contents}
                    contentProps={{
                      color: 'white',
                      textSize: '45',
                      lineHeight: '60'
                    }}
                    contentIconProps={{
                      width: 14,
                      height: 14,
                      color: 'white'
                    }}
                  />
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        <BottomSheet
          isOpen={isOpen}
          headerCloseButton={true}
          header={<Text variant="64B">가스앱 이벤트 공유하기</Text>}
          cancel={() => setIsOpen(false)}
          cancelButton={false}
          okButton={false}
        >
          <Stack
            justifyItems="center"
            direction="row"
            style={{ marginTop: '0.3rem' }}
          >
            <Stack
              gap={20}
              alignItems="center"
              justifyContent="center"
              direction="column"
              style={{ flex: '1' }}
              onClick={copyLink}
            >
              <Image image={IcoLink} width={200} />
              <Text variant="44" textAlign="center">
                링크 복사
              </Text>
            </Stack>
            <Stack
              gap={20}
              alignItems="center"
              justifyContent="center"
              direction="column"
              style={{ flex: '1' }}
              onClick={shareKakao}
            >
              <Image image={IcoKakao} width={200} />
              <Text variant="44" textAlign="center">
                카카오톡
              </Text>
            </Stack>
          </Stack>
        </BottomSheet>
      </BoilerEventWrap>
    </div>
  );
}

export default BoilerEvent;
