import React, { useEffect } from 'react';
import BTN from '../resources/images/celebration/btn.png';
import IMG from '../resources/images/celebration/img.png';
import TOP from '../resources/images/celebration/top_cv.png';
import INTERVIEW_BTN from '../resources/images/celebration/interview_btn.png';
import LOGO from '../resources/images/celebration/logo_cv.png';
import { Image, Stack } from '@gasapp/ui';
import styled from '@emotion/styled';
import { toRem } from '../utils/utils';

const StyledMobileWrap = styled.div`
  .fix-btm {
    position: fixed;
    bottom: ${toRem(80)};
  }
`;
const url = 'https://youtube.com/shorts/tw2T6Po8KDc?feature=share';
function CelebrationCV() {
  // useEffect(() => {
  //   setTimeout(function() {
  //     window.open(url);
  //   }, 1500);

  //   return () => {};
  // }, []);
  return (
    <React.Fragment>
      <StyledMobileWrap>
        <Stack gap={80}>
          <Image image={TOP} />
          <Stack
            defaultPadding
            style={{ padding: '0 1.8rem 1.8rem' }}
            gap={120}
          >
            <Image image={IMG} />
            <Stack gap={25}>
              <Image
                image={BTN}
                onClick={() => {
                  window.open(url);
                }}
              />
              <Image
                image={INTERVIEW_BTN}
                onClick={() => {
                  window.open('https://youtu.be/_mnce5OnVe0?feature=shared');
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        {/* <Stack defaultPadding style={{ padding: '0 6rem' }} className="fix-btm">
          <Image image={LOGO} />
        </Stack> */}
      </StyledMobileWrap>
    </React.Fragment>
  );
}

export default CelebrationCV;
