import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const ChatbotStyled = styled.section`
  padding: 235px 0 235px;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    position: relative;
    padding: 0 40px 0 393px;
    ${mq[0]} {
      padding: 0;
      .m {
        display: none;
      }
    }
    figure {
      position: relative;
      ${mq[0]} {
        text-align: center;
      }
      .circle {
        position: absolute;
        left: 0;
        top: 0;
        ${mq[0]} {
          width: 125px;
          left: auto;
          right: 50%;
          top: -26px;
        }
      }
      .phone {
        position: absolute;
        left: 82px;
        top: 50px;
        width: 230px;
        ${mq[0]} {
          position: relative;
          left: auto;
          top: auto;
          width: 166px;
          margin-left: 18px;
        }
      }
      .character {
        position: absolute;
        left: 317px;
        top: 37px;
        width: 500px;
        ${mq[0]} {
          display: none;
        }
      }
    }
    .contentsText {
      padding-left: 358px;
      padding-top: 238px;
      ${mq[0]} {
        padding: 0 14px;
      }
      &.pc {
        ${mq[0]} {
          display: none;
        }
      }
      &.m {
        display: none;
        ${mq[0]} {
          display: block;
          margin: 0 auto;
          max-width: 380px;
        }
      }
      span {
        display: block;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: 15px;
        ${mq[0]} {
          font-size: 10px;
        }
      }
      h2 {
        font-size: 60px;
        ${mq[0]} {
          font-size: 30px;
          margin-bottom: 15px;
        }
      }
      p {
        font-family: 'NanumSquareL';
        font-size: 20px;
        line-height: 1.75;
        strong {
          font-family: 'NanumSquareB';
        }
        ${mq[0]} {
          word-break: keep-all;
          font-size: 13px;
          margin-bottom: 56px;
        }
      }
    }
  }
`;
export default ChatbotStyled;
