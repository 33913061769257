import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import ImageBg from '../resources/images/luckyRoulette/img-roulette-bg.png';
import ImageRoulette from '../resources/images/luckyRoulette/img-roulette.png';
import ImageStart from '../resources/images/luckyRoulette/img-roulette-start.png';
import ImageInfo from '../resources/images/luckyRoulette/img-roulette-info.png';
import ImageButton from '../resources/images/luckyRoulette/img-roulette-button-nn.png';
import ImageNotice from '../resources/images/luckyRoulette/img-roulette-notice-n.png';
import { Stack } from '../utils/utils';
import DeviceUtil from '../utils/DeviceUtil';
import { Box, Image } from '@gasapp/ui';

const ButtonBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 87%;
  transform: translateX(-50%);

  padding-bottom: 8%;

  text-align: center;
  > img {
    max-width: 360px;
  }
`;

function LuckyRoulette() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const imageRef = useRef();

  const [env, setEnv] = useState('production');
  useEffect(() => {
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
  }, [queryParams]);

  // const onClickRouletteImage = e => {
  //   const x = e.clientX;
  //   const y = e.clientY;
  //   console.log(x, y);

  //   const imageWidth = imageRef.current.clientWidth;
  //   const imageHeight = imageRef.current.clientHeight;
  //   console.log(imageWidth, imageHeight);
  // };

  const onClickButton = () => {
    alert('본 이벤트는 앱에서 참여 가능합니다');

    let url = null;
    if (env === 'production') {
      url = `https://gasappmobile.page.link/luckyRoulette`;
    } else {
      url = `https://gasappdev.page.link/luckyRoulette`;
    }

    window.open(url);
  };

  return (
    <div className="" style={{ background: '#FCFAF4' }}>
      <div
        style={{
          maxWidth: DeviceUtil.isMobile ? '500px' : '360px',
          background: '#fff',
          margin: '0 auto'
        }}
      >
        <Stack direction="column" style={{ position: 'relative' }}>
          <div style={{ position: 'relative' }}>
            <img
              src={ImageBg}
              alt="background"
              width="100%"
              // ref={imageRef}
              // onClick={onClickRouletteImage}
            />

            <div
              style={{
                position: 'absolute',
                top: '17%',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '110%'
              }}
            >
              <img
                src={ImageRoulette}
                alt="roulette"
                style={{ width: '100%' }}
                onClick={onClickButton}
              />

              {/* <img
                src={ImageStart}
                alt="start"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '20%'
                }}
              /> */}
            </div>
            <img
              src={ImageInfo}
              alt="info"
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                paddingBottom: '26%',
                width: '100%'
              }}
            />

            <ButtonBox
              className="button"
              // style={{ background: '#fff2d8' }}
              onClick={onClickButton}
            >
              <Image image={ImageButton} />
              {/* <img src={Img04} alt="" width="100%" /> */}
            </ButtonBox>
          </div>
          <div style={{ padding: '1vw', background: 'rgb(252, 250, 244)' }} />
          <Image image={ImageNotice} />
        </Stack>
      </div>
    </div>
  );
}

export default LuckyRoulette;
