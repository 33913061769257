import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const LifeStyled = styled.section`
  padding: 235px 0 235px;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    position: relative;
    padding: 0 40px 0 393px;
    ${mq[0]} {
      padding: 0;
      .m {
        display: none;
      }
    }
    figure {
      position: relative;
      ${mq[0]} {
        text-align: center;
      }
      .circle {
        position: absolute;
        top: 30px;
        left: 80px;
        ${mq[0]} {
          width: 125px;
          height: 125px;
          top: 72px;
          right: auto;
          left: 50%;
        }
      }
      .phone {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 230px;
        ${mq[0]} {
          position: relative;
          width: 165px;
          top: auto;
          right: auto;
        }
      }
      .character {
        position: absolute;
        width: 96px;
        top: 300px;
        left: 220px;
        ${mq[0]} {
          display: none;
        }
      }
    }
    .contentsText {
      padding-left: 358px;
      /* padding-top: 238px; */
      ${mq[0]} {
        display: block;
        margin: 0 auto;
        max-width: 380px;
        padding: 0 14px;
      }
      &.pc {
        ${mq[0]} {
          display: none;
        }
      }
      &.m {
        display: none;
        ${mq[0]} {
          display: block;
          margin: 0 auto;
          max-width: 380px;
        }
      }
    }
    span {
      font-size: 20px;
      display: block;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.4);
      ${mq[0]} {
        font-size: 10px;
      }
    }
    h2 {
      font-size: 60px;
      margin-bottom: 18px;
      ${mq[0]} {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
    p {
      font-family: 'NanumSquareL';
      font-size: 20px;
      line-height: 1.75;
      ${mq[0]} {
        word-break: keep-all;
        font-size: 13px;
        line-height: 1.8;
        margin-bottom: 59px;
        .pc {
          display: none;
        }
      }
    }
  }
`;
export default LifeStyled;
