import React from 'react';
import NewGasAppStyled from './NewGasApp.Styled';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { new_2x } from '../../../resources/images';
import { contents } from '../../../constants/contents';

const id = 'NEWGASAPP';

export default function NewGasApp(props) {
  return (
    <NewGasAppStyled id={id}>
      <div>
        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-100}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.5,
                    y: 50
                  }}
                  to={{
                    opacity: 1,
                    scale: 1,
                    y: 0
                  }}
                >
                  <img className={`newapp`} src={new_2x} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
        <Controller>
          <Scene indicators={false} duration="200" offset={-250}>
            <Timeline wrapper={<div />}>
              <Tween
                position="0"
                stagger={0.2}
                staggerFrom={{
                  opacity: 0,
                  y: 100
                }}
                staggerTo={{
                  opacity: 1,
                  y: 0
                }}
              >
                {contents.kor[id].content1}
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </NewGasAppStyled>
  );
}
