import React from 'react';
import GateStyled from '../components/cash/Gate.Styled';
import YouTube from 'react-youtube';

function NaverGateCash() {
  const videoOptions = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0
    }
  };
  return (
    <React.Fragment>
      <GateStyled>
        <div>
          <h3 className="title">
            가스앱 캐시 모아
            <br />
            가스 요금 할인 받으세요!
          </h3>
          <p className="sub-title">
            이벤트 참여나 광고 시청으로 <br />
            차곡차곡 <span className="bold">캐시 적립</span>해서 <br />
            <span className="bold">가스비 최~대한 할인</span> 받으세요!
          </p>
          <div className="image-wrap">
            <YouTube videoId={'q8Ljc5rFFYI'} opts={videoOptions} />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              window.open('https://mobileservicecenter.page.link/cashsave');
            }}
          >
            가스앱 설치하고 캐시적립
          </button>
          <p className="cash-title">※ 가스앱 캐시란?</p>
          <p className="cash-desc">
            1캐시는 현금 1원과 동일하며, 적립 캐시는 가스요금 납부 또는
            캐시아웃으로 계좌 송금 후 사용할 수 있습니다.
          </p>
        </div>
      </GateStyled>
    </React.Fragment>
  );
}

export default NaverGateCash;
