import * as React from 'react';
import {
  ic_home_copy,
  ic_download_copy,
  ic_user_copy,
  ic_store_copy,
  award_01,
  award_02,
  award_03,
  award_04,
  award_05,
  award_06,
  award_07,
  award_08,
  award_09
} from '../resources/images';

export const contents = {
  kor: {
    // 가스앱
    GASAPP: {
      content1: (
        <>
          <h2>
            전국 곳곳 가스앱의 <br className="w" />
            고객경험이 늘어갈수록
            <br />
            우리는 더 새로운 가치를 <br className="w" />
            만들어 갑니다.
          </h2>
          <p>
            가스앱은{' '}
            <strong>
              도시가스 외 전기 · 난방 · 신재생 에너지 등 <br />
              에너지를 이용하는
            </strong>{' '}
            <br className="m" /> 모든 고객을 위한 서비스 입니다
          </p>
        </>
      ),
      content2: (
        <p className={`contentsText pc`}>
          국내 공공 서비스 앱 중 가장 높은 고객 이용률과 만족도 평가를 받아온
          가스앱!
          <br />
          <strong>전국의 40%</strong>에 해당하는
          <strong>약 842만 가구</strong>를 대상으로 운영 중이며{' '}
          <strong>서울 고객의 90%</strong>가 이용하고 있습니다.
        </p>
      ),
      content3: (
        <p className={`contentsText m`}>
          국내 공공 서비스 앱 중
          <br />
          가장 높은 고객 이용률과 만족도 평가를 받아온 가스앱!
          <br />
          <strong>전국의 40%</strong>에 해당하는
          <strong>약 842만 가구</strong>
          를 대상으로
          <br />
          운영 중이며 <strong>서울 고객의 90%</strong>가 이용하고 있습니다.
        </p>
      ),
      content_quantitative: (
        <>
          <li>
            <figure>
              <img src={ic_home_copy} alt={``} />
            </figure>
            <strong>842만</strong>
            <p>서비스 가구</p>
          </li>
          <li>
            <figure>
              <img src={ic_download_copy} alt={``} />
            </figure>
            <strong>500만</strong>
            <p>다운로드</p>
          </li>
          <li>
            <figure>
              <img src={ic_user_copy} alt={``} />
            </figure>
            <strong>200만</strong>
            <p>활동 회원</p>
          </li>
          <li>
            <figure>
              <img src={ic_store_copy} alt={``} />
            </figure>
            <strong>4.8점</strong>
            <p>스토어 평점</p>
          </li>
        </>
      ),
      content_award: (
        <>
          <dl>
            <dt>
              <img src={award_01} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2021년 국가서비스대상</strong>
              </div>
              <span className="etc">가스앱 수상</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_02} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>
                  2021년 대한민국 브랜드
                  <br />
                  명예의전당
                </strong>
              </div>
              <span className="etc">PATH 수상</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_03} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2020년 국가서비스대상</strong>
              </div>
              <span className="etc">가스앱 수상</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_04} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2019년 대한민국 브랜드대상
                <strong>국무총리상 수상</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_05} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>
                  2019년 대한민국 브랜드
                  <br />
                  명예의전당
                </strong>
              </div>
              <span className="etc">가스락 수상</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_06} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2018 앱스토어 <strong>메인 피쳐드 앱 선정</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_07} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2018 디지털 이노베이션 <strong>대상 수상</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_08} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2017 앱 어워드 코리아 <strong>올해의 앱 대상 수상</strong>
              </div>
            </dd>
          </dl>
        </>
      )
    },
    // 서비스 지역 확대
    NEWGASAPP: {
      content1: (
        <p>
          2020년 3월 첫 서비스를 시작으로
          <br />
          서울, 예스코, 인천, 대륜 E&S, JB, 군산, <br className="m" />
          제주, 귀뚜라미 에너지, 참빛도시가스 등
          <br />
          <strong>전국 도시가스로 확대</strong>
          되고 있습니다.
        </p>
      )
    },
    INTEGRATION: {
      content1: (
        <>
          <span>Features 01</span>
          <h2>실시간 에너지 관리</h2>
          <p>
            <strong>가스, 전기 등 실시간 에너지 사용 패턴</strong>
            과 
            <br className="m" />
            <strong>이웃집 에너지 사용량 비교</strong>까지 가능합니다.
          </p>
        </>
      )
    },
    // 쉽고 편한 개인화 홈
    PRIVATEHOME: {
      content1: (
        <>
          <span>Features 02</span>
          <h2>쉽고 편한 개인화 홈</h2>
          <p>
            <strong>얼마나 쓰고, 얼마나 냈는지 </strong> <br className={`pc`} />
            꼭 필요한 내용만 골라 <strong>더 쉽고, 더 편하게</strong>{' '}
            보여드립니다.
          </p>
        </>
      ),
      content2: (
        <>
          <span>Features 02</span>
          <h2>쉽고 편한 개인화 홈</h2>
          <p>
            <strong>얼마나 쓰고, 얼마나 냈는지 </strong>
            <br />꼭 필요한 내용만 골라 <strong>더 쉽고, 더 편하게</strong>{' '}
            보여드립니다.
          </p>
        </>
      )
    },
    // 3초 간편 결제
    PAY: {
      content1: (
        <>
          <span>Features 03</span>
          <h2>3초 간편 결제</h2>
          <p>
            번거롭고 오래 걸리는 요금 납부는 끝!
            <br />
            <strong>간편 결제</strong> 한 번만 등록해 놓으면{' '}
            <strong>3초</strong> 만에 납부 완료!
          </p>
        </>
      )
    },
    // 매일 똑똑해지는 AI
    CHATBOT: {
      content1: (
        <>
          <span>Features 04</span>
          <h2>매일 똑똑해지는 A.I</h2>
          <p>
            가스앱 A.I 챗봇은 4년간 학습한 <strong>약 40만 건의 데이터</strong>
            로 <br className="m" />
            <strong>24시간 고객의 민원을 해결</strong>합니다.
          </p>
        </>
      )
    },
    AMI: {
      content1: (
        <>
          <span>Features 05</span>
          <h2>처음 만나는 AMI</h2>
          <p>
            실시간 사용량과 예상 요금 조회, 목표 사용량 설정으로
            <br />
            <strong>더 효율적인 에너지 관리</strong>가 가능합니다.
          </p>
        </>
      )
    },
    // LIFE & 020
    LIFE: {
      content1: (
        <>
          <span>Features 06</span>
          <h2>LIFE &amp; O2O</h2>
          <p>
            가스비 절약 방법, 생활 속 꿀팁, 맛있는 요리 레시피는 물론
            <br className={`pc`} /> 일상에 편리함을 주는 다양한 O2O 서비스들을
            만날 수 있습니다.
          </p>
        </>
      )
    },
    // 가스락 2.0
    GASLOCK: {
      content1: (
        <>
          <h2>혜택과 할인 ‘가스락'</h2>
          <p>
            잠금 화면을 밀 때마다, <br className="w" />
            다양한 콘텐츠를 둘러볼 때마다 차곡차곡 캐시가 쌓여요!
            <br />
            <strong>적립된 캐시로 가스비 할인 혜택</strong>을 받아보세요!
          </p>
        </>
      )
    },
    // 도시가스를 위한 가스앱
    EFFECT: {
      content1: (
        <>
          <h2>
            고객 만족을 고민하는 <br className="w" />
            다양한 기업의 선택
          </h2>
          <p className={`contentsText`}>
            가스앱, FMS(현장관리시스템), 챗봇, 채팅상담시스템, 가스락 등
            <br className="m" />
            기업의 필요에 맞게 선택하세요!
            <br />
            <strong>최적화된 솔루션</strong>으로 365일 안정적인 서비스를
            제공합니다.
          </p>
        </>
      ),
      content2: (
        <p>
          <strong>고지서 송달 금액 절감</strong>
          <span>
            모바일 청구서로 전환되어 고지서
            <br />
            송달 금액 절감 효과를 기대할 수 있습니다.
          </span>
        </p>
      ),
      content3: (
        <p>
          <strong>콜센터 콜 감소</strong>
          <span>
            고객센터 수준의 업무 처리를 통해
            <br /> 불필요한 콜센터 업무를 감소시킬 수 있습니다.
          </span>
        </p>
      ),
      content4: (
        <p>
          <strong>쉽고 빠른 정책 도입</strong>
          <span>
            산업통상자원부의 정책을 빠르게 분석하여
            <br /> 파트너사들에게 보다 좋은 서비스 도입을 합니다.
          </span>
        </p>
      ),
      content5: (
        <p>
          <strong>소비자 요금 절감</strong>
          <span>
            도시가스와 소비자의 접점에서
            <br /> 발생하는 수익으로 도시가스는 매출 증가,
            <br /> 소비자는 요금 절감 혜택을 볼 수 있습니다.
          </span>
        </p>
      ),
      content6: (
        <p>
          <strong>채권 기간 단축</strong>
          <span>
            요금 정보를 투명하고 공개하여
            <br /> 채권 기간을 단축시킬 수 있습니다.
          </span>
        </p>
      )
    }
  },
  en: {
    GASAPP: {
      content1: (
        <>
          <h2>
            As more customers begin to use Gas app
            <br />
            all over the country, we find new ways to create value.
          </h2>
          <p>
            Gas app is a service even for customers other than city gas,
            <br className="m" /> such as electricity and heating renewable
            energy.
          </p>
        </>
      ),
      content2: (
        <p className={`contentsText pc`}>
          Gas app has received the{' '}
          <strong>
            highest customer usage and
            <br className="m" />
            satisfaction ratings among public service apps
          </strong>{' '}
          in all of Korea!
          <br />
          It provides service to <strong>about 8.4M households</strong> in
          Seoul, Incheon, Gyeonggi-do,
          <br className="m" /> Uijeongbu, Pocheon, Yangju, Gangwon, Chungcheong,
          Sejong, Jeollabuk-do and Jeju
        </p>
      ),
      content3: (
        <p className={`contentsText m`}>
          Gas app has received the highest customer usage and
          <br className="m" />
          satisfaction ratings among public service apps in all of Korea!
          <br />
          It provides service to about{' '}
          <strong>
            8.4M households in Seoul, Incheon,
            <br className="m" />
            {''}
            Uijeongbu, Pocheon, Yangju, Chungcheong, Sejong, and Jeju.
          </strong>
        </p>
      ),
      content_quantitative: (
        <>
          <li>
            <figure>
              <img src={ic_home_copy} alt={``} />
            </figure>
            <strong>8.4M</strong>
            <p>Households</p>
          </li>
          <li>
            <figure>
              <img src={ic_download_copy} alt={``} />
            </figure>
            <strong>5M</strong>
            <p>Downloads</p>
          </li>
          <li>
            <figure>
              <img src={ic_user_copy} alt={``} />
            </figure>
            <strong>2M</strong>
            <p>Active users</p>
          </li>
          <li>
            <figure>
              <img src={ic_store_copy} alt={``} />
            </figure>
            <strong>4.8</strong>
            <p>Store rating</p>
          </li>
        </>
      ),
      content_award: (
        <>
          <dl>
            <dt>
              <img src={award_06} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2023 AppStore <strong>Lifestyle 1st place</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_09} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2019 Google Play <strong>Lifestyle 1st place</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_01} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2021 National Service Award</strong>
              </div>
              <span className="etc">Gas App</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_02} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2021 Korea Brand Hall of Fame</strong>
              </div>
              <span className="etc">PATH</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_03} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2020 National Service Award</strong>
              </div>
              <span className="etc">Gas App</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_04} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2019 Korea Brand Award</strong>
              </div>
              <span className="etc">Prime Minister's Prize</span>
            </dd>
          </dl>
        </>
      )
    },
    NEWGASAPP: {
      content1: (
        <p>
          Started in March 2020 with <strong>Seoul</strong>,{' '}
          <strong>Incheon</strong>, <strong>Jeju</strong>, <strong>JB</strong>,{' '}
          <strong>Daeryun E&S</strong>, <strong>Yesco</strong>,{' '}
          <strong>Gunsan</strong>, <strong>Kiturami</strong>,
          <br className="m" /> it will be expanded in the{' '}
          <strong>Charm vit</strong> areas in August 2024.
        </p>
      )
    },
    INTEGRATION: {
      content1: (
        <>
          <span>Features 01</span>
          <h2>Real-time energy management</h2>
          <p>
            It is possible to compare the usage patterns of various energies
            such as
            <br />
            <strong>
              gas and electricity in each household and the usage amount in our
              neighborhood
            </strong>
          </p>
        </>
      )
    },
    PRIVATEHOME: {
      content1: (
        <>
          <span>Features 02</span>
          <h2 className="en">
            Customized
            <br />
            personal home screen
          </h2>
          <p>
            <strong>The biggest reason to use 'Gas App'!</strong>
            <br /> We show how much you used and paid intuitvely.
          </p>
        </>
      ),
      content2: (
        <>
          <span>Features 02</span>
          <h2 className="en">
            Customized <br /> personal <br /> home screen
          </h2>
          <p>
            <strong>The biggest reason to use 'Gas App'!</strong>
            <br /> We show how much you used and paid intuitvely.
          </p>
        </>
      )
    },
    PAY: {
      content1: (
        <>
          <span>Features 03</span>
          <h2 className="en">
            3 seconds <br />
            simple payment
          </h2>
          <p>
            The tedious steps to pay for your bill is over! <br />
            <strong>The simple payment</strong> lets you pay in{' '}
            <strong>3 seconds.</strong>
          </p>
        </>
      )
    },
    CHATBOT: {
      content1: (
        <>
          <span>Features 04</span>
          <h2 className="en">A.I. getting smart everyday</h2>
          <p>
            The Gas App <strong>AI</strong> uses 4 years of more than{' '}
            <strong>400,000</strong> data to learn and handle user meter reading
            and complaints.
          </p>
        </>
      )
    },
    AMI: {
      content1: (
        <>
          <span>Features 05</span>
          <h2 className="en">
            First time
            <br />
            meeting AMI
          </h2>
          <p>
            Real-time usage and expected fee inquiry,
            <br />
            and setting a target usage amount make it <br />
            possible to <strong>manage energy more efficiently.</strong>
          </p>
        </>
      )
    },
    LIFE: {
      content1: (
        <>
          <span>Features 06</span>
          <h2 className="en">LIFE &amp; O2O</h2>
          <p>
            You can find various O2O services including <br className="pc" />
            how to save gas bill, daily life tips, and delicious cooking
            recipes.
          </p>
        </>
      )
    },
    GASLOCK: {
      content1: (
        <>
          <h2 className="en">Gas Lock</h2>
          <p>
            Gas Lock, the screen lock that gives you points to pay for your
            bill, <br />
            has been loved by many customer.
          </p>
        </>
      )
    },
    EFFECT: {
      content1: (
        <>
          <h2 className="en">
            The choice for companies who care about their customer’s
            satisfaction.
          </h2>
          <p className={`contentsText`}>
            Choose your company’s needs including Gas app, FMS (Field Management
            System),
            <br className="m" /> Chatbot, Chat consultation, and Gas lock!
            <br />
            We provide stable service and <strong>
              optimized solutions
            </strong>{' '}
            365 days a year.
          </p>
        </>
      ),
      content2: (
        <p>
          <strong>
            Savings on <br />
            bill delivery
          </strong>
          <span>
            By converting to mobile billing, the mailing cost for the bill is
            greatly reduced.
          </span>
        </p>
      ),
      content3: (
        <p>
          <strong>
            Reduce call <br />
            center calls
          </strong>
          <span>
            Providing human level mobile A/S, the work load of your call center
            is dramatically reduced.
          </span>
        </p>
      ),
      content4: (
        <p>
          <strong>
            Easy and fast <br />
            policy adaptation
          </strong>
          <span>
            When legislations or goverment policies are changed, they get
            implemented and notified to customers instantly through the app.
          </span>
        </p>
      ),
      content5: (
        <p>
          <strong>
            Reduce customer <br />
            costs
          </strong>
          <span>
            More the customers use the app, the benefit is returned back to the
            users for bill payment reduction.
          </span>
        </p>
      ),
      content6: (
        <p>
          <strong>
            Shorten the <br />
            unpaid debt period
          </strong>
          <span>
            The unpaid bill will be managed and repaid quickly through the app.
          </span>
        </p>
      )
    }
  },
  fr: {
    GASAPP: {
      content1: (
        <>
          <h2>
            À mesure que d'autres clients utilisent Gas App dans tout le pays,
            nous creeons de nouvelles valeurs.
          </h2>
          <p>
            Gas App est un service destiné à tous les clients de gaz.
            <br className="m" /> Énergie Gaz de ville ainsi que électricité,
            Même le chauffage et les énergies renouvelables.
          </p>
        </>
      ),
      content2: (
        <p className={`contentsText pc`}>
          Gas App a reçu le plus haut taux d'utilisation cliente <br />
          et le meilleur taux de satisfaction parmi les applications <br />
          de service public dans toute la Corée!
          <br />
          Il fournit des services à environ 8 millions de ménages
          <br />
          comprenant 90% des clients de Séoul ainsi que les régions de
          <br />
          Chungcheong, Gangwon, Jeolla.
        </p>
      ),
      content3: (
        <p className={`contentsText m`}>
          Gas App a reçu le plus haut taux d'utilisation cliente <br />
          et le meilleur taux de satisfaction parmi les applications <br />
          de service public dans toute la Corée!
          <br />
          Il fournit des services à environ 8 millions de ménages
          <br />
          comprenant 90% des clients de Séoul ainsi que les régions de
          <br />
          Chungcheong, Gangwon, Jeolla.
        </p>
      ),
      content_quantitative: (
        <>
          <li>
            <figure>
              <img src={ic_home_copy} alt={``} />
            </figure>
            <strong>8.4M</strong>
            <p>Households</p>
          </li>
          <li>
            <figure>
              <img src={ic_download_copy} alt={``} />
            </figure>
            <strong>5M</strong>
            <p>Downloads</p>
          </li>
          <li>
            <figure>
              <img src={ic_user_copy} alt={``} />
            </figure>
            <strong>2M</strong>
            <p>Active users</p>
          </li>
          <li>
            <figure>
              <img src={ic_store_copy} alt={``} />
            </figure>
            <strong>4.8</strong>
            <p>Store rating</p>
          </li>
        </>
      ),
      content_award: (
        <>
          {' '}
          <dl>
            <dt>
              <img src={award_06} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2023 AppStore <strong>Lifestyle 1st place</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_09} alt={``} />
            </dt>
            <dd>
              <div className="title">
                2019 Google Play <strong>Lifestyle 1st place</strong>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_01} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2021 National Service Award</strong>
              </div>
              <span className="etc">Gas App</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_02} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2021 Korea Brand Hall of Fame</strong>
              </div>
              <span className="etc">PATH</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_03} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2020 National Service Award</strong>
              </div>
              <span className="etc">Gas App</span>
            </dd>
          </dl>
          <dl>
            <dt>
              <img src={award_04} alt={``} />
            </dt>
            <dd>
              <div className="title">
                <strong>2019 Korea Brand Award</strong>
              </div>
              <span className="etc">Prime Minister's Prize</span>
            </dd>
          </dl>
        </>
      )
    },
    NEWGASAPP: {
      content1: (
        <p>
          Avec l'avènement du premier service en mars 2020,
          <br className="m" />
          <strong>12 compagnies de gaz de ville, dont Séoul City Gas, </strong>
          <br className="m" />
          se sont <strong>étendues de 50 % dans tout le pays</strong> grâce à
          l'utilisation de Gas App.
        </p>
      )
    },
    INTEGRATION: {
      content1: (
        <>
          <span>Caractéristiques 01</span>
          <h2>
            Gestion de l'énergie <br />
            en temps réel
          </h2>
          <p>
            Nous fournissons des tableaux de consommation d'énergie et des
            indicateurs d'utilisation, tels que le gaz et l'électricité, pour
            chaque foyer.
          </p>
        </>
      )
    },
    PRIVATEHOME: {
      content1: (
        <>
          <span>Caractéristiques 02</span>
          <h2 className="en">Menu personnalise facile d'utilisation</h2>
          <p>
            Nous ne vous montrons que l'essentiel, combien vous dépensez,
            combien vous avez payé et votre taux de réduction.
          </p>
        </>
      ),
      content2: (
        <>
          <span>Caractéristiques 02</span>
          <h2 className="en">Menu personnalise facile d'utilisation</h2>
          <p>
            Nous ne vous montrons que l'essentiel, combien vous dépensez,
            combien vous avez payé et votre taux de réduction.
          </p>
        </>
      )
    },
    PAY: {
      content1: (
        <>
          <span>Caractéristiques 03</span>
          <h2 className="en">
            Paiement simple
            <br /> en 3 secondes
          </h2>
          <p>
            Fini le paiement des frais compliques et fastidieux !
            <br />
            Si vous enregistrez un moyen paiement simple,
            <br /> le paiement sera effectué en 3 secondes !
          </p>
        </>
      )
    },
    CHATBOT: {
      content1: (
        <>
          <span>Caractéristiques 04</span>
          <h2 className="en">Une I.A. de plus en plus intelligente</h2>
          <p>
            L'I.A de Gas App utilise 300 000 donnees accumulees pendant les
            trois dernieres annees pour resoudre les problemes et plaintes des
            clients 24 heures sur 24.
          </p>
        </>
      )
    },
    AMI: {
      content1: (
        <>
          <span>Caractéristiques 05</span>
          <h2 className="en">
            Premiere rencontre <br />
            avec AMI
          </h2>
          <p>
            Une gestion plus efficace de l'énergie est possible <br />
            grâce à la vérification des tarifs estimes de l'utilisation <br />
            en temps réel, et à la configuration <br />
            du taux d'utilisation cible. De plus, <br />
            nous assurons la sécurité quotidienne des ménages <br />
            d'une personnes grâce à un service d'alerte actif 24 heures sur 24.
            "
          </p>
        </>
      )
    },
    LIFE: {
      content1: (
        <>
          <span>Caractéristiques 06</span>
          <h2 className="en">LIFE & O2O</h2>
          <p>
            Vous pouvez trouver une variété de services 020 qui vous facilitent
            la vie quotidienne comme Comment économiser les frais de gaz, les
            astuces de la vie quotidienne ou encore des recettes de cuisine
            délicieuses.
          </p>
        </>
      )
    },
    GASLOCK: {
      content1: (
        <>
          <h2 className="en">
            Avantages et réductions <br />
            'Gas lock'
          </h2>
          <p>
            Chaque fois que vous deverouillez l'écran, que vous regardez du
            contenu les points s'accumulent ! <br />
            Bénéficiez d'une réduction sur les frais de gaz avec le cash mis de
            côté !
          </p>
        </>
      )
    },
    EFFECT: {
      content1: (
        <>
          <h2 className="en">
            Des solutions sur-mesure
            <br className="w" />
            pour satisfaire le client
          </h2>
          <p className={`contentsText`}>
            Choisissez la solution dont votre entreprise a besoin parmi Gas App,
            FMS (systeme de gestion du site), chatbot, service de consultation
            par chat et Gas lock. Nous vous offrons un service fiable sur toute
            l'année avec des solutions optimisées.
          </p>
        </>
      ),
      content2: (
        <p>
          <strong>
            Réduction du montant <br />
            de la facture
          </strong>
          <span>
            vous pouvez bénéficier d'une réduction du montant de la facture
            grace a la conversion en facture mobile
          </span>
        </p>
      ),
      content3: (
        <p>
          <strong>
            Réduction des appels <br />
            au centre d'appels
          </strong>
          <span>
            le traitement des affaires au niveau du centre client réduit les
            tâches inutiles au centre d'appels.
          </span>
        </p>
      ),
      content4: (
        <p>
          <strong>
            Introduction rapide <br />
            des politiques
          </strong>
          <span>
            analyse rapide des politiques du ministère du Commerce, de
            l'Industrie et de l'Énergie pour introduire de meilleurs services
            aux partenaires.
          </span>
        </p>
      ),
      content5: (
        <p>
          <strong>Réduction des frais de consommation</strong>
          <span>
            les revenus générés par le biais du contact des consommateurs
            augmentent les ventes de gaz urbains et les consommateurs
            bénéficient de réductions de tarifs.
          </span>
        </p>
      ),
      content6: (
        <p>
          <strong>Réduction de la durée d'endettement</strong>
          <span>
            l'information sur les taux peut être montrée de manière transparente
            pour réduire l'écart entre les endettements.
          </span>
        </p>
      )
    }
  }
};
