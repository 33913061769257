import React, { useEffect, useState } from 'react';
import SideMenu from '../layout/SideMenu';

import {
  Chatbot,
  Contact,
  Effect,
  GasApp,
  GasLock,
  Life,
  NewGasApp,
  Pay,
  PrivateHome,
  Integration
} from '../components/main/kor';
import Footer from '../layout/Footer';
import Ami from '../components/main/kor/Ami';

export default function Main(props) {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = e => {
    setScrollTop(document.body.scrollTop || document.documentElement.scrollTop);
  };

  return (
    <React.Fragment>
      <SideMenu scrollTop={scrollTop} />
      <GasApp />
      {/* <Intro /> */}
      <NewGasApp />
      <Integration />
      <PrivateHome />
      <Pay />
      <Chatbot />
      <Ami />
      <Life />
      <GasLock />
      <Effect />
      <Contact />
      <Footer />
    </React.Fragment>
  );
}
