import * as React from 'react';
import { css } from '@emotion/core';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { integration_house } from '../../../resources/images';
import { integration_charts } from '../../../resources/images';
import IntegrationStyled from './Integration.styled';
import { contents } from '../../../constants/contents';

const id = 'INTEGRATION';

function Integration() {
  return (
    <IntegrationStyled id={id}>
      <div>
        <div className={`contentsText`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.kor[id].content1}
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-500}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.8
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <div className="image">
                    <img
                      className={`img_house`}
                      src={integration_house}
                      alt={``}
                    />
                    <img
                      className={`img_chart`}
                      src={integration_charts}
                      alt={``}
                    />
                  </div>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </IntegrationStyled>
  );
}

export default Integration;
