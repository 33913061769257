import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import Timer from '../components/lucky/Timer';
import ImgRaffleTitle from '../resources/images/lucky/img-card-market-text.png';
import ImgRaffleLeaf from '../resources/images/lucky/img-market-product-leaf.png';
import ImgHowToJoin from '../resources/images/lucky/img-how-to-join.png';
import ImgNoti from '../resources/images/lucky/img-noti2.png';
import ImgButton from '../resources/images/lucky/img-button.png';
import { productBg, iconBack, iconFront, iconTimer } from '../resources/images';
import {
  toRemLucky,
  StackStyle,
  FontStyle,
  Text,
  Stack,
  RaffleColor,
  WunderPaper
} from '../utils/utils';
import axios from 'axios';
import DeviceUtil from '../utils/DeviceUtil';
import moment from 'moment/moment';

const TimerWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${toRemLucky(136)};
  background: rgba(224, 224, 224, 0.65);
  gap: ${toRemLucky(10)};
  img {
    width: ${toRemLucky(60)};
  }
`;

const LuckyMarketWrap = styled.div`
  background: #00b2ff url(${productBg}) center top repeat-x;
  background-size: ${toRemLucky(1200)};
  position: relative;
  width: 100%;
  margin: 0 auto;

  .market-title {
    padding-top: ${toRemLucky(39)};
    padding-bottom: ${toRemLucky(36)};
    // width: ${toRemLucky(1200)};
    width: 100%;
    margin: 0 auto;

    &.winner-title {
      padding-bottom: ${toRemLucky(100)};
    }
  }
  .cards {
    position: relative;
    padding-top: ${toRemLucky(210)};
    padding-bottom: ${toRemLucky(100)};
  }
  .leaf {
    position: absolute;
    left: 0;
    top: 0;
    width: ${toRemLucky(222)};
  }
`;

const LuckyWrap = styled.div`
  box-sizing: border-box;
  // max-width: 360px;
  padding: 0 ${toRemLucky(75)};
  margin: 0 auto;
  text-align: center;
  width: 100%;
  &.info {
    img {
      width: ${toRemLucky(300)};
    }
  }
  &.button {
    cursor: pointer;
  }
`;
const DefaultBox = styled.div`
  width: 100%;

  margin: 0 auto;
  text-align: center;
  > img {
    // max-width: 360px;
  }
  &.gray {
    background: #bbbbbb26;
  }
`;

const CharacterBack = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: ${toRemLucky(34)};
  height: ${toRemLucky(176)};
  width: ${toRemLucky(940)};
  z-index: 1;

  .back {
    background: url(${iconBack}) no-repeat center top;
    background-size: 100%;
    height: 100%;
  }
`;

const CharacterFront = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: ${toRemLucky(34)};
  height: ${toRemLucky(210)};
  width: ${toRemLucky(940)};
  z-index: 3;

  .front {
    background: url(${iconFront}) no-repeat center top;
    background-size: 100%;
    height: 100%;
  }
`;
const Product = styled.div`
  background: #fff;
  border-radius: ${toRemLucky(80)};
  overflow: hidden;
  box-shadow: 0px ${toRemLucky(4)} ${toRemLucky(200)} 0px rgba(0, 0, 0, 0.15);

  // .color-change {
  //     color: ${RaffleColor} !important
  // }

  // &.week-view {
  //     border-radius: toRemLucky(20) toRemLucky(20) 0 0;
  //     background: ${RaffleColor};

  //     .color-change {
  //         color: var(--color-white-fixed) !important
  //     }
  // }
  .cancel-line-wrap {
    position: relative;
    padding: 0 ${toRemLucky(20)};

    .cancel-line {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      height: ${toRemLucky(4)};
      border-radius: ${toRemLucky(4)};
      background: ${RaffleColor};

      .triangle {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 1;
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
        border-top: ${toRemLucky(16)} solid transparent;
        border-left: ${toRemLucky(20)} solid ${RaffleColor};
        border-bottom: ${toRemLucky(16)} solid transparent;
      }
    }
  }
  .eventPrice {
        position: relative;
        top: ${toRemLucky(10)};
        line-height: 1;
    }
`;

const Visual = styled.div`
  position: relative;
  height: 330px;
  overflow: hidden;
  background: #f5f5f5 url(${props => props.thumbnailUrl}) no-repeat center
    center;
  background-size: cover;
  text-align: center;
  > img {
    width: 100%;
  }
  .timer-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .thumb {
    margin: 0 auto;
  }
  .winnerCount {
    position: absolute;
    right: ${toRemLucky(60)};
    top: ${toRemLucky(60)};
    width: ${toRemLucky(180)};
    height: ${toRemLucky(180)};
    border-radius: ${toRemLucky(180)};
    color: #fff;
    background: #fa7b44;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SEASON_CLOSED_DATE = '20241013235959';

function LuckyMarket() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mid, setMid] = useState(null);
  const [env, setEnv] = useState(null);
  useEffect(() => {
    const mid = queryParams.get('mid');
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
    setMid(mid);
  }, [queryParams]);

  useEffect(() => {
    if (null !== env) {
      const instance = axios.create({
        baseURL:
          env === 'production'
            ? 'https://app.gasapp.co.kr/api'
            : 'https://app-dev.gasapp.co.kr/api'
      });
      const fetchData = () => {
        instance
          .get('/nevent/raffle/public')
          .then(response => {
            setData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      };

      fetchData();
    }
  }, [env]);
  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content =
      'width=340, initial-scale=1, height=device-height, maximum-scale=1.0 ,user-scalable=no';

    // document.head에 메타태그 추가
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  //   const onClickButton = useCallback(() => {
  //     console.log(navigator);
  //     window.gtag('event', '예스코_이벤트_가스앱설치');
  //     const url = `https://m.gasapp.co.kr/app/launcherGasApp.html`;
  //     window.open(url);
  //   }, []);

  return (
    <div
      style={{
        maxWidth: DeviceUtil.isMobile ? '500px' : '360px',
        background: '#fff',
        margin: '0 auto'
      }}
    >
      <Stack direction="column" gap={120}>
        <LuckyMarketWrap>
          <img src={ImgRaffleTitle} className="market-title" alt="" />
          {data && (
            <LuckyWrap>
              <img src={ImgRaffleLeaf} className="leaf" alt="" />
              <div className="cards">
                <CharacterBack>
                  <div className="back"></div>
                </CharacterBack>
                <CharacterFront>
                  <div className="front"></div>
                </CharacterFront>
                <Product>
                  <div className="content">
                    <Stack direction="column">
                      <Visual thumbnailUrl={data.thumbnailUrl}>
                        {/* <img src={data.thumbnailUrl} alt="" /> */}
                        {moment().isAfter(
                          moment(SEASON_CLOSED_DATE, 'YYYYMMDDHHmmss')
                        ) ? (
                          <TimerWrap>
                            <img src={iconTimer} alt="" />
                            <Text
                              fontFamily="SUITE"
                              fontSize={52}
                              fontWeight={700}
                            >
                              행운의 마켓 시즌2 10월 13일(일) 종료
                            </Text>
                          </TimerWrap>
                        ) : (
                          <Timer
                            targetTime={data.toDatetime}
                            winnersPopDatetime={data.winnersPopDatetime}
                            nextRaffleFromDatetime={data.nextRaffleFromDatetime}
                            isOpened={data.entryClosed === 'N'}
                          />
                        )}
                        {!moment().isAfter(
                          moment(SEASON_CLOSED_DATE, 'YYYYMMDDHHmmss')
                        ) && (
                          <Text
                            fontSize={54}
                            fontWeight={700}
                            fontFamily={'SUITE'}
                            className="winnerCount"
                          >
                            {data.numberOfWinners}명
                          </Text>
                        )}
                      </Visual>
                      <Stack
                        direction="column"
                        className="group"
                        alignItems="center"
                      >
                        <Stack direction="column" alignItems="center">
                          <Text
                            fontFamily="SUITE"
                            fontSize={70}
                            fontWeight={700}
                          >
                            {data.productName}
                          </Text>
                          <Stack
                            direction="row"
                            gap={36}
                            alignItems="baseline"
                            justifyContent="center"
                          >
                            {moment().isAfter(
                              moment(SEASON_CLOSED_DATE, 'YYYYMMDDHHmmss')
                            ) ? (
                              <Text textSize="48" alignItems="center">
                                [혜택 &gt; 행운의 마켓] 메뉴는
                                <br />
                                10월 19일(토)까지 운영됩니다.
                              </Text>
                            ) : (
                              <>
                                <div className="cancel-line-wrap">
                                  <Text
                                    fontFamily="SUITE"
                                    fontSize={'48'}
                                    fontWeight={500}
                                  >
                                    {data.originPrice.toLocaleString()} 원
                                  </Text>
                                  <div className="cancel-line">
                                    <span className="triangle"></span>
                                  </div>
                                </div>
                                <Stack
                                  direction="row"
                                  alignItems="baseline"
                                  gap={5}
                                >
                                  <Text
                                    fontFamily="SUITE"
                                    fontSize={106}
                                    fontColor={RaffleColor}
                                    fontWeight={800}
                                    className="eventPrice"
                                  >
                                    {data.eventPrice}
                                  </Text>
                                  <Text
                                    fontFamily="SUITE"
                                    fontSize={68}
                                    fontColor={RaffleColor}
                                    textWeight={700}
                                  >
                                    원
                                  </Text>
                                </Stack>
                              </>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                      {/* info */}
                    </Stack>
                  </div>
                </Product>
                {/* 제품 카드 */}
              </div>
            </LuckyWrap>
          )}
        </LuckyMarketWrap>
        {!moment().isAfter(moment(SEASON_CLOSED_DATE, 'YYYYMMDDHHmmss')) && (
          <>
            <LuckyWrap
              className="button"
              onClick={() => {
                let url = null;
                if (env === 'production') {
                  url = `https://gasappmobile.page.link/?link=http://bit.ly/3adhNwn?link%3Dpathgasapp://0/raffle%26mid%3D${mid}&apn=com.biller.scg&amv=1220&isi=741949011&ibi=com.hanbill.SCGMO`;
                } else {
                  url = `https://gasappdev.page.link/?link=http://bit.ly/3adhNwn?link%3Dpathgasapp://0/raffle%26mid%3D${mid}&apn=com.biller.scg.debug&isi=741949011&ibi=com.scglab.WunderFlo.Internal`;
                }
                window.open(url);
              }}
            >
              <img src={ImgButton} alt="" width="100%" />
            </LuckyWrap>
            <LuckyWrap className="info">
              <Stack gap={60} direction="column">
                <Stack direction="column" alignItems="center">
                  <Text fontSize="64" fontWeight={700}>
                    한 눈에 보는 <Text fontColor={RaffleColor}>참여방법</Text>
                  </Text>
                </Stack>

                <img src={ImgHowToJoin} alt="" style={{ minWidth: '100%' }} />
              </Stack>
            </LuckyWrap>
          </>
        )}
        <DefaultBox className="gray">
          <img
            src={ImgNoti}
            alt=""
            width="100%"
            style={{ background: '#fff' }}
          />
        </DefaultBox>
      </Stack>
    </div>
  );
}

export default LuckyMarket;
