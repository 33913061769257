import React from 'react';
import SurveyStyled from '../components/survey/Survey.Styled';
import NaverPayImage from '../resources/images/survey/img_naver_pay.png';

function Survey() {
  return (
    <React.Fragment>
      <SurveyStyled>
        <div>
          <h3 className="title">
            가스앱을 사용하면서 <br />
            불편했던 점 있으세요?
          </h3>
          <p className="desc">
            서울도시가스 가스앱을 이용해 주셔서 감사합니다. 이번 설문조사를 통해
            소중한 의견을 모아 더 편리하고 유용한 가스앱을 만들어 나가겠습니다.
            <br />
            아래 버튼을 눌러 고객님의 소리를 들려주세요! <br />
            (가스앱에 접속한 뒤 설문 페이지에 연결 됩니다.)
          </p>
          <div className="image-wrap">
            <img className="large" src={NaverPayImage} alt="naverPay" />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.open(
                  'https://mobileservicecenter.page.link/InactiveSeoul'
                );
              } else {
                window.open(
                  'https://mobileservicecenterdev.page.link/externalLink'
                );
              }
            }}
          >
            설문조사하고 네이버페이 받기
          </button>
          <p className="desc2">
            * 설문조사 참여하신 모든분들께 현금처럼 사용 가능한 네이버페이
            포인트를 지급해 드립니다.
          </p>
        </div>
      </SurveyStyled>
    </React.Fragment>
  );
}

export default Survey;
