const Constant = {
  BILL_SEND_METHOD_MOBILE: '1', // 모바일 청구서
  BILL_SEND_METHOD_UNKNOWN: '2', // 알수없음
  BILL_SEND_METHOD_PAPER: '4', // 지로
  BILL_SEND_METHOD_LMS: '8', // LMS
  BILL_SEND_METHOD_KAKAOTALK: '16', // 카카오 알림톡
  BILL_SEND_METHOD_KAKAOPAY: '32', // 카카오페이
  BILL_SEND_METHOD_EMAIL: '64', // 이메일
  BILL_SEND_METHOD_POST: '128', // 우편
  BILL_SEND_METHOD_REGISTERED_POST: '256', // 등기우편
  BILL_SEND_METHOD_FAX: '512', // 팩스
  BILL_SEND_METHOD_PRE_NOTICE: '1024', // 선고지
  BILL_SEND_METHOD_NOT_MOBILE: '2048', // 모바일 청구서 아님 (현재는 대륜 전용)
  BILL_SEND_METHOD_ETC: '4096' // 기타
};

const Code = {};

Code.billSendMethodCodeList = [
  { name: '모바일 청구서', value: Constant.BILL_SEND_METHOD_MOBILE },
  { name: null, value: Constant.BILL_SEND_METHOD_NOT_MOBILE }, // 대륜 전용
  { name: '알수없음', value: Constant.BILL_SEND_METHOD_UNKNOWN },
  { name: '지로', value: Constant.BILL_SEND_METHOD_PAPER },
  { name: 'LMS', value: Constant.BILL_SEND_METHOD_LMS },
  { name: '카카오 알림톡', value: Constant.BILL_SEND_METHOD_KAKAOTALK },
  { name: '카카오페이', value: Constant.BILL_SEND_METHOD_KAKAOPAY },
  { name: '이메일', value: Constant.BILL_SEND_METHOD_EMAIL },
  { name: '우편', value: Constant.BILL_SEND_METHOD_POST },
  { name: '등기우편', value: Constant.BILL_SEND_METHOD_REGISTERED_POST },
  { name: '팩스', value: Constant.BILL_SEND_METHOD_FAX },
  { name: '선고지', value: Constant.BILL_SEND_METHOD_PRE_NOTICE }
];

export default Code;
