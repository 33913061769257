import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';

import styled from '@emotion/styled';

import Timer from '../components/common/Timer';
import LoadingBar from '../components/common/LoadingBar';
import Code from '../constants/Code';

import {
  Paper,
  Box,
  Button,
  VStack,
  HStack,
  Label,
  Text,
  Image,
  Tag,
  Noti,
  Inputbox,
  Radiobox,
  Selectbox,
  Divider,
  Data,
  ModalMiddle
} from '@gasapp/ui';
import { toRem } from '../utils/utils';

export const API_DOMAIN =
  process.env.NODE_ENV === 'production'
    ? 'https://app.gasapp.co.kr/api'
    : 'https://app-dev.gasapp.co.kr/api';

const api = axios.create({
  baseURL: API_DOMAIN
});

const StyledMobileWrap = styled.div`
  * {
    box-sizing: border-box;
  }

  .app-bar {
    padding: ${toRem(49)} ${toRem(80)};
    > span {
      font-weight: 400;
    }
  }
`;

function MemberLeave() {
  // const birthDateInputRef = useRef(null);
  // const handphoneInputRef = useRef(null);

  const [isTimerOpen, setTimerOpen] = useState(false);
  const [isFirstApiCall, setFirstApiCall] = useState(false);
  const [isShowLoading, setShowLoading] = useState(false);
  const [modalState, setModalState] = useState({
    content: '',
    subContent: '',
    isOpen: false
  });

  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState(null);

  const [leaveDatas, setLeaveDatas] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    gender: '',
    handphone: '',
    reason: ''
  });

  const onChangeForm = useCallback(e => {
    let { name, value } = e.target;

    const regExps = {
      name: () => {
        const regExp = /^[가-힣a-zA-Zㄱ-ㅎㅏ-ㅣㆍᆢ\s]*$/;
        if (!regExp.test(value)) return 'ERROR';
      },
      handphone: () => {
        const regExp = /[^0-9]/g;
        value = value.replace(regExp, '');
      },
      birthDate: () => {
        const regExp = /[^0-9]/g;
        value = value.replace(regExp, '');
      }
    };

    if (regExps[name] && regExps[name]() === 'ERROR') return;

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  // otp 입력
  const onChangeOtp = e => {
    setOtp(e.target.value);
  };

  // 다음으로 이동
  const checkKeyPress = event => {
    // 중간에 성별이 들어가서 일단 주석
    // if (event.keyCode === 13 || event.key === 'Enter') {
    //   birthDateInputRef.current.focus();
    // }
  };

  const closeModal = useCallback(() => {
    setModalState({ content: '', subContent: '', isOpen: false });
  }, []);

  // otp 번호 발급
  const getAuthNumber = () => {
    setShowLoading(true);
    const params = { ...formData, reason: undefined };
    api
      .post('web/member/leave/identify', { ...params })
      .then(response => {
        setShowLoading(false);
        setTimerOpen(false);
        setToken(response.data.token);
        setModalState({
          content: '인증번호가 발송되었습니다.',
          isOpen: true
        });
      })
      .catch(error => {
        setShowLoading(false);
        if (error.response?.status === 412) {
          setModalState({
            content: error.response.data.message,
            isOpen: true
          });
        } else {
          // todo 공통 에러
          setModalState({
            content: '처리 중 오류가 발생했습니다.',
            isOpen: true
          });
        }
      });
  };

  // 인증 토큰값이 바뀔때마다 타이머, otp 초기화
  useEffect(() => {
    if (null != token) {
      setFirstApiCall(true);
      setTimerOpen(true);
      setOtp(null);
    }
  }, [token]);

  // 인증번호 입력
  const setAuthConfirm = () => {
    setShowLoading(true);
    api
      .post('/web/member/leave/confirm', { token, otp })
      .then(response => {
        setShowLoading(false);
        let data = response.data;
        setToken(data.token);
        setTimerOpen(false);
        setLeaveDatas(data);
        setModalState({
          content: '인증에 성공하였습니다.',
          isOpen: true
        });
      })
      .catch(error => {
        setShowLoading(false);
        if (error.response.status === 412 || error.response.status === 400) {
          setModalState({
            content: error.response.data.message,
            isOpen: true
          });
        } else if (error.response.status === 510) {
          setModalState({
            content:
              '점검 중에는 탈퇴가 불가능합니다. 점검이 끝난 후 시도해 주시기 바랍니다.',
            isOpen: true
          });
        } else {
          setModalState({
            content: '처리 중 오류가 발생했습니다.',
            isOpen: true
          });
        }
      });
  };

  // 인증번호 입력 초과
  const afterTimeout = () => {
    setModalState({
      content: '인증 실패',
      subContent:
        '인증번호 입력 시간이 지났어요. [재전송]버튼을 눌러 다시 인증해 주세요.',
      isOpen: true
    });
    setTimerOpen(false);
  };

  // 탈퇴 신청
  const onLeave = () => {
    setShowLoading(true);
    api
      .put('/web/member/leave', {
        token: token,
        reason: formData.reason
      })
      .then(response => {
        setShowLoading(false);
        if (response.status === 200) {
          successClear();
        } else {
          setModalState({
            content:
              '가스앱 회원이 아닙니다.<br/>입력한 정보를 다시 확인해 주세요. ',
            isOpen: true
          });
        }
      })
      .catch(error => {
        setShowLoading(false);
        if (error.response.status === 412 || error.response.status === 400) {
          setModalState({
            content: error.response.data.message,
            isOpen: true
          });
        } else {
          setModalState({
            content: '처리 중 오류가 발생했습니다.',
            isOpen: true
          });
        }
      });
  };

  const successClear = () => {
    setOtp(null);
    setFormData({
      name: '',
      handphone: '',
      birthDate: '',
      gender: '',
      reason: ''
    });
    setFirstApiCall(false);
    setLeaveDatas(null);
    setModalState({
      content:
        '성공적으로 가스앱 회원에서 탈퇴했습니다. 그동안 정말 감사했습니다.',
      subContent:
        '※ 탈퇴일로부터 30일 이내에 동일한 휴대폰 번호로 재가입 하는 경우 캐시는 소멸되지 않습니다.',
      isOpen: true
    });
  };

  const getBillSendMethodListFilter = useCallback(billSendMethodCodeValue => {
    let billSendMethodList = null;
    let codeValueList = Code.billSendMethodCodeList;

    if (billSendMethodCodeValue) {
      billSendMethodList = codeValueList.find(data => {
        if (billSendMethodCodeValue & data.value) {
          return data;
        }
      });
    }
    return billSendMethodList;
  }, []);

  const disableGetOtpApiButton = useMemo(() => {
    const { name, birthDate, gender, handphone } = formData;
    return !name || !gender || birthDate.length !== 8 || handphone.length < 10;
  }, [formData]);

  return (
    <StyledMobileWrap>
      <div className="app-bar">
        <Text variant="56B">가스앱 계정 삭제</Text>
      </div>

      <Box padding={{ t: 80, l: 80, b: 120, r: 80 }}>
        <VStack gap={120}>
          {/* 상단 안내 */}
          <Paper backgroundColor="transparent">
            <VStack gap={40}>
              <HStack gap={15}>
                <Text color="blue" variant="52B">
                  계정 삭제 전에
                </Text>
                <Text variant="52B">꼭! 확인해 주세요.</Text>
              </HStack>

              <Noti
                contentProps={{ variant: '48', color: 'black' }}
                content="가스앱 계정 삭제 시 청구서는 도시가스별 기본 청구서 방식으로 변경되며, 다른 청구서 방식을 원하실 경우에는 이용 중인 도시가스로 문의해 주세요. 단, 가족회원이 가스앱을 이용 중일 경우에는 모바일 청구서가 유지됩니다."
              />

              <Noti
                contentProps={{ variant: '48', color: 'black' }}
                content="가스앱 계정 삭세 시 가스락도 함께 탈퇴되며, 캐시는 한달 후 자동 소멸됩니다."
              />
            </VStack>
          </Paper>

          <VStack gap={60}>
            <Inputbox
              title="이름"
              name="name"
              placeholder="이름을 입력해주세요"
              required
              value={formData.name}
              onChange={onChangeForm}
              onKeyUp={event => {
                checkKeyPress(event);
              }}
            />

            <Inputbox
              title="생년월일"
              // orgRef={birthDateInputRef}
              type="tel"
              name="birthDate"
              placeholder="숫자 8자리를 입력해주세요"
              required
              value={formData.birthDate}
              maxLength={8}
              onChange={onChangeForm}
            />

            <VStack gap={30}>
              <Label title="성별" required />
              <Radiobox
                name="gender"
                value={formData.gender}
                options={[
                  { name: '남성', value: 'M' },
                  { name: '여성', value: 'F' }
                ]}
                squareStyle
                onChange={onChangeForm}
              />
            </VStack>

            <Inputbox
              title="휴대폰 번호"
              // orgRef={handphoneInputRef}
              type="tel"
              name="handphone"
              placeholder="-없이 숫자만 입력해주세요"
              required
              value={formData.handphone}
              maxLength={11}
              onChange={onChangeForm}
              inputButton
              inputButtonTitle={isFirstApiCall ? '재전송' : '인증번호 전송'}
              inputButtonDisabled={disableGetOtpApiButton}
              inputButtonClick={getAuthNumber}
            />

            <Inputbox
              title="인증번호"
              subTitle={
                isTimerOpen && (
                  <Timer
                    className="col_red h_44_light"
                    time={59 * 1000}
                    afterTimeout={afterTimeout}
                  />
                )
              }
              type="tel"
              name="otp"
              placeholder="SMS로 전송된 인증번호 6자리를 입력해주세요"
              required
              value={otp || ''}
              maxLength={6}
              onChange={onChangeOtp}
              inputButton
              inputButtonTitle={isTimerOpen && '인증'}
              inputButtonDisabled={!otp || otp.length !== 6}
              inputButtonClick={setAuthConfirm}
            />

            <Selectbox
              title="탈퇴사유"
              placeholder="선택해 주세요"
              options={[
                { name: '이사로 인한 탈퇴', value: '이사로 인한 탈퇴' },
                { name: '서비스 사용 불편', value: '서비스 사용 불편' },
                { name: '기타', value: '기타' }
              ]}
              name="reason"
              value={formData.reason}
              onChange={onChangeForm}
            />
          </VStack>
        </VStack>
      </Box>

      {leaveDatas && (
        <>
          <Divider type="group" />

          <Box padding={{ t: 120, r: 80, b: 120, l: 80 }}>
            <VStack gap={60}>
              <Text variant="56B">{`${leaveDatas.name}님의 도시가스 계약 정보를\n확인해 주세요.`}</Text>
              {leaveDatas?.confirmContracts?.length > 0 ? (
                leaveDatas.confirmContracts.map(data => {
                  const billSendMethodName =
                    getBillSendMethodListFilter(data.billSendMethod)?.name ||
                    '';
                  return (
                    <div key={data.useContractNum}>
                      <VStack gap={20}>
                        <Paper backgroundColor="transparent">
                          <VStack gap={40}>
                            <HStack gap={20} alignItems="center">
                              <HStack gap={10} alignItems="center">
                                <Image
                                  width={100}
                                  height={100}
                                  image={`https://app.gasapp.co.kr/${data.icon}`}
                                />
                                <Text variant="56B">{data.companyName}</Text>
                              </HStack>
                              {billSendMethodName !== '' && (
                                <Tag title={billSendMethodName} />
                              )}
                            </HStack>
                            <Data
                              title={data.contractLabelName}
                              titleProps={{
                                variant: '44',
                                color: 'gray-888888'
                              }}
                              titleWidth={260}
                              value={data.contractLabel}
                              valueProps={{ variant: '48' }}
                              valueHorizontalAlign="start"
                            />
                            <Data
                              title="주소"
                              titleProps={{
                                variant: '44',
                                color: 'gray-888888'
                              }}
                              titleWidth={260}
                              value={`${data.address.address1}\n${data.address.address2}`}
                              valueProps={{ variant: '48' }}
                              valueHorizontalAlign="start"
                            />
                          </VStack>
                        </Paper>
                        <Text variant="42" style={{ color: '#00000080' }}>
                          ※ 가스앱 탈퇴 시, 청구서 유형이 “{billSendMethodName}
                          ”로 변경되고 자가검침이 자동해지 됩니다.
                        </Text>
                      </VStack>
                    </div>
                  );
                })
              ) : (
                <Text>탈퇴 버튼을 누르면 계정 삭제가 완료됩니다.</Text>
              )}
            </VStack>
          </Box>
        </>
      )}

      <Box padding={{ l: 80, b: 80, r: 80 }}>
        <VStack gap={40}>
          <Button title="탈퇴" disabled={!leaveDatas} onClick={onLeave} />
          <Button variant="secondary" title="취소" />
        </VStack>
      </Box>

      <ModalMiddle
        type="alert"
        isOpen={modalState.isOpen}
        ok={closeModal}
        onRequestClose={closeModal}
      >
        <VStack gap={20}>
          {modalState.content !== '' && (
            <Text variant="48">{modalState.content}</Text>
          )}
          {modalState.subContent !== '' && (
            <Text variant="42" color="gray-888888">
              {modalState.subContent}
            </Text>
          )}
        </VStack>
      </ModalMiddle>
      <LoadingBar open={isShowLoading} />
    </StyledMobileWrap>
  );
}

export default MemberLeave;
