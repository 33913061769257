import { css } from '@emotion/core';
import styled from '@emotion/styled';
export const toRem = px => {
  return px / 45 + 'rem';
};
export const toRemLucky = value => {
  return value / 50 + 'rem';
};

export const FontStyle = props => css`
  font-size: ${toRemLucky(props.fontSize)};
  font-family: ${props.fontFamily
    ? props.fontFamily
    : props.fontWeight === 'bold' || props.fontWeight === 700
    ? 'NanumSquareB'
    : 'NanumSquareR'};
  font-weight: ${props.fontWeight};
  color: ${props.fontColor || '#000'};
  text-align: ${props.alignItems || 'left'};
`;
export const Text = styled.span`
  ${FontStyle}
`;

export const StackStyle = props => css`
  align-items: ${props.alignItems};
  justify-content: ${props.justifyContent};
  justify-content: ${props.justifyContent};
  flex-direction: ${props.direction};
  gap: ${props.gap ? toRemLucky(props.gap) : 0};
`;
export const Stack = styled.div`
  display: flex;
  ${StackStyle}
  &.group {
    padding: ${toRemLucky(40)} 0 ${toRemLucky(60)};
  }
  &.padding {
    padding: ${toRemLucky(80)};
  }
`;

export const PaperStyle = props => css`
  background: ${props.backgroundColor || 'rgb(221 221 221 / 10%)'};
`;

export const WunderPaper = styled.div`
  ${PaperStyle}
  padding: ${toRemLucky(55)} ${toRemLucky(60)};
  border-radius: ${toRemLucky(40)};
  position: relative;
  overflow: hidden;
  &.nopadding {padding:0;}
  
`;

export const RaffleColor = '#00B2FF';
