import React from 'react';
import GateStyled from '../components/cash/Gate.Styled';
import YouTube from 'react-youtube';
import EcoImg from '../resources/images/naverGate/img-eco.png';

function NaverGateEco() {
  const videoOptions = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0
    }
  };
  return (
    <React.Fragment>
      <GateStyled>
        <div>
          <h3 className="title">에코 마일리지를 캐시로</h3>
          <p className="sub-title">
            서울시 에코마일리지를
            <br />
            가스앱 캐시로 전환해 보세요.
            <br />
            요금 납부나 현금으로 인출할 수 있어요!
          </p>
          <div className="image-wrap">
            {/* https://youtu.be/?si=YIgeCf6Wwd0GjxmM */}
            {/* <img src={EcoImg} alt="" style={{ marginBottom: '100px' }} /> */}
            <YouTube videoId={'QEUJ1u0HFL8'} opts={videoOptions} />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              window.open('https://mobileservicecenter.page.link/livebill');
            }}
          >
            가스앱 설치하고 캐시 전환
          </button>
        </div>
      </GateStyled>
    </React.Fragment>
  );
}

export default NaverGateEco;
