import React, { useState } from 'react';
function ConditionSelect({ id, datas, condition, handleChangeCondition }) {
  const [active, setActive] = useState(false);
  let timeOut = null;

  const handleSelectList = () => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      setActive(false);
    }, 200);
  };

  return (
    <div className={active ? 'select active' : 'select'}>
      <div
        className="selected"
        id={id}
        onMouseEnter={() => {
          setActive(true);
        }}
        onMouseLeave={() => {
          handleSelectList();
        }}
      >
        <div className="selected-value">{condition[id].name}</div>
        <div className="arrow"></div>
      </div>
      {datas && (
        <div
          className="select-list"
          onMouseEnter={() => {
            if (timeOut) {
              clearTimeout(timeOut);
            }
            setActive(true);
          }}
          onMouseLeave={() => {
            setActive(false);
          }}
        >
          <ul className={active ? 'active' : ''}>
            {datas.map((data, index) => {
              return (
                <li
                  className="option"
                  key={index}
                  onClick={e => {
                    setActive(false);
                    handleChangeCondition(id, data);
                  }}
                >
                  {data.name}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ConditionSelect;
