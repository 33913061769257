import React from 'react';
import { Privacy } from '../components/main/kor';
import Footer from '../layout/Footer';

export default function Policy(props) {
  return (
    <React.Fragment>
      <Privacy />
      <Footer />
    </React.Fragment>
  );
}
