import * as React from 'react';
import { useCallback } from 'react';
import styled from '@emotion/styled';

import YescoPromotionSmallImage from '../resources/images/welcomePromotionYesco/img_yesco_promotion_s.png';
import YescoPromotionLargeImage from '../resources/images/welcomePromotionYesco/img_yesco_promotion_l.png';
import ButtonImage from '../resources/images/welcomePromotionYesco/img_button.svg';

import { mq } from '../components/main/kor/GasApp.Styled';

//////////////////////////////////////
// 추가 2022.12.14
// 예스코 신규 회원 이벤트
// 2023년 1월 14일 이후 삭제 예정
//////////////////////////////////////
const YescoEventWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  // width: 30%;
  // ${mq[1]} {
  //   width: 50%;
  // }
  // ${mq[0]} {
  //   width: 100%;
  // }
`;

const ImageBox = styled.div`
  width: 100%;
  display: inline-block;

  > img {
    width: 100%;
    &.small {
      display: none;
      ${mq[0]} {
        display: block;
      }
    }
    &.large {
      display: block;
      ${mq[0]} {
        display: none;
      }
    }
  }
`;

const Button = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 4.5%;

  > div {
    display: inline-block;
    width: calc(100vw / 3);
    min-width: 410px;

    ${mq[0]} {
      width: 85%;
      min-width: 0;
    }

    > img {
      width: 100%;
    }
  }
`;

// const Button = styled.button`
//   width: 100%;
//   z-index: 100;
//   position: absolute;
//   bottom: 5%;
//   display: block;
// `;

function YescoEvent() {
  const onClickButton = useCallback(() => {
    console.log(navigator);
    window.gtag('event', '예스코_이벤트_가스앱설치');
    const url = `https://m.gasapp.co.kr/app/launcherGasApp.html`;
    window.open(url);
  }, []);

  return (
    <YescoEventWrapper>
      <ImageBox>
        <img className="small" src={YescoPromotionSmallImage} alt="yesco" />
        <img className="large" src={YescoPromotionLargeImage} alt="yesco" />
      </ImageBox>
      <Button onClick={onClickButton}>
        <div>
          <img src={ButtonImage} alt="button" />
        </div>
      </Button>
    </YescoEventWrapper>
  );
}

export default YescoEvent;
