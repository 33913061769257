import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import ImgEventImage1 from '../resources/images/hanbill/img-1.png';
import ImgEventImage2 from '../resources/images/hanbill/img-2.png';
import ImgEventImage3 from '../resources/images/hanbill/img-3.png';
import ImgEventImage4 from '../resources/images/hanbill/img-4.png';
import ImgEventImage5 from '../resources/images/hanbill/img-5.png';
import ImgEventImage6 from '../resources/images/hanbill/img-6.png';
import ImgEventImage7 from '../resources/images/hanbill/img-7.png';
import ImgEventImage8 from '../resources/images/hanbill/img-8.png';
import ImgEventImage9 from '../resources/images/hanbill/img-9.png';
import ImgEventImageBtn from '../resources/images/hanbill/img-btn.png';
import ImgEventLinkBtn from '../resources/images/hanbill/img-btn-link.png';
import ImgEventKakaoBtn from '../resources/images/hanbill/img-btn-kakao.png';
import ImgEventFixedBtn from '../resources/images/hanbill/img-btn-fixed.png';
import { toRemLucky, Stack } from '../utils/utils';
import DeviceUtil from '../utils/DeviceUtil';
import moment from 'moment/moment';
import { usePageTracking, clickTracking } from '../hook/useTracking';

const TimerWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${toRemLucky(136)};
  background: rgba(224, 224, 224, 0.65);
  gap: ${toRemLucky(10)};
  img {
    width: ${toRemLucky(60)};
  }
`;

const SummerEventWrap = styled.div`
  .round_btn {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
    overflow: hidden;
    text-align: center;
    color: #fff !important;
    font-family: 'nanumsquareB';
    display: block;
  }
  .bg_E8F8FF {
    background: #e8f8ff;
  }
  .bg_3a4a59 {
    background: #f5f5f5;
    padding-bottom: 7rem;
  }
`;

const FixedButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

function HanBillInviteEvent() {
  usePageTracking('한빌친구초대_hid');
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hid, setHid] = useState(null);
  const [env, setEnv] = useState(null);
  useEffect(() => {
    const hid = queryParams.get('hid');
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
    setHid(hid);
  }, [queryParams]);

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content =
      'width=340, initial-scale=1, height=device-height, maximum-scale=1.0 ,user-scalable=no';

    // document.head에 메타태그 추가
    document.head.appendChild(metaTag);
    document.title = '럭키비키한 가스앱 이벤트';
    return () => {
      document.head.removeChild(metaTag);
      document.title = 'SCGLAB';
    };
  }, []);

  const openLink = () => {
    clickTracking('한빌친구초대_hid_버튼');
    let url = null;
    if (env === 'production') {
      url = `https://gasappmobile.page.link/?link=http://bit.ly/3adhNwn?link%3Dpathgasapp://0/hanbill/promotionEvent2024Summer%26hid%3D${hid}&apn=com.biller.scg&isi=741949011&ibi=com.hanbill.SCGMO`;
    } else {
      url = `https://gasappdev.page.link/?link=http://bit.ly/3adhNwn?link%3Dpathgasapp://0/hanbill/promotionEvent2024Summer%26hid%3D${hid}&apn=com.biller.scg.debug&isi=741949011&ibi=com.scglab.WunderFlo.Internal`;
    }
    window.open(url);
  };

  return (
    <div
      style={{
        maxWidth: DeviceUtil.isMobile ? '500px' : '360px',
        background: '#fff',
        margin: '0 auto'
      }}
    >
      <SummerEventWrap>
        <Stack direction="column">
          <img src={ImgEventImage1} alt="" width="100%" />
          <img src={ImgEventImage2} alt="" width="100%" />
          <img src={ImgEventImage3} alt="" width="100%" />
          <img src={ImgEventImage4} alt="" width="100%" />

          <img src={ImgEventImage5} alt="" width="100%" />
          <img src={ImgEventImage6} alt="" width="100%" />
          <img src={ImgEventImage7} alt="" width="100%" />
          <Stack
            direction="column"
            className="padding bg_E8F8FF"
            gap={30}
            onClick={() => {
              openLink();
            }}
          >
            <img src={ImgEventLinkBtn} alt="" width="100%" />
            <img src={ImgEventKakaoBtn} alt="" width="100%" />
          </Stack>
          <img src={ImgEventImage8} alt="" width="100%" />
          <div
            onClick={() => {
              openLink();
            }}
          >
            <img src={ImgEventImageBtn} alt="" width="100%" />
          </div>
          <img src={ImgEventImage9} alt="" width="100%" className="bg_3a4a59" />
        </Stack>
        <FixedButton
          onClick={() => {
            openLink();
          }}
        >
          <img src={ImgEventFixedBtn} alt="" width="100%" />
        </FixedButton>
      </SummerEventWrap>
    </div>
  );
}

export default HanBillInviteEvent;
