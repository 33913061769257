import React from 'react';
import FooterStyled from './Footer.Styled';
import { logo } from '../resources/images';
import { Link } from 'react-router-dom';
import ImgAppleStore from '../resources/images/img_apple_store.svg';
import ImgPlayStore from '../resources/images/img_play_store.svg';
import ImgYoutube from '../resources/images/img_youtube.svg';
import ImgBlog from '../resources/images/img_blog.svg';

export default function FooterEn(props) {
  return (
    <FooterStyled>
      <div className="footer_en">
        <div className="top">
          <img src={logo} alt="logo" />
        </div>

        <div className="bottom">
          <div className="left">
            <div className="info">SCGLAB, Inc</div>
            <dl>
              <dt>
                <Link to={`/Policy`}>Privacy Policy</Link>
              </dt>
              <dd>Copyright © 2020&nbsp; SCG LAB. All Rights Reserved.</dd>
            </dl>
          </div>

          <div className="center">
            <dl>
              <dt>Address</dt>
              <dd>
                03991, 3, 5, 6, World Cup buk-ro 6-gil, Mapo-gu, Seoul, Republic
                of Korea
              </dd>
            </dl>
            <dl>
              <dt>E-mail</dt>
              <dd>hello@scglab.com</dd>
            </dl>
          </div>

          <div className="right">
            <div className="title">Gas App Download</div>
            <div className="icons">
              <span className="store">
                <img
                  src={ImgAppleStore}
                  alt="appleStore"
                  onClick={() =>
                    window.open('https://apps.apple.com/kr/app/id741949011')
                  }
                />
                <img
                  src={ImgPlayStore}
                  alt="playStore"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.biller.scg'
                    )
                  }
                />
              </span>
              <span className="bl"></span>
              <span className="sns">
                <img
                  src={ImgYoutube}
                  alt="youtube"
                  onClick={() => window.open('https://m.youtube.com/@energy_T')}
                />
                <img
                  src={ImgBlog}
                  alt="blog"
                  onClick={() => window.open('https://blog.naver.com/gasapp/')}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
}
