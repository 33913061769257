import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';

import { Stack, toRem } from '../utils/utils';
import { Image, Text } from '@gasapp/ui';

import EventKakaoSeoul from '../resources/images/boilerEvent/event-kakao-seoul.png';
import EventKakaoSeoulBtn from '../resources/images/boilerEvent/event-kakao-seoul-btn.png';
import EventKakao2_1 from '../resources/images/boilerEvent/event-kakao-2-1-seoul.png';
import EventKakao2_2 from '../resources/images/boilerEvent/event-kakao-2-2.png';
import EventMain1 from '../resources/images/boilerEvent/event-main-1.png';
import EventMain2 from '../resources/images/boilerEvent/event-main-2.png';
import EventMain3 from '../resources/images/boilerEvent/event-main-3.png';
import Event1_1 from '../resources/images/boilerEvent/event-1-1.png';
import Event1_2 from '../resources/images/boilerEvent/event-1-2.png';
import Event1_3 from '../resources/images/boilerEvent/event-1-3.png';
import Event2_1 from '../resources/images/boilerEvent/event-2-1.png';
import Event2_2 from '../resources/images/boilerEvent/event-2-2.png';
import Event2_3 from '../resources/images/boilerEvent/event-2-3.png';
import Event2_4 from '../resources/images/boilerEvent/event-2-4.png';
import Event2_5 from '../resources/images/boilerEvent/event-2-5.png';
import Tab1_active from '../resources/images/boilerEvent/tab1-active.png';
import Tab1_inactive from '../resources/images/boilerEvent/tab1-inactive.png';
import Tab2_active from '../resources/images/boilerEvent/tab2-active.png';
import Tab2_inactive from '../resources/images/boilerEvent/tab2-inactive.png';
import { eventBg } from '../resources/images';
import IcoHeart from '../resources/images/boilerEvent/ico-heart.svg';
import BtnMore from '../resources/images/boilerEvent/btn-more.png';
import ImgNoti from '../resources/images/boilerEvent/img-noti.png';
import axios from 'axios';
import { clickTracking, usePageTracking } from '../hook/useTracking';
import IcoInstagram from '../resources/images/boilerEvent/ico-instagram.png';
import IcoBlog from '../resources/images/boilerEvent/ico-blog.png';

const BoilerEventKakaoSeoulWrap = styled.div`
  .join {
    background: #fff7e4;
    padding-top: ${toRem(80)};

    .wunder-textarea .wunder-textarea-wrap textarea.wunder-textarea-area {
      font-family: nanumsquare;
    }

    .wunder-textarea .wunder-textarea-wrap .wunder-textarea-placeholder {
      font-size: ${toRem(48)} !important;
    }
    .wunder-button {
      &.variant-lower {
        width: fit-content;
        padding: ${toRem(25)} ${toRem(60)};
        color: #ff6817;
        border-color: #ff6817;
        background-color: white;
      }
    }

    // 탭
    .tabs {
      border-bottom: ${toRem(10)} solid #ff6817;
      padding: 0 ${toRem(80)};

      .wunder-image {
        > img {
          transform: scale(1, 1.01);
        }
      }
      .tab {
        padding: ${toRem(40)} 0;
        border-radius: ${toRem(36)} ${toRem(36)} 0 0;
        width: 100%;
        text-align: center;
        font-size: ${toRem(56)};
        font-family: 'HakgyoansimDunggeunmisoB';

        &.active {
          background: #ff6817;
          color: white;
        }
        &.inactive {
          background: white;
          color: var(--color-gray-888888);
        }
      }
    }

    .tab-contents {
      width: 100%;
      .story-list {
        width: 100%;
        padding: ${toRem(100)} ${toRem(80)};
      }
      .story-box {
        width: 100%;
        background: var(--color-white);
        border-radius: 0 ${toRem(120)} ${toRem(120)} ${toRem(120)};
        padding: ${toRem(70)} ${toRem(80)};
        word-break: break-all;
        overflow-wrap: break-word;

        > div {
          margin-bottom: ${toRem(40)};

          > span {
            width: 100%;
            word-break: keep-all;
          }
        }
      }
    }

    .exclamation .icon > svg > path {
      text-align: center;
      fill: #ff6817 !important;
    }
  }

  .event-2 {
    display: inline-block;
    position: relative;

    > img {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        position: absolute;
        top: 47%;
        left: 15%;
        width: 36%;
      }
    }
  }

  .how-to-join {
    background: url(${eventBg}) no-repeat;
    .event2-3 {
      position: relative;

      .icos {
        width: 19.16%;
        position: absolute;
        top: 16%;
        left: 48%;
      }
    }
  }

  .event-seoul {
    display: inline-block;
    position: relative;

    > img {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 4.68%;
        width: 83%;
        //height: 7.5%;
      }
    }
  }
`;

function BoilerEventKakaoSeoul() {
  usePageTracking('보일러 이벤트 알림');
  const joinRef = useRef(null);
  const [activeTab, setActiveTab] = useState('STORY');
  const [otherStoryData, setOtherStoryData] = useState([]);
  const [otherSnsData, setOtherSNSData] = useState([]);
  const [isStoryPageEnd, setIsStoryPageEnd] = useState(false);
  const [isSnsPageEnd, setIsSnsPageEnd] = useState(false);
  const [storyLastId, setStoryLastId] = useState(0);
  const [snsLastId, setSnsLastId] = useState(0);

  useEffect(() => {
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content =
      'width=340, initial-scale=1, height=device-height, maximum-scale=1.0 ,user-scalable=no';

    // document.head에 메타태그 추가
    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  const getOtherJoinData = joinType => {
    const instance = axios.create({
      baseURL: 'https://app.gasapp.co.kr/api'
    });

    const fetchData = () => {
      instance
        .get(`nevent/boilermall-openevent/joins/${joinType}`, {
          params: {
            lastId: joinType === 'STORY' ? storyLastId : snsLastId,
            limit: 5
          }
        })
        .then(res => {
          let data = res.data;
          if (data.length < 5)
            joinType === 'STORY'
              ? setIsStoryPageEnd(true)
              : setIsSnsPageEnd(true);
          if (data.length) {
            if (joinType === 'STORY') {
              setOtherStoryData(prevState => {
                return [...prevState, ...data];
              });
              setStoryLastId(Number(data[data.length - 1].joinId));
            } else {
              setOtherSNSData(prevState => {
                return [...prevState, ...data];
              });
              setSnsLastId(Number(data[data.length - 1].joinId));
            }
          }
        });
    };
    fetchData();
  };

  useEffect(() => {
    if (activeTab === 'STORY' && otherStoryData.length === 0) {
      // 다른 사람 사연 조회
      getOtherJoinData(activeTab);
    }
    if (activeTab === 'SNS' && otherSnsData.length === 0) {
      // 다른 사람 SNS 조회
      getOtherJoinData(activeTab);
    }
  }, [activeTab]);

  const onClickButton = type => {
    if (type === 'share') alert('본 이벤트는 앱에서 참여 가능합니다.');

    let url = `https://gasappmobile.page.link/${
      type === 'share'
        ? 'boilerSaleEvent'
        : type === 'mall'
        ? 'boilerInputForm'
        : 'boilerIntro'
    }`;

    window.open(url);
  };

  const copyLink = () => {
    clickTracking('이벤트_링크_복사');
    let prevUrl =
      'https://www.scglab.com/boilerEventKakaoSeoul.html?link=http://www.scglab.com/#/boilerEventKakaoSeoul?env=production';
    const el = document.createElement('textarea');
    el.value = prevUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    alert('이벤트 링크가 복사되었습니다!');
  };

  return (
    <div
      style={{
        background: '#fff',
        margin: '0 auto'
      }}
    >
      <BoilerEventKakaoSeoulWrap>
        <Stack gap={20} direction="column">
          <Stack gap={20} direction="column">
            <Stack gap={20} direction="column">
              <div className="event-seoul">
                <img src={EventKakaoSeoul} />
                <img
                  src={EventKakaoSeoulBtn}
                  onClick={() => onClickButton('intro')}
                />
              </div>
              <div className="event-2">
                <img src={EventKakao2_1} />
                <img
                  className="btn-life-contents"
                  src={EventKakao2_2}
                  onClick={() => {
                    window.open(
                      'https://gasappmobile.page.link/boilerLifeContents'
                    );
                  }}
                />
              </div>
            </Stack>
            <Stack direction="column">
              <Image image={EventMain1} />
              <Image
                image={EventMain2}
                onClick={() => {
                  clickTracking('역대급_할인_보일러_구매하기');
                  onClickButton('mall');
                }}
              />
              <Image image={EventMain3} />
            </Stack>
            <Stack direction="column">
              <Image image={Event1_1} />
              <Image
                image={Event1_2}
                onClick={() => {
                  clickTracking('사연_남기기');
                  onClickButton('share');
                }}
              />
              <Image image={Event1_3} />
            </Stack>
            <Stack direction="column" className="how-to-join">
              <Image image={Event2_1} />
              <Image image={Event2_2} onClick={copyLink} />
              <div className="event2-3">
                <Image image={Event2_3} />
                <Stack direction="row" gap={30} className="icos">
                  <Image
                    image={IcoInstagram}
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/p/DBLMso1vzDS/?img_index=1',
                        '_blank'
                      )
                    }
                  />
                  <Image
                    image={IcoBlog}
                    onClick={() =>
                      window.open('https://blog.naver.com/gasapp', '_blank')
                    }
                  />
                </Stack>
              </div>
              <Image
                image={Event2_4}
                onClick={() => {
                  clickTracking('공유_인증하기');
                  onClickButton('share');
                }}
              />
              <Image image={Event2_5} />
            </Stack>
          </Stack>
          <Stack className="join" ref={joinRef} direction="column">
            <Stack direction="row" className="tabs" justifyContent="center">
              {/*<div*/}
              {/*  className={`tab ${*/}
              {/*    activeTab === 'STORY' ? 'active' : 'inactive'*/}
              {/*  }`}*/}
              {/*  onClick={() => setActiveTab('STORY')}*/}
              {/*>*/}
              {/*  보일러 선물 사연*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  className={`tab ${activeTab === 'SNS' ? 'active' : 'inactive'}`}*/}
              {/*  onClick={() => setActiveTab('SNS')}*/}
              {/*>*/}
              {/*  SNS 공유 인증*/}
              {/*</div>*/}
              <Image
                image={activeTab === 'STORY' ? Tab1_active : Tab1_inactive}
                onClick={() => setActiveTab('STORY')}
              />
              <Image
                image={activeTab === 'SNS' ? Tab2_active : Tab2_inactive}
                onClick={() => setActiveTab('SNS')}
              />
            </Stack>

            {activeTab === 'STORY' ? (
              <Stack gap={100} className="tab-contents">
                {otherStoryData && otherStoryData.length > 0 && (
                  <Stack
                    gap={80}
                    className="story-list"
                    direction="column"
                    alignItems="center"
                  >
                    {otherStoryData?.map(item => {
                      return (
                        <div className="story-box" key={item.joinId}>
                          <Stack direction="row" gap={20} alignItems="center">
                            {item.featuredYn === 'Y' && (
                              <Image image={IcoHeart} width={74} />
                            )}
                            <Text variant="48B">{item.maskedName}</Text>
                          </Stack>
                          <Text variant="48" color="gray-666666">
                            {item.content}
                          </Text>
                        </div>
                      );
                    })}
                    {!isStoryPageEnd && (
                      <Image
                        image={BtnMore}
                        width={400}
                        height={100}
                        onClick={() => getOtherJoinData('STORY')}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack gap={100} className="tab-contents">
                {otherSnsData && otherSnsData.length > 0 && (
                  <Stack
                    gap={80}
                    className="story-list"
                    direction="column"
                    alignItems="center"
                  >
                    {otherSnsData?.map(item => {
                      return (
                        <div className="story-box" key={item.joinId}>
                          <Stack direction="row" gap={20} alignItems="center">
                            {item.featuredYn === 'Y' && (
                              <Image image={IcoHeart} width={74} />
                            )}
                            <Text variant="48B">{item.maskedName}</Text>
                          </Stack>

                          <Text variant="48" color="gray-666666">
                            {item.content}
                          </Text>
                        </div>
                      );
                    })}
                    {!isSnsPageEnd && (
                      <Image
                        image={BtnMore}
                        width={400}
                        height={100}
                        onClick={() => getOtherJoinData('SNS')}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>

          <Image image={ImgNoti} />
        </Stack>
      </BoilerEventKakaoSeoulWrap>
    </div>
  );
}

export default BoilerEventKakaoSeoul;
