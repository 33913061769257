import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const NewGasAppStyled = styled.section`
  padding: 270px 0 271px;
  text-align: center;
  ${mq[0]} {
    padding: 75px 15px;
  }
  > div {
    padding: 0 40px 0 250px;
    ${mq[0]} {
      padding: 0;
    }
    figure {
      margin-bottom: 50px;
      .newapp {
        width: 616px;
        ${mq[0]} {
          width: 250px;
          margin-bottom: 0;
        }
      }
    }

    h2 {
      font-family: 'NanumSquareL';
      font-size: 35px;
      line-height: 2;
      ${mq[0]} {
        line-height: 1.3;
        font-size: 25px;
        margin-bottom: 15px;
      }
      .m {
        display: none;
        ${mq[0]} {
          display: block;
        }
      }
      strong {
        font-family: 'NanumSquareB';
      }
    }
    p {
      font-size: 20px;
      line-height: 1.3;
      font-family: 'NanumSquareL';
      ${mq[0]} {
        font-size: 13px;
        line-height: 1.6;
      }
      .m {
        ${mq[0]} {
          display: none;
        }
      }
      strong {
        font-family: 'NanumSquareB';
      }
    }
  }
`;
export default NewGasAppStyled;
