import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import Timer from '../components/lucky/Timer';
import ImgRaffleTitle from '../resources/images/lucky/img-card-market-text.png';
import ImgRaffleInfo1 from '../resources/images/lucky/img-raffle-info-1.png';
import ImgRaffleInfo2 from '../resources/images/lucky/img-raffle-info-2.png';
import ImgRaffleInfo3 from '../resources/images/lucky/img-raffle-info-3.png';
import ImgRaffleInfo4 from '../resources/images/lucky/img-raffle-info-4.png';
import ImgNoti from '../resources/images/lucky/img-noti.png';
import ImgButton from '../resources/images/lucky/img-button.png';
import { productBg, iconBack, iconFront, iconTimer } from '../resources/images';
import {
  toRemLucky,
  StackStyle,
  FontStyle,
  Text,
  Stack,
  RaffleColor,
  WunderPaper
} from '../utils/utils';
import axios from 'axios';

import { mq } from '../components/main/kor/GasApp.Styled';

const DefaultBox = styled.div`
  width: 100%;

  margin: 0 auto;
  text-align: center;
  > img {
    max-width: 360px;
  }
  &.gray {
    background: #bbbbbb26;
  }
`;

function WinterEvenElec() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [env, setEnv] = useState('production');
  useEffect(() => {
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
  }, [queryParams]);

  return (
    <div style={{ maxWidth: '360px', background: '#fff', margin: '0 auto' }}>
      <Stack direction="column">
        <p>전기 이벤트</p>
        <DefaultBox
          className="button"
          onClick={() => {
            let url = null;
            if (env === 'production') {
              url = `https://gasappmobile.page.link/winterEventElec`;
            } else {
              url = `https://gasappdev.page.link/winterEventElec`;
            }

            window.open(url);
          }}
        >
          <img src={ImgButton} alt="" width="100%" />
        </DefaultBox>

        <DefaultBox>
          <img src={ImgNoti} alt="" width="100%" />
        </DefaultBox>
      </Stack>
    </div>
  );
}

export default WinterEvenElec;
