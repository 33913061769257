import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const GasLockStyled = styled.section`
  padding: 300px 0 124px;
  text-align: center;
  .w {
    display: none;
  }
  ${mq[0]} {
    padding: 75px 0;
  }
  ${mq[0]} {
    .m {
      display: none;
    }
    .w {
      display: block;
    }
  }
  > div {
    padding: 0 40px 0 250px;
    ${mq[0]} {
      padding: 0;
    }
    span {
      display: block;
      font-family: 'NanumSquareL';
      font-size: 43px;
      ${mq[0]} {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    h2 {
      font-size: 60px;
      line-height: 1.35;
      margin-bottom: 24px;
      ${mq[0]} {
        font-size: 30px;
        margin-bottom: 15px;
      }
    }
    p {
      font-size: 20px;
      line-height: 1.75;
      margin-bottom: 35px;
      font-family: 'NanumSquareL';
      strong {
        font-family: 'NanumSquareB';
      }
      ${mq[0]} {
        font-size: 13px;
        padding: 0 14px;
        margin: 0 auto 72px;
        word-break: keep-all;
        .pc {
          display: none;
        }
      }
    }
    figure {
      position: relative;
      display: inline-block;
      ${mq[0]} {
        text-align: center;
      }
      .lock_arrow {
        width: 627px;
        ${mq[0]} {
          width: 280px;
        }
      }
      .lock_phone {
        position: absolute;
        width: 376px;
        left: 50%;
        margin-left: -188px;
        ${mq[0]} {
          width: 242px;
          top: -42px;
          margin-left: -121px;
        }
      }
    }
  }
`;
export default GasLockStyled;
