import styled from '@emotion/styled';
import { toRem } from '../../utils/utils';

const SurveyStyled = styled.section`
  position: relative;
  font-family: 'NanumSquareR';
  font-size: 15px;

  > div {
    margin: 0 auto;
    padding: 0 ${toRem(90)};
  }
  .title {
    font-family: 'NanumSquareEB';
    font-size: ${toRem(90)};
    margin: ${toRem(140)} 0 ${toRem(60)};
    line-height: 1.36;
    letter-spacing: -0.06em;

    @media (max-width: 360px) {
      font-size: ${toRem(80)};
    }
  }
  .desc {
    font-size: ${toRem(52)};
    font-family: 'NanumSquareR';
    line-height: 1.5;
    letter-spacing: -${toRem(3)}px;
    @media (max-width: 360px) {
      font-size: ${toRem(50)};
    }
  }
  .desc2 {
    font-size: ${toRem(48)};
    font-family: 'NanumSquareR';
    line-height: 1.5;
    color: #979797;
    margin: ${toRem(40)} 0 ${toRem(120)};
    @media (max-width: 360px) {
      font-size: ${toRem(50)};
    }
  }
  .image-wrap {
    width: 100%;
    display: inline-block;
    margin: ${toRem(120)} 0 ${toRem(60)};

    > img {
      width: 100%;
    }
  }
  .bg_blue {
    background-color: #29b6f6;
  }
  .round_btn {
    width: 100%;
    border-radius: 2rem;
    padding: ${toRem(58)};
    overflow: hidden;
    text-align: center;
    color: #fff !important;
    font-family: 'nanumsquareB';
    font-size: ${toRem(56)};
    display: block;
  }
`;

export default SurveyStyled;
