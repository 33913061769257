import React, { useEffect } from 'react';
import EnergyBodyStyled from './EnergyBody.Styled';
import DeviceUtil from '../../utils/DeviceUtil';

export default function EnergyBody01(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(props.companyCode);
  return (
    <EnergyBodyStyled>
      <div className="contents">
        <div className="content_group">
          <h3>도시가스 요금 경감 제도란?</h3>
          <p className="text">
            사회적으로 보호를 필요로 하는 도시가스 사용고객의 취사 및 난방요금을
            할인해 주는 제도
          </p>
        </div>
        <div className="content_group">
          <h3>신청 대상</h3>
          <table className="info_table">
            <colgroup>
              <col width="35%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>대상</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>사회적 배려자</td>
                <td>
                  장애인(장애정도가 심한 장애인)
                  <br />
                  기초생활수급자
                  <br />
                  국가유공자(1~3급)
                  <br />
                  5.18유공자(1~3급)
                  <br />
                  독립유공자 및 유족
                </td>
              </tr>
              <tr>
                <td>차상위계층</td>
                <td>
                  자활사업 참여자 <br />
                  본인부담 경감자 <br />
                  차상위 장애인 <br />
                  한부모 가족 지원 <br />
                  차상위계층 확인서 발급대상
                </td>
              </tr>
              <tr>
                <td>다자녀가구</td>
                <td>
                  일반 다자녀 가구
                  <br />
                  위탁가정 다자녀 가구
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content_group">
          <h3>요금 경감 금액</h3>
          {props.companyCode !== '6' && (
            <p className="s_text mb10">
              2022.12.01.~2023.03.31. 도시가스 사용분에 한해 적용
            </p>
          )}
          <table className="info_table">
            <colgroup>
              <col />
              {props.companyCode === '6' ? (
                <>
                  <col width="23%" />
                  <col width="23%" />
                </>
              ) : (
                <>
                  <col width="35%" />
                  <col width="20%" />
                </>
              )}

              <col width="17%" />
            </colgroup>
            <thead className="sm">
              <tr>
                <th rowSpan={2}>구분</th>
                <th colSpan={2} className="border_left border_bottom">
                  가정용 난방.취사 겸용, <br />
                  난방전용
                </th>
                <th className="border_left" rowSpan={2}>
                  가정용
                  <br />
                  취사
                </th>
              </tr>
              <tr>
                <th className="border_left">
                  동절기
                  <br />
                  (12~3월)
                </th>
                <th>
                  기타월
                  <br />
                  (4~11월)
                </th>
              </tr>
            </thead>
            {props.companyCode === '6' ? (
              <tbody className="sm">
                <tr>
                  <td className="center">장애인(장애정도가 심한 장애인) </td>
                  <td className="center">36,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>
                <tr>
                  <td className="center">
                    국가유공자/
                    <br />
                    5.18유공자
                  </td>
                  <td className="center">36,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>
                <tr>
                  <td className="center">독립유공자</td>
                  <td className="center">36,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>
                <tr>
                  <td className="center">생계/의료급여</td>
                  <td className="center">36,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>

                <tr>
                  <td className="center">주거급여</td>
                  <td className="center">18,000</td>
                  <td className="center">4,950</td>
                  <td className="center">840</td>
                </tr>
                <tr>
                  <td className="center">교육급여</td>
                  <td className="center">9,000</td>
                  <td className="center">2,470</td>
                  <td className="center">420</td>
                </tr>
                <tr>
                  <td className="center">차상위계층</td>
                  <td className="center">18,000</td>
                  <td className="center">4,950</td>
                  <td className="center">840</td>
                </tr>
                <tr>
                  <td className="center">차상위계층 확인서 발급대상</td>
                  <td className="center">9,000</td>
                  <td className="center">2,470</td>
                  <td className="center">420</td>
                </tr>
                <tr>
                  <td className="center">다자녀가구</td>
                  <td className="center">9,000</td>
                  <td className="center">2,470</td>
                  <td className="center">420</td>
                </tr>
              </tbody>
            ) : (
              <tbody className="sm">
                <tr>
                  <td className="center">장애인(장애정도가 심한 장애인) </td>
                  <td className="center">72,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>
                <tr>
                  <td className="center">
                    국가유공자/
                    <br />
                    5.18유공자
                  </td>
                  <td className="center">72,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>
                <tr>
                  <td className="center">독립유공자</td>
                  <td className="center">72,000</td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>
                <tr>
                  <td className="center">생계/의료급여</td>
                  <td className="left">
                    <p className="m0">미수급자 : 148,000</p>
                    <p className="m0">수급자 : 86,000</p>
                  </td>
                  <td className="center">9,900</td>
                  <td className="center">1,680</td>
                </tr>

                <tr>
                  <td className="center">주거급여</td>
                  <td className="left">
                    <p className="m0">미수급자 : 148,000</p>
                    <p className="m0">수급자 : 86,000</p>
                  </td>
                  <td className="center">4,950</td>
                  <td className="center">840</td>
                </tr>
                <tr>
                  <td className="center">교육급여</td>
                  <td className="left">
                    <p className="m0">미수급자 : 148,000</p>
                    <p className="m0">수급자 : 86,000</p>
                  </td>
                  <td className="center">2,470</td>
                  <td className="center">420</td>
                </tr>
                <tr>
                  <td className="center">차상위계층</td>
                  <td className="left">
                    <p className="m0">미수급자 : 148,000</p>
                    <p className="m0">수급자 : 86,000</p>
                  </td>
                  <td className="center">4,950</td>
                  <td className="center">840</td>
                </tr>
                <tr>
                  <td className="center">차상위계층 확인서 발급대상</td>
                  <td className="center">18,000</td>
                  <td className="center">2,470</td>
                  <td className="center">420</td>
                </tr>
                <tr>
                  <td className="center">다자녀가구</td>
                  <td className="center">18,000</td>
                  <td className="center">2,470</td>
                  <td className="center">420</td>
                </tr>
              </tbody>
            )}
          </table>
          {props.companyCode === '6' ? (
            <>
              {/* <p className="info_text mb10">
                ※ 「사회적배려대상자에 대한 도시가스요금 경감 지침(2023.01 개정)」
                에 따른 경감 금액
              </p> */}
              <p className="info_text">
                ※ 도시가스요금 경감대상자의 월 도시가스 사용요금이 경감금액 보다
                작은경우 기본요금을 제외한 사용요금 만큼만 경감
              </p>
            </>
          ) : (
            <>
              <p className="info_text mb10">
                ※ 미수급자 : 에너지 바우처 지원을 받고있지 않은 고객
              </p>
              <p className="info_text">
                ※ 수급자 : 에너지 바우처 지원을 받고있는 고객
              </p>
            </>
          )}
        </div>
        <div className="content_group">
          <h3>신청 방법</h3>
          <p className="text bold">방문 신청</p>
          <p className="text text_pad">
            읍·면·동 행정복지센터 (단, 다자녀, 국가유공자, 독립유공자는 관할
            도시가스 홈페이지 또는 고객센터 신청)
          </p>
          <p className="text bold">모바일 신청</p>
          <p className="text">
            서울도시가스, JB(주), 대륜E&S 도시가스사 가스앱 신청 가능
          </p>
        </div>
        <div className="content_group">
          {(props.companyCode === '1' ||
            props.companyCode === '4' ||
            props.companyCode === '5') &&
            props.userType === 'Y' && (
              <button
                className="primary_btn"
                onClick={() => {
                  window.open(
                    'https://mobileservicecenter.page.link/weakDiscount'
                  );
                  try {
                    if (DeviceUtil.isAndroid) {
                      window.android.closePopupWindow();
                    } else if (DeviceUtil.isIOS) {
                      window.webkit.messageHandlers.observe.postMessage(
                        JSON.stringify({
                          action: 'closePopupWindow'
                        })
                      );
                    }
                  } catch (e) {}
                }}
              >
                요금 경감 신청서 작성
              </button>
            )}

          <p className="info_text mt30">
            ※ 자세한 사항은 관할 지자체로 문의하시거나{' '}
            <span
              className="underline"
              onClick={() => {
                window.open('http://www.citygas.or.kr/social/index.jsp');
              }}
            >
              한국도시가스협회 홈페이지
            </span>
            를 참고해 주세요.
          </p>
        </div>
      </div>
    </EnergyBodyStyled>
  );
}
