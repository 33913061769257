export const menus = {
  kor: [
    { name: 'GASAPP', label: '가스앱' },
    { name: 'NEWGASAPP', label: '서비스 지역 확대' },
    { name: 'INTEGRATION', label: '실시간 에너지 관리' },
    { name: 'PRIVATEHOME', label: '쉽고 편한 개인화 홈' },
    { name: 'PAY', label: '3초 간편 결제' },
    { name: 'CHATBOT', label: '매일 똑똑해지는 A.I' },
    { name: 'AMI', label: '처음 만나는 AMI' },
    { name: 'LIFE', label: 'LIFE & O2O' },
    { name: 'GASLOCK', label: `혜택과 할인 ‘가스락'` },
    { name: 'EFFECT', label: '도시가스를 위한 가스앱' }
  ],
  en: [
    { name: 'GASAPP', label: 'Gas App' },
    { name: 'NEWGASAPP', label: 'New partners coming soon!' },
    { name: 'INTEGRATION', label: 'Real-time energy management' },
    { name: 'PRIVATEHOME', label: 'Customized personal home screen' },
    { name: 'PAY', label: '3 seconds simple payment' },
    { name: 'CHATBOT', label: 'A.I. getting smart everyday' },
    { name: 'AMI', label: 'First time meeting AMI' },
    { name: 'LIFE', label: 'Life & O2O' },
    { name: 'GASLOCK', label: 'Gas Lock' },
    { name: 'EFFECT', label: 'Gas App for gas providers' }
  ],
  fr: [
    { name: 'GASAPP', label: 'Gas App' },
    { name: 'NEWGASAPP', label: 'Nouveaux partenaires en vue!' },
    { name: 'INTEGRATION', label: `Gestion de l'énergie en temps réel` },
    { name: 'PRIVATEHOME', label: `UX personnalisée` },
    { name: 'PAY', label: `Paiement simple en 3 secondes` },
    { name: 'CHATBOT', label: 'Une I.A. de plus en plus intelligente' },
    { name: 'AMI', label: 'Premiere rencontre avec AMI' },
    { name: 'LIFE', label: 'LIFE & O2O' },
    { name: 'GASLOCK', label: `Avantages et réductions 'Gas lock'` },
    {
      name: 'EFFECT',
      label: `Service du gaz de ville`
    }
  ]
};
