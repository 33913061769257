import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { WunderPaper, toRemLucky } from '../utils/utils';
import Img01 from '../resources/images/settlementReceipt/settlementReceiptImg1.png';
import Img02 from '../resources/images/settlementReceipt/settlementReceiptImg2.png';
import Img03 from '../resources/images/settlementReceipt/settlementReceiptImg3.png';
import Logo from '../resources/images/settlementReceipt/settlementReceiptLogo.svg';
import moment from 'moment';
import {
  Data,
  Image,
  NotiList,
  Text,
  Divider,
  Button,
  Stack
} from '@gasapp/ui';
import LoadingBar from '../components/common/LoadingBar';
import { useLocation } from 'react-router-dom';

function SettlementReceipt() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seq, setSeq] = useState(null);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  useEffect(() => {
    const seq = queryParams.get('seq');
    setSeq(seq);
  }, [queryParams]);

  useEffect(() => {
    if (!seq) return;
    fetchData();

    return () => {
      setSeq('');
    };
  }, [seq]);

  const fetchData = () => {
    const instance = axios.create({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? 'https://app.gasapp.co.kr/api/relay'
          : 'https://app-dev1.gasapp.co.kr/api/relay'
    });
    instance
      .get(`/bills/payment/history/seq?seq=${seq}`)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const HeaderTitle = styled.div`
    background: #ffffff;
    padding: ${toRemLucky(50)} ${toRemLucky(90)};
  `;

  const SettlementReceipt = styled.div`
    .receiptWrap {
      background: #f5f5f5;
    }
    .gasappInfo {
      padding-top: ${toRemLucky(122)}!important;
      .paper {
        padding: ${toRemLucky(100)};
      }
    }
  `;

  const numberWithCommas = x => {
    if (!x) return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const dataProps = {
    titleProps: { variant: '44', color: 'gray-888888' },
    valueProps: { variant: '48' },
    titleWidth: 280
  };

  return (
    <SettlementReceipt>
      <HeaderTitle>
        <Text variant="56">도시가스 납부 내역서</Text>
      </HeaderTitle>
      {data && (
        <>
          <Stack defaultPadding className="receiptWrap" direction="column">
            <WunderPaper backgroundColor="white">
              <Stack direction="column" gap={100}>
                <Stack alignItems="center" direction="column" gap={48}>
                  <Image image={Logo} width={551} />
                  <Text variant="76B">납부 내역서</Text>
                </Stack>
                <Stack direction="column" gap={80}>
                  <Stack direction="column" gap={20}>
                    <Data
                      {...dataProps}
                      title="주소"
                      valueProps={{
                        alignItems: 'end',
                        textAlign: 'end'
                      }}
                      value={`${data.address.address1}, ${data.address.address2}`}
                    />
                    <Data
                      {...dataProps}
                      title="정산 일자"
                      value={data.paymentDate}
                    />
                    <Data
                      {...dataProps}
                      title="정산 지침"
                      value={`${numberWithCommas(
                        data.thisMonthIndicatorQty
                      )} ㎥`}
                    />
                    <Data
                      {...dataProps}
                      title="사용 기간"
                      value={
                        <Text variant="48">{`${
                          data.startDate
                            ? moment(data.startDate).format('YYYY. MM. DD') +
                              ' ~ '
                            : ''
                        }${moment(data.endDate).format('YYYY. MM. DD')}`}</Text>
                      }
                    />
                  </Stack>
                  <Divider />
                  {/* 카드 은행 여부 텍스트로 보여줘야 함 문구 정해져야댐! */}
                  {data.paymentAmount !== 0 && (
                    <>
                      <Stack direction="column" gap={20}>
                        <Text variant="48B">
                          {data.paymentType === 'CARD'
                            ? '카드 결제'
                            : '은행 결제'}
                        </Text>
                        <Data
                          {...dataProps}
                          title="은행/카드사명"
                          value={data.bankName}
                        />
                        <Data
                          {...dataProps}
                          title="승인번호"
                          value={data.transferApprovalNum}
                        />
                      </Stack>
                      <Divider />
                    </>
                  )}
                  <Stack direction="column" gap={20}>
                    <Data
                      {...dataProps}
                      title="결제 금액"
                      value={
                        <Text variant="48B">
                          {numberWithCommas(data.paymentAmount)} 원
                        </Text>
                      }
                    />
                    {data.overPaymentAmount !== 0 && (
                      <Data
                        {...dataProps}
                        title="미리 납부 금액"
                        value={
                          <>{numberWithCommas(data.overPaymentAmount)} 원</>
                        }
                      />
                    )}
                    {data.cashAmount !== 0 && (
                      <Data
                        {...dataProps}
                        title="캐시 할인 금액"
                        value={<>{numberWithCommas(data.cashAmount)} 원</>}
                      />
                    )}
                    <Data
                      {...dataProps}
                      title="결제 일자"
                      value={data.paymentDate}
                    />
                  </Stack>
                  <Divider />
                  <NotiList
                    titleIcon="ExclamationIcon"
                    titleProps={{ variant: '48B', color: 'gray-666666' }}
                    title="확인해 주세요."
                    gap={40}
                    contentProps="42"
                    contents={[
                      '가스앱으로 정산한 도시가스 납부 내역서입니다.',
                      data.paymentAmount === 0
                        ? '결제 금액 0원으로 이사 정산이 완료되었습니다. 이후 변동사항은 가스앱에 반영되지 않으니 참고해 주세요.'
                        : '도시가스 전산 반영까지 최대 2일 소요됩니다. 이후 변동 사항은 가스앱에 반영되지 않으니 참고해 주세요.'
                    ]}
                    contentsGap={20}
                  />
                </Stack>
              </Stack>
            </WunderPaper>
          </Stack>
          <Stack
            className="gasappInfo"
            gap={60}
            direction="column"
            style={{
              background: 'rgba(41, 182, 246, 0.15)',
              padding: toRemLucky(50)
            }}
          >
            <Stack gap={104} direction="column">
              <Stack alignItems="center" direction="column">
                <Text variant="76B">우리집 도시가스 관리</Text>
                <Text variant="100B" color="blue">
                  가스앱으로 간편하게!
                </Text>
              </Stack>
              <Stack gap={50} direction="column">
                <WunderPaper backgroundColor="white" className="paper">
                  <Stack gap={46} direction="column">
                    <Image image={Img01} />
                    <Stack alignItems="center" direction="column">
                      <Text variant="64B">
                        이번 달 가스요금{' '}
                        <Text variant="64B" color="blue">
                          조회부터 납부까지
                        </Text>
                      </Text>
                      <Text variant="64B">한번에 처리할 수 있어요!</Text>
                    </Stack>
                  </Stack>
                </WunderPaper>
                <WunderPaper backgroundColor="white" className="paper">
                  <Stack gap={46} direction="column">
                    <Image image={Img02} />
                    <Stack alignItems="center" direction="column">
                      <Text variant="64B">자가검침 기간을 놓치지 않도록</Text>
                      <Text variant="64B">
                        <Text variant="64B" color="blue">
                          맞춤 알림
                        </Text>
                        을 발송해드려요!
                      </Text>
                    </Stack>
                  </Stack>
                </WunderPaper>
                <WunderPaper backgroundColor="white" className="paper">
                  <Stack gap={46} direction="column">
                    <Image image={Img03} />
                    <Stack alignItems="center" direction="column">
                      <Text variant="64B">차곡차곡 1만 캐시를 적립해</Text>
                      <Text variant="64B">
                        <Text variant="64B" color="blue">
                          현금으로{' '}
                        </Text>
                        받아 가세요!
                      </Text>
                    </Stack>
                  </Stack>
                </WunderPaper>
              </Stack>
            </Stack>
            <Button
              onClick={() => {
                window.open('https://mobileservicecenter.page.link/install');
              }}
              title="가스앱 설치하기"
            />
          </Stack>
        </>
      )}
      <LoadingBar open={loading} />
    </SettlementReceipt>
  );
}

export default SettlementReceipt;
