import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

const HiddenEvent = styled.div`
  input {
    display: none;
  }
`;

function BenenuryEvent() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  useEffect(() => {
    // handphone: 'RGwBLoRVvzMwQVzTjl4rnw==';
    // id: 'ZtP6aMuBTldrINLrozXhRA==';
    // name: 'tsNPmAEl1DFQHeWt_9isiw==';
    // url: 'https://gasapp.benenuri.com/api/oauth';
    let a = document.getElementById('event');
    let form = document.createElement('form'); //form엘리먼트 생성

    form.setAttribute('method', 'post'); //POST 메서드 적용
    form.setAttribute('action', queryParams.get('url')); //데이터를 전송할 url

    var hiddenField1 = document.createElement('input');
    var hiddenField2 = document.createElement('input');
    var hiddenField3 = document.createElement('input');
    var hiddenField4 = document.createElement('input');
    hiddenField1.setAttribute('name', 'id');
    hiddenField1.setAttribute('value', queryParams.get('id'));
    hiddenField2.setAttribute('name', 'name');
    hiddenField2.setAttribute('value', queryParams.get('name'));
    hiddenField3.setAttribute('name', 'phone');
    hiddenField3.setAttribute('value', queryParams.get('handphone'));
    hiddenField4.setAttribute('name', 'target');
    hiddenField4.setAttribute('value', 'gas');
    form.appendChild(hiddenField1);
    form.appendChild(hiddenField2);
    form.appendChild(hiddenField3);
    form.appendChild(hiddenField4);
    a.appendChild(form);
    form.submit(); // 전송
  }, [queryParams]);

  return <HiddenEvent id="event"></HiddenEvent>;
}

export default BenenuryEvent;
