import { css } from '@emotion/core';
import ResetStyled from './Reset.Styled';

const ResetEnergyStyled = css`
  ${ResetStyled};

  * {
    -webkit-tap-highlight-color: transparent;
    /* 터치 하이라이트 제거*/
    -webkit-overflow-scrolling: touch;
    /* ios 스크롤 스무스*/
    -ms-overflow-style: none;
    /* IE에서 스크롤바 감춤*/
  }

  button:focus {
    outline: 0;
  }
`;
export default ResetEnergyStyled;
