import { css } from '@emotion/core';
import ResetStyled from './Reset.Styled';

const ResetChargeUsageStyled = css`
  ${ResetStyled};

  body {
    margin: 0; // 1
    font-family: 'NanumSquareR'; // 4
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    text-align: left; // 3
    background-color: #f7f7f7;
  }
`;
export default ResetChargeUsageStyled;
