import styled from '@emotion/styled';
import { toRem } from '../../utils/utils';

const GateStyled = styled.section`
  position: relative;
  font-family: 'NanumSquareR';
  font-size: 15px;

  > div {
    margin: 0 auto;
    padding: 0 ${toRem(80)};
  }
  .sub-label {
    font-family: 'NanumSquareB';
    font-size: ${toRem(64)};
    margin: ${toRem(190)} 0 ${toRem(20)};
    line-height: 1.36;
    letter-spacing: -0.06em;

    @media (max-width: 360px) {
      font-size: ${toRem(60)};
    }
  }
  .title {
    font-family: 'NanumSquareB';
    font-size: ${toRem(76)};
    margin: ${toRem(150)} 0 ${toRem(40)};
    line-height: 1.36;
    letter-spacing: -0.06em;
    &.market {
      margin-top: 0;
    }

    @media (max-width: 360px) {
      font-size: ${toRem(76)};
    }
  }
  .sub-title {
    font-size: ${toRem(52)};
    font-family: 'NanumSquareR';
    line-height: 1.5;
    @media (max-width: 360px) {
      font-size: ${toRem(52)};
    }
  }
  .bold {
    font-family: 'NanumSquareB';
  }
  .image-wrap {
    width: 100%;
    display: inline-block;
    margin: ${toRem(100)} 0;

    > img {
      width: 100%;
    }
  }
  .desc2 {
    font-size: ${toRem(50)};
    font-family: 'NanumSquareEB';
    margin: ${toRem(80)} 0 ${toRem(225)};
    @media (max-width: 360px) {
      font-size: ${toRem(40)};
    }
  }
  .bg_blue {
    background-color: #29b6f6;
  }
  .round_btn {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
    margin-bottom: ${toRem(120)};
    overflow: hidden;
    text-align: center;
    color: #fff !important;
    font-family: 'nanumsquareB';
    display: block;
  }
  .cash-title {
    font-family: 'NanumSquareB';
    font-size: ${toRem(40)};
    margin-bottom: ${toRem(25)};
  }
  .cash-desc {
    line-height: 1.44;
    font-size: ${toRem(40)};
    margin-bottom: ${toRem(130)};
  }
`;

export default GateStyled;
