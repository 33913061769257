import React, { useEffect } from 'react';
import EnergyBodyStyled from './EnergyBody.Styled';

export default function EnergyBody04(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <EnergyBodyStyled>
      <div className="contents">
        <div className="content_group">
          <h3>친환경 보일러 교체 지원이란?</h3>
          <p className="text">
            가정용 보일러를 환경표지인증 제품으로 설치(교체)할 경우 보조금을
            지원하는 제도
          </p>
          <p className="info_text">※ 설치 당시 환경표지인증이 유효한 제품</p>
        </div>
        <div className="content_group">
          <h3>지원 대상 및 금액</h3>
          <table className="info_table">
            <colgroup>
              <col width="80%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>대상</th>
                <th>보조금</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">일반</td>
                <td className="center">10만원</td>
              </tr>
              <tr>
                <td className="center">
                  저소득층(기초생활수급자, 차상위계층){' '}
                </td>
                <td className="center">60만원</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content_group">
          <h3>신청 방법</h3>
          <p className="text bold">방문 신청</p>
          <p className="text text_pad">관할 지자체 접수</p>
          <p className="text bold">온라인 신청</p>
          <p className="text mb30">친환경 보일러 인증시스템 홈페이지 접수</p>

          <table className="info_table sm left_align">
            <colgroup>
              <col width="50%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th className="left">사전 신청</th>
                <th className="left">사후 신청</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>① 친환경 보일러 계약 (신청자→대리점 등 공급자) </p>
                  <p>② 온라인 또는 방문 신청 (대리점 등 공급자→지자체) </p>
                  <p>③ 서류 검토, 보조금 지급 결정 (지자체→대리점 등 공급자)</p>
                  <p>④ 보일러 설치 (대리점 등 공급자) </p>
                  <p>⑤ 적정설치여부 확인 (대리점 등 공급자→지자체) </p>
                  <p>⑥ 보조금 지급 (지자체→대리점 등 공급자)</p>
                </td>
                <td>
                  <p>① 보일러 설치 (대리점 등 공급자) </p>
                  <p>② 온라인 신청 또는 방문신청 (공급자 또는 구매자→지자체)</p>
                  <p>
                    ③ 적정설치여부 확인 및 서류검토 (공급자 또는 구매자→지자체)
                  </p>
                  <p>④ 보조금 지급 (지자체→공급자 또는 구매자)</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content_group">
          <button
            className="primary_btn"
            onClick={() => {
              window.open('https://www.greenproduct.go.kr/boiler/main.do');
            }}
          >
            친환경 보일러 보조금 신청
          </button>
          <p className="info_text mt30">
            ※ 자세한 사항은 관할 지자체로 문의하시거나{' '}
            <span
              className="underline"
              onClick={() => {
                window.open('https://www.greenproduct.go.kr/boiler/main.do');
              }}
            >
              친환경 보일러 인증시스템 홈페이지
            </span>
            를 참고해 주세요.
          </p>
        </div>
      </div>
    </EnergyBodyStyled>
  );
}
