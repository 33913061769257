import React, { useState, useEffect, useRef, useMemo } from 'react';

export default function Timer({
  className,
  time = 1 * 60 * 1000, // 1분
  afterTimeout
}) {
  const timerCallback = useRef();
  const [localMinute, setLocalMinute] = useState(-1);
  const [localSecond, setLocalSecond] = useState(-1);

  useEffect(() => {
    initTimerState();
  }, [time]);

  const initTimerState = () => {
    // 밀리세컨드를 초로 변환
    const totalSeconds = time / 1000;

    // 분과 초 계산
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    setLocalMinute(minutes);
    setLocalSecond(seconds);
  };

  const setTimerState = () => {
    setLocalSecond(second => {
      if (second === 0) {
        setLocalMinute(minute => {
          return minute - 1;
        });
        second = 59;
      } else {
        second -= 1;
      }
      return second;
    });
  };

  useEffect(() => {
    timerCallback.current = setTimerState;
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      timerCallback.current();
    }, 1000);

    if (localMinute === 0 && localSecond === 0) {
      afterTimeout();
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [localMinute, localSecond]);

  const printTime = useMemo(() => {
    return (
      <>
        {localMinute < 10 ? '0' + localMinute : localMinute}:
        {localSecond < 10 ? '0' + localSecond : localSecond}
      </>
    );
  }, [localMinute, localSecond]);

  return <div className={className}>{printTime}</div>;
}
