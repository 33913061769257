import React from 'react';
import ContactStyled from './Contact.Styled';

import { email, inquiry } from '../../../resources/images';

export default function Contact(props) {
  return (
    <ContactStyled>
      <div>
        <i>
          <img src={inquiry} alt={``} />
        </i>
        <strong>
          많은 도시가스사와 에너지 기업이 <br className="w" />
          우리와 함께 하는 이유,
        </strong>
        <p>더 알고 싶으시다면, 지금 문의하세요.</p>
        <div className={`btn-group`}>
          <a href={`mailto:hello@scglab.com`}>
            <i>
              <img src={email} alt={``} />
            </i>
            hello@scglab.com
          </a>
          {/*<a href={`tel:02-326-4006`}>
            <i>
              <img src={call} alt={``} />
            </i>
            02-326-4006
          </a>*/}
        </div>
      </div>
    </ContactStyled>
  );
}
