import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChargeUsageStyled from '../components/charge/ChargeUsage.Styled';

import UsageDistribution from '../components/charge/UsageDistribution';
import ProperUsage from '../components/charge/ProperUsage';
import UsageRecent from '../components/charge/UsageRecent';
import Condition from '../components/charge/Condition';
import { logoText, dimmImg1, dimmImg2, dimmImg3 } from '../resources/images';
import moment from 'moment';

export const Colors = {
  ORANGE: '#FF775B',
  YELLOW: '#FFB31F',
  GREEN: '#4ABD68'
};

const jsonUrl =
  'https://cdn-app.gasapp.co.kr/attach/temp/chargeUsageData.json?123';

function ChargeUsage() {
  const [condition, setCondition] = useState({
    conditionCity: { name: '지역', value: '' }, //{name: '서울특별시', value: '경기도_고양시' }, //
    conditionArea: { name: '평형', value: '' }, //{ name: '10평 이하', value: 5 }, //
    conditionMemberCount: { name: '가족 수', value: '' }, //{ name: '1명', value: 1 }, //
    conditionContracts: { name: '선택하세요.', value: '선택하세요.' }
  });
  // 사용량 분포도 데이터
  const [sampleJson, setSampleJson] = useState();

  // 필터링된 전체 데이터
  const [conditionSearchedDataList, setConditionSearchedDataList] = useState();
  // 차트에 필요한 데이터, 사용계약번호를 선택하면 그 데이터도 chartData에 추가
  const [chartData, setChartData] = useState();
  // 사용계약번호 리스트
  const [contractsData, setContractsData] = useState();
  // 선택된 년월 index
  const [activeRequestYm, setActiveRequestYm] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [properColors, setProperColors] = useState();

  // json 파일가져오기
  useEffect(() => {
    fetch(jsonUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // JSON 데이터를 파싱하여 Promise 반환
      })
      .then(data => {
        // JSON 데이터를 사용하는 코드 작성
        console.log(data); // 데이터를 콘솔에 출력하거나 원하는 대로 처리
        setSampleJson(data);
      })
      .catch(error => {
        // 오류 처리
        console.error('There was a problem with the fetch operation:', error);
      });

    const metaTag = document.createElement('meta');

    // Set the meta tag's attributes
    metaTag.name = 'viewport';
    metaTag.content = 'width=640;  user-scalable=no';

    // Append the meta tag to the document's head
    document.head.appendChild(metaTag);
  }, []);

  // 필터된 데이터 가공
  const handleChangeCondition = (id, data) => {
    // 사용계약번호를 선택햇을때 chartData에 데이터 추가해주는 코드
    if (id === 'conditionContracts') {
      let findContractNumsData = conditionSearchedDataList.users.find(datas => {
        return data.value === datas.useContractNum;
      });
      let findIdx = findContractNumsData.data.findIndex(data => {
        return data.requestYm === moment().format('YYYYMM');
      });
      // 월별 실제 사용량
      let usageList = findContractNumsData.data.slice(
        findIdx - 14,
        findIdx + 1
      );

      setChartData(prevState => {
        return prevState.map((data, index) => {
          return {
            ...data,
            ...usageList[index],
            properUsage: [
              {
                label: '적정 사용량',
                usage: data.appropriateUsage
              },
              { label: '우리집', usage: usageList[index].usage, myHome: true }
            ],
            usageDistribution: [
              {
                label: '평균',
                usage: data.averageUsage
              },
              {
                label: '우리집',
                usage: usageList[index].usage,
                myHome: true,
                percent: Math.round(usageList[index].percent)
              }
            ]
          };
        });
      });
    }
    // 전체 데이터에서 필터된 데이터 가져오기
    setCondition(prevState => {
      if (id !== 'conditionContracts') {
        return {
          ...prevState,
          [id]: data,
          conditionContracts: { name: '선택하세요.', value: '선택하세요.' }
        };
      } else {
        return { ...prevState, [id]: data };
      }
    });
  };

  const changeActiveRequestYm = (activeRequestYm, activeIndex) => {
    setActiveRequestYm(activeRequestYm);
    setActiveIndex(activeIndex);
  };

  // 조건 effect
  useEffect(() => {
    for (const obj of Object.values(condition)) {
      if (obj.value === '') return;
    }
    if (!sampleJson) return;
    let contracts = sampleJson.find(data => {
      if (
        data['conditionCity'] === condition['conditionCity'].value &&
        data['conditionArea'] === condition['conditionArea'].value &&
        data['conditionMemberCount'] === condition['conditionMemberCount'].value
      ) {
        return data;
      }
    });
    // 조건을 모두 선택했을때에 맞는 데이터 저장
    setConditionSearchedDataList(contracts.groupInfo);

    return () => {};
  }, [condition, sampleJson]);

  useEffect(() => {
    if (conditionSearchedDataList) {
      let findIdx = conditionSearchedDataList.bandwidths.findIndex(data => {
        return data.requestYm === moment().format('YYYYMM');
      });
      setContractsData(
        conditionSearchedDataList.users.map(data => {
          return {
            name: data.name + ' | ' + data.address,
            value: data.useContractNum
          };
        })
      );

      setChartData(
        conditionSearchedDataList.bandwidths
          .slice(findIdx - 14, findIdx + 1)
          .map(data => {
            return {
              ...data,
              minMaxUsage: [Math.max(0, data.minUsage - 5), data.maxUsage + 5]
            };
          })
      );
    }
  }, [conditionSearchedDataList]);

  useEffect(() => {
    if (chartData && chartData[activeIndex].properUsage) {
      let data = chartData[activeIndex].properUsage;
      if (data[0].usage > data[1].usage) {
        setProperColors(Colors.GREEN);
      } else if (data[0].usage < data[1].usage) {
        setProperColors(Colors.ORANGE);
      } else {
        setProperColors(Colors.YELLOW);
      }
    }

    return () => {};
  }, [activeIndex, chartData]);

  return (
    <React.Fragment>
      <ChargeUsageStyled>
        <div>
          {/* <Link to="/chargeUsage"> */}
          <h3
            className="title"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={logoText} alt="도시가스 사용량 진단" />
          </h3>
          {/* </Link> */}
          <div className="conditions-wrap">
            <Condition
              handleChangeCondition={handleChangeCondition}
              condition={condition}
              contractsData={contractsData}
            />
          </div>
          {/* 최근 사용량 */}
          <div
            className={
              chartData ? 'charts-box-wrap active' : 'charts-box-wrap '
            }
          >
            {chartData ? (
              <UsageRecent
                usageData={chartData}
                activeRequestYm={activeRequestYm}
                changeActiveRequestYm={(activeRequestYm, activeIndex) => {
                  changeActiveRequestYm(activeRequestYm, activeIndex);
                }}
                condition={condition}
              />
            ) : (
              <>
                <div className="charts-year">
                  <p className="sub_title" style={{ color: '#88888833' }}>
                    최근 사용량
                  </p>
                </div>
                <p
                  className="dimmed"
                  style={{
                    background: `url(${dimmImg1}) no-repeat center center`
                  }}
                >
                  조건 및 고객을 먼저 선택해주세요
                </p>
              </>
            )}
          </div>
          {/* 월 적정 사용량 */}
          {chartData &&
          chartData[activeIndex].properUsage &&
          chartData[activeIndex].usageDistribution ? (
            <div className="month-chart-wrap active">
              <div className="bg"></div>
              <ProperUsage
                usageData={chartData[activeIndex].properUsage}
                condition={condition}
                activeRequestYm={activeRequestYm}
              />
              <UsageDistribution
                usageData={chartData[activeIndex].usageDistribution}
                colors={properColors}
              />
            </div>
          ) : (
            <div className="month-chart-wrap">
              <div className="bg"></div>
              <div
                className="proper-usage"
                style={{
                  background: `url(${dimmImg2}) no-repeat center center`
                }}
              >
                <p className="sub_title" style={{ color: '#88888833' }}>
                  적정 사용량
                </p>
              </div>
              <div
                className="usage-distribution"
                style={{
                  borderLeft: 'none',
                  background: `url(${dimmImg3}) no-repeat center center`
                }}
              >
                <p className="sub_title" style={{ color: '#88888833' }}>
                  사용량 분포
                </p>
              </div>
              <p className="dimmed">고객을 선택해 주세요</p>
            </div>
          )}
        </div>
      </ChargeUsageStyled>
    </React.Fragment>
  );
}

export default ChargeUsage;
