import React from 'react';
import ConditionSelect from './ConditionSelect';

const cityDatas = [
  { name: '서울특별시', value: '서울특별시' },
  { name: '인천광역시', value: '인천광역시' },
  { name: '경기도_고양시', value: '경기도_고양시' },
  { name: '경기도_구리시', value: '경기도_구리시' },
  { name: '경기도_김포시', value: '경기도_김포시' },
  { name: '경기도_남양주시', value: '경기도_남양주시' },
  { name: '경기도_동두천시', value: '경기도_동두천시' },
  { name: '경기도_양주시', value: '경기도_양주시' },
  { name: '경기도_의정부시', value: '경기도_의정부시' },
  { name: '경기도_파주시', value: '경기도_파주시' },
  { name: '경기도_포천시', value: '경기도_포천시' },
  { name: '충청도_공주시', value: '충청도_공주시' },
  { name: '충청도_논산시', value: '충청도_논산시' },
  { name: '충청도_보령시', value: '충청도_보령시' },
  { name: '충청도_아산시', value: '충청도_아산시' },
  { name: '충청도_천안시', value: '충청도_천안시' },
  { name: '전라도_군산시', value: '전라도_군산시' },
  { name: '제주도_서귀포시', value: '제주도_서귀포시' },
  { name: '제주도_제주시', value: '제주도_제주시' }
];
const areaDatas = [
  { name: '10평 이하', value: 5 },
  { name: '10평대', value: 10 },
  { name: '20평대', value: 20 },
  { name: '30평대', value: 30 },
  { name: '40평대', value: 40 },
  { name: '50평 이상', value: 50 }
];

const memberCountDatas = [
  { name: '1명', value: 1 },
  { name: '2명', value: 2 },
  { name: '3명', value: 3 },
  { name: '4명', value: 4 },
  { name: '5명', value: 5 },
  { name: '6명 이상', value: 6 }
];

function Condition({ condition, handleChangeCondition, contractsData }) {
  return (
    <>
      <div className="condition">
        <p className="label">조건</p>
        <div className="detail">
          <div className="">
            <ConditionSelect
              id="conditionCity"
              datas={cityDatas}
              condition={condition}
              handleChangeCondition={handleChangeCondition}
            />
          </div>
          <div className="">
            <ConditionSelect
              id="conditionArea"
              datas={areaDatas}
              condition={condition}
              handleChangeCondition={handleChangeCondition}
            />
          </div>
          <div className="">
            <ConditionSelect
              id="conditionMemberCount"
              datas={memberCountDatas}
              condition={condition}
              handleChangeCondition={handleChangeCondition}
            />
          </div>
        </div>
      </div>
      <div className="condition">
        <p className="label">고객</p>
        {contractsData ? (
          <ConditionSelect
            id="conditionContracts"
            datas={contractsData}
            condition={condition}
            handleChangeCondition={handleChangeCondition}
          />
        ) : (
          <ConditionSelect
            id="conditionContracts"
            datas={contractsData}
            condition={condition}
            handleChangeCondition={handleChangeCondition}
          />
        )}
      </div>
    </>
  );
}
export default Condition;
