import React from 'react';
import GateStyled from '../components/cash/Gate.Styled';
import YouTube from 'react-youtube';
import MeterImg from '../resources/images/naverGate/img-meter.png';

function NaverGateMeter() {
  const videoOptions = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0
    }
  };
  return (
    <React.Fragment>
      <GateStyled>
        <div>
          <h3 className="title">모바일 자가검침</h3>
          <p className="sub-title">
            검침원 방문 없이
            <br />
            가스앱으로 계량기 찰칵!
            <br />
            간단하게 검침을 끝내보세요.
          </p>
          <div className="image-wrap">
            {/* <img src={MeterImg} alt="" style={{ marginBottom: '100px' }} /> */}
            <YouTube videoId={'hXlkw_tyjE4'} opts={videoOptions} />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              window.open('https://mobileservicecenter.page.link/livebill');
            }}
          >
            가스앱 설치하고 자가검침하기
          </button>
        </div>
      </GateStyled>
    </React.Fragment>
  );
}

export default NaverGateMeter;
