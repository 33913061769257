import styled from '@emotion/styled';

export const breakpoints = [768, 1024, 1280];
export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const IntegrationStyled = styled.section`
  padding: 270px 0 100px;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }

  > div {
    position: relative;
    padding: 0 40px 0 345px;
    ${mq[0]} {
      padding: 0;
    }

    figure {
      /* position: absolute;
      right: 60px; */

      ${mq[0]} {
        position: relative;
        text-align: center;
        right: auto;
      }

      .image {
        display: inline-block;
        position: absolute;
        top: -250px;
        right: 70px;

        ${mq[0]} {
          position: relative;
          top: unset;
          right: unset;
        }

        .img_house {
          z-index: 2;
          position: absolute;
          bottom: 10px;
          left: -70px;

          ${mq[0]} {
            display: none;
          }
        }

        .img_chart {
          z-index: 1;
          /* position: absolute;
          top: -240px;
          right: 90px; */

          ${mq[0]} {
            position: relative;
            width: 340px;
            top: auto;
            right: auto;
            bottom: 0;
          }
        }
      }
    }

    .contentsText {
      ${mq[0]} {
        padding: 0 14px;
        max-width: 380px;
        margin: 0 auto;
      }
    }

    span {
      font-size: 20px;
      display: block;
      margin-bottom: 15px;
      color: rgba(0, 0, 0, 0.4);
      ${mq[0]} {
        font-size: 10px;
      }
    }
    h2 {
      font-size: 60px;
      margin-bottom: 18px;
      ${mq[0]} {
        font-size: 30px;
        margin-bottom: 15px;
      }
    }
    p {
      font-family: 'NanumSquareL';
      font-size: 20px;
      line-height: 1.75;
      strong {
        font-family: 'NanumSquareB';
      }
      ${mq[0]} {
        font-size: 13px;
        margin-bottom: 67px;
      }
    }
  }
`;

export default IntegrationStyled;
