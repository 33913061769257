import React, { useCallback } from 'react';
import GasFeeSupportStyled from '../components/gasFeeSupport/GasFeeSupport.Styled';
import GasFeeSupportSmallImage from '../resources/images/gasFeeSupport/img_gas_fee_support_s.png';
import GasFeeSupportLargeImage from '../resources/images/gasFeeSupport/img_gas_fee_support_l.png';
import ButtonImage from '../resources/images/gasFeeSupport/img_button.png';
import styled from '@emotion/styled';
import { mq } from '../components/main/kor/GasApp.Styled';
// import useFirebase from '../hook/useFirebase';

const ImageBox = styled.div`
  width: 100%;
  display: inline-block;

  > img {
    width: 100%;
    &.small {
      display: none;
      ${mq[0]} {
        display: block;
      }
    }
    &.large {
      display: block;
      ${mq[0]} {
        display: none;
      }
    }
  }
`;

export const breakpoints = [768, 1024, 1280];

const Button = styled.div`
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 6%;

  ${mq[0]} {
    bottom: 3%;
  }

  > div {
    display: inline-block;
    min-width: 410px;
    width: calc(100vw / 3);

    ${mq[0]} {
      width: 90%;
      min-width: 0;
    }
    > img {
      width: 100%;
    }
  }
`;

function GasFeeSupport() {
  const onClickButton = useCallback(() => {
    window.gtag('event', '가스비_지원_캠페인_가스앱설치');
    const url = `https://mobileservicecenter.page.link/gasFeeSupport`;
    window.open(url);
  }, []);

  return (
    <React.Fragment>
      <GasFeeSupportStyled>
        <ImageBox>
          <img
            className="small"
            src={GasFeeSupportSmallImage}
            alt="gasFeeSupport"
          />
          <img
            className="large"
            src={GasFeeSupportLargeImage}
            alt="gasFeeSupport"
          />
        </ImageBox>
        <Button>
          <div onClick={onClickButton}>
            <img src={ButtonImage} alt="button" />
          </div>
        </Button>
      </GasFeeSupportStyled>
    </React.Fragment>
  );
}

export default GasFeeSupport;
