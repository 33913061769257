import React, { useState, useEffect, useRef } from 'react';
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  AreaChart,
  Area
} from 'recharts';
import ChartActive from '../../resources/images/chargeUsage/chart-active.svg';
import _ from 'lodash';

const CustomizedAxisTick = ({ x, y, payload }) => {
  let fontSize = 16;
  let fontWeight = 'bold';
  let fontColor = '#888';
  let monthString = payload.value.substr(4, 2);
  let yearString = '';
  if (monthString === '01') {
    yearString = payload.value.substr(2, 2) + '년';
    monthString = Number(payload.value.substr(4, 2)) + '월';
  } else {
    monthString = Number(monthString) + '월';
  }
  let labelComponent = (
    <>
      <text
        x={x}
        y={y - 50}
        dy={10}
        fill={fontColor}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAnchor="middle"
      >
        {monthString}
      </text>
      <text
        x={x}
        y={y - 50}
        dy={30}
        fill={'#aaa'}
        fontSize={14}
        textAnchor="middle"
      >
        {yearString}
      </text>
    </>
  );
  return labelComponent;
};

const CustomizedDot = props => {
  const { cx, cy, stroke, payload, activeRequestYm, chartUnitWidth } = props;
  let dotBackgroundColor = '#29b6f6';
  let fontSize = 12;
  let fontWeight = 'bold';
  let fontColor = stroke;
  let dotComponent = null;
  let labelComponent = null;

  if (payload.requestYm === activeRequestYm) {
    dotComponent = (
      // <ChartSelectedDotIcon x={cx - 25} y={cy - 25} width={50} height={50} />
      <svg x={cx - 20} y={cy - 20} width={100} height={100}>
        <circle
          cx="20"
          cy="22"
          r="12"
          stroke="#ccc"
          strokeWidth="9"
          fillOpacity="1"
          fill={'#29b6f6'}
        ></circle>
        <circle
          cx="20"
          cy="20"
          r="12"
          stroke="#fff"
          strokeWidth="9"
          fillOpacity="1"
          fill={'#29b6f6'}
        ></circle>
      </svg>
    );
  } else {
    dotComponent = (
      <svg x={cx - 10} y={cy - 10} width={100} height={100}>
        <circle
          cx="10"
          cy="10"
          r="5"
          stroke="#29b6f6"
          strokeWidth="2"
          fillOpacity="1"
          fill={dotBackgroundColor}
        ></circle>
      </svg>
    );
  }
  if (payload.requestYm === activeRequestYm) {
    let monthString = activeRequestYm.substr(4, 2);
    let yearString = '';
    if (monthString === '01') {
      yearString = activeRequestYm.substr(2, 2) + '년';
      monthString = Number(activeRequestYm.substr(4, 2)) + '월';
    } else {
      monthString = Number(monthString) + '월';
    }
    fontSize = 12;
    fontWeight = 'bold';
    labelComponent = (
      <React.Fragment>
        {/* 월 배경 흰색 */}
        <rect
          x={cx - chartUnitWidth / 2}
          y={220}
          width={chartUnitWidth}
          height={100}
          fill={'#fff'}
        />
        {/* 선택된 월 오퍼시티 박스 */}
        <rect
          x={cx - chartUnitWidth / 2}
          y={30}
          width={chartUnitWidth}
          height={290}
          fill={'#E7E7E74D'}
        />
        {/* 선택된 월 하단 파란 바 */}
        <rect
          x={cx - chartUnitWidth / 2}
          y={296}
          width={chartUnitWidth}
          height={6}
          fill={'#29b6f6'}
        />
        {/* 선택된 월 데이터 라운드 박스 */}
        <image
          x={cx - chartUnitWidth + 30}
          y={cy - 80}
          width="124"
          height="59"
          href={ChartActive}
        />
        {/* 선택된 월 강조 */}
        <text
          x={cx}
          y={250}
          fill={'#29b6f6'}
          fontSize={22}
          fontWeight={900}
          textAnchor="middle"
        >
          {monthString}
        </text>
        {yearString && (
          <text
            x={cx}
            y={273}
            fill={'#29b6f6'}
            fontSize={16}
            fontWeight={400}
            textAnchor="middle"
          >
            {yearString}
          </text>
        )}
        {/* 선택된 월 요금 */}
        {payload.usefee && (
          <>
            <text
              x={cx}
              y={cy - 57}
              fill={'#fff'}
              fontSize={18}
              fontWeight={700}
              text-anchor="middle"
            >
              <tspan>{payload.usefee.toLocaleString()}</tspan>
              <tspan fontSize={15} dx={1} dy={-1}>
                원
              </tspan>
            </text>
          </>
        )}

        {/* 선택된 월 사용량 */}
        <text
          x={cx}
          y={cy - 38}
          fill={'#fff'}
          fontSize={14}
          fontWeight={400}
          textAnchor="middle"
        >
          {payload.usage}㎥
        </text>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelComponent}
      {dotComponent}
    </React.Fragment>
  );
};

function UsageRecent({
  usageData,
  changeActiveRequestYm,
  activeRequestYm,
  condition
}) {
  const [chartUnitWidth, setChartUnitWidth] = useState();
  const [chartWidth, setChartWidth] = useState();
  const containerRef = useRef(null);

  // 사용계약번호를 선택했을때 초기화
  useEffect(() => {
    if (!usageData[0].usage) {
      changeActiveRequestYm(null, usageData.length - 1);
    }
    if (!activeRequestYm && usageData[0].usage) {
      changeActiveRequestYm(
        usageData[usageData.length - 1].requestYm,
        usageData.length - 1
      );
    }
    console.log('UsageRecent : ' + JSON.stringify(usageData));
    return () => {};
  }, [activeRequestYm, changeActiveRequestYm, condition, usageData]);

  // 차트의 개별 width 사이즈
  useEffect(() => {
    if (document.getElementById('charts-year')) {
      let unitWidth = Math.floor(1280 / (usageData.length - 1));
      let chartWidth = usageData.length * unitWidth;
      setChartUnitWidth(unitWidth);
      setChartWidth(chartWidth);
    }
    return () => {};
  }, [usageData.length]);

  useEffect(() => {
    scrollToRight();
    const container = containerRef.current;
    if (container) {
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;
      const maxScrollLeft = scrollWidth - clientWidth;
      console.log(scrollWidth, clientWidth, maxScrollLeft, activeRequestYm);
      if (activeRequestYm) {
        let searchIndex = _.findIndex(usageData, info => {
          return info.requestYm === activeRequestYm;
        });
        if (searchIndex !== -1) {
          let scrollLeft =
            chartUnitWidth * (searchIndex - 1) - chartUnitWidth / 2;
          container.scrollTo({
            left: scrollLeft,
            behavior: 'smooth'
          });
        }
      } else {
        container.scrollTo({
          left: maxScrollLeft,
          behavior: 'smooth'
        });
      }
    }
    return () => {};
  }, [activeRequestYm, chartUnitWidth, chartWidth, usageData]);

  const scrollToRight = () => {};

  return (
    <>
      <div className="charts-year active" id="charts-year">
        <p className="sub_title">최근 사용량</p>
        <div className="charts-caption">
          <div className="caption">
            {activeRequestYm && (
              <p className="">
                <span className="square home"></span>우리집
              </p>
            )}

            <p className="">
              <span className="square same"></span>
              <span className="text">
                동일 그룹 분포 ({condition.conditionCity.name},{' '}
                {condition.conditionArea.name},{' '}
                {condition.conditionMemberCount.value}인 가구)
              </span>
            </p>
          </div>
        </div>
        <div className="" style={{ overflowY: 'hidden' }} ref={containerRef}>
          <AreaChart
            width={chartWidth}
            height={330}
            data={usageData}
            margin={{
              top: 20,
              right: 10,
              bottom: 20
            }}
            padding={{ left: 60 }}
            onClick={options => {
              if (activeRequestYm) {
                if (
                  options &&
                  options.activePayload &&
                  options.activePayload.length
                ) {
                  let payload = options.activePayload[0].payload;
                  let requestYm = payload.requestYm;
                  changeActiveRequestYm(requestYm, options.activeTooltipIndex);
                }
              }
            }}
          >
            <defs>
              <linearGradient id="roundedGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
              </linearGradient>
              <mask id="roundedMask">
                <rect
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                  rx="100"
                  ry="100"
                  fill="white"
                />
              </mask>
            </defs>
            <XAxis
              dataKey="requestYm"
              height={30}
              orientation="bottom"
              mirror={false}
              reserved={true}
              axisLine={false}
              tickLine={false}
              interval={0}
              // tick={{ fontSize: 16, fill: '#888888' }}
              padding={{
                left: chartUnitWidth / 1.5,
                right: chartUnitWidth / 1.5
              }}
              // tickFormatter={}
              tick={<CustomizedAxisTick />}
            />
            <YAxis padding={{ top: 40, bottom: 65 }} hide={true} />
            <Area
              // type="monotone"
              dataKey="minMaxUsage"
              // stroke="#8884d8"
              stroke={0}
              fill="#29B6F629"
              isAnimationActive={false}
            />
            <CartesianGrid
              vertical={false}
              horizontal={true}
              horizontalPoints={[30, 93, 156, 220]}
              strokeDasharray="1 0"
              stroke={'#AAAAAA2E'}
              fillOpacity={1}
            />
            {activeRequestYm && (
              <Area
                // type="monotone"
                dataKey="usage"
                stroke="#29b6f6"
                strokeWidth={3}
                activeDot={true}
                fillOpacity={0}
                dot={
                  <CustomizedDot
                    activeRequestYm={activeRequestYm}
                    dataLength={usageData}
                    chartWidth={chartWidth}
                    chartUnitWidth={chartUnitWidth}
                  />
                }
                isAnimationActive={false}
              />
            )}

            {/* )} */}
          </AreaChart>
        </div>
      </div>
    </>
  );
}

export default UsageRecent;
