import styled from '@emotion/styled';
import { toRem } from '../../utils/utils';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const EnergyBodyStyled = styled.section`
  > div {
    padding: 0;
  }
  .contents {
    padding: ${toRem(50)} ${toRem(70)};
    color: #000;
    .content_group {
      padding-bottom: ${toRem(100)};
    }
    h3 {
      font-family: 'NanumSquareB';
      font-size: ${toRem(70)};
      margin-bottom: ${toRem(50)};
      display: inline-block;
      padding: 0 ${toRem(10)};
      position: relative;
      line-height: 1.4em;
      letter-spacing: -1px;
      background: rgb(191, 233, 252);
      background: linear-gradient(
        0deg,
        rgba(191, 233, 252, 1) 0%,
        rgba(191, 233, 252, 1) 40.5%,
        rgba(255, 255, 255, 1) 41%
      );
    }
    .text {
      font-family: 'NanumSquareR';
      font-size: ${toRem(45)};
      line-height: 1.4em;
      &.bold,
      .bold {
        font-family: 'NanumSquareB';
      }
    }
    .s_text {
      font-family: 'NanumSquareR';
      font-size: ${toRem(40)};
      line-height: 1.4em;
      letter-spacing: -0.03em;
      &.bold {
        font-family: 'NanumSquareB';
      }
      &.ss {
        font-size: ${toRem(38)};
      }
    }

    .info_text {
      font-family: 'NanumSquareR';
      font-size: ${toRem(40)};
      color: #888;
      line-height: 1.4em;
    }
  }
  .info_table {
    width: 100%;
    margin-bottom: ${toRem(50)};
    th {
      background: #f3f3f3;
      text-align: center;
      font-size: ${toRem(40)};
      padding: ${toRem(30)} ${toRem(10)};
      &.border_left {
        border-left: 1px solid rgba(151, 151, 151, 0.1);
      }
      &.border_bottom {
        border-bottom: 1px solid rgba(151, 151, 151, 0.1);
      }
      .small {
        font-size: ${toRem(30)};
      }
    }
    td {
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
      padding: ${toRem(30)} ${toRem(10)};
      font-size: ${toRem(40)};
      text-align: left;
      vertical-align: top;
      line-height: 1.5em;
      .small {
        font-size: ${toRem(30)};
      }
      p {
        margin: ${toRem(20)} 0;
        &.m0 {
          margin: 0;
        }
      }
    }
    thead.sm th {
      font-size: ${toRem(35)};
    }
    tbody.sm td {
      font-size: ${toRem(35)};
    }
    &.sm {
      th,
      td {
        font-size: ${toRem(37)};
      }
    }
    &.left_align {
      th,
      td {
        padding: ${toRem(30)} ${toRem(30)};
      }
    }
  }

  .flex_box {
    display: flex;
    align-items: baseline;
    .t1 {
      flex-basis: ${toRem(280)};
      flex-shrink: 0;
    }
  }

  .dot_list {
    li {
      display: flex;
      margin-bottom: ${toRem(20)};
      line-height: 1.4em;
      .dot {
        flex-basis: ${toRem(13)};
        flex-shrink: 0;
        display: block;
        width: ${toRem(13)};
        height: ${toRem(13)};
        border-radius: ${toRem(13)};
        background: #000;
        margin-right: ${toRem(20)};
        margin-top: ${toRem(21)};
      }
    }
    &.sm {
      li {
        line-height: 1.2em;
        font-size: ${toRem(38)};
        .dot {
          flex-basis: ${toRem(10)};
          flex-shrink: 0;
          display: block;
          width: ${toRem(10)};
          height: ${toRem(10)};
          border-radius: ${toRem(10)};
          background: #000;
          margin-right: ${toRem(15)};
          margin-top: ${toRem(15)};
        }
      }
    }
  }

  .left {
    text-align: left !important;
  }
  .center {
    text-align: center !important;
  }
  .mb10 {
    margin-bottom: ${toRem(10)};
  }
  .mb20 {
    margin-bottom: ${toRem(20)};
  }
  .mb30 {
    margin-bottom: ${toRem(30)};
  }
  .mb70 {
    margin-bottom: ${toRem(70)};
  }
  .ml30 {
    margin-left: ${toRem(50)};
  }
  .mt30 {
    margin-top: ${toRem(30)};
  }
  .text_pad {
    padding-bottom: ${toRem(50)};
  }
  .underline {
    cursor: pointer;
    color: #0094ff;
    border-bottom: 1px solid #0094ff;
  }
  .primary_btn {
    width: 100%;
    font-family: 'NanumSquareB';
    font-size: ${toRem(55)};
    color: #fff;
    height: ${toRem(160)};
    border-radius: ${toRem(20)};
    text-align: center;
    background: #29b6f6;
    margin-bottom: ${toRem(30)};
  }
`;

export default EnergyBodyStyled;
