import React, { useState, useEffect } from 'react';
import EffectStyled from './Effect.Styled';
import {
  effect_1,
  effect_2,
  effect_3,
  effect_4,
  effect_5
} from '../../../resources/images';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { css } from '@emotion/core';
import useInterval from '../../../hook/useInterval';
import { contents } from '../../../constants/contents';

const id = 'EFFECT';

export default function Effect(props) {
  const [no, setNo] = useState(2);
  const [delay, setDelay] = useState(1500);
  const [isRunning, setIsRunning] = useState(false);

  useInterval(
    () => {
      setNo(no => {
        let number = no < 4 ? no + 1 : 0;
        return number;
      });
    },
    isRunning ? delay : null
  );

  useEffect(() => {
    setDelay(1500);
    setIsRunning(true);
    return () => {
      setIsRunning(false);
    };
  }, []);

  const overThumb = e => {
    setIsRunning(false);
    setNo(parseInt(e.currentTarget.getAttribute(`name`)));
  };

  const outThumb = e => {
    setIsRunning(true);
  };

  return (
    <EffectStyled id={id}>
      <div>
        <Controller>
          <Scene indicators={false} duration="200" offset={-250}>
            <Timeline
              wrapper={
                <div
                  css={css`
                    overflow: hidden;
                  `}
                />
              }
            >
              <Tween
                position="0"
                stagger={0.2}
                staggerFrom={{
                  opacity: 0,
                  y: 100
                }}
                staggerTo={{
                  opacity: 1,
                  y: 0
                }}
              >
                {contents.kor[id].content1}
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
        <Controller>
          <Scene indicators={false} duration="200" offset={-200}>
            <Timeline>
              <Tween
                position="0"
                from={{
                  opacity: 0,
                  y: 100
                }}
                to={{
                  opacity: 1,
                  y: 0
                }}
              >
                <ul>
                  <li className={no === 0 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={0}>
                      <img src={effect_1} alt={``} />
                    </i>
                    {contents.kor[id].content2}
                  </li>
                  <li className={no === 1 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={1}>
                      <img src={effect_2} alt={``} />
                    </i>
                    {contents.kor[id].content3}
                  </li>
                  <li className={no === 2 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={2}>
                      <img src={effect_3} alt={``} />
                    </i>
                    {contents.kor[id].content4}
                  </li>
                  <li className={no === 3 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={3}>
                      <img src={effect_4} alt={``} />
                    </i>
                    {contents.kor[id].content5}
                  </li>
                  <li className={no === 4 ? `active` : ''}>
                    <i onMouseOver={overThumb} onMouseOut={outThumb} name={4}>
                      <img src={effect_5} alt={``} />
                    </i>
                    {contents.kor[id].content6}
                  </li>
                </ul>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </EffectStyled>
  );
}
