import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import Img01 from '../resources/images/winterEvent/eco/img-1.png';
import Img02 from '../resources/images/winterEvent/eco/img-2-n.png';
import Img02_1 from '../resources/images/winterEvent/eco/img-2-1.png';
import Img02_2 from '../resources/images/winterEvent/eco/img-2-2.png';
import Img03 from '../resources/images/winterEvent/eco/img-3.png';
import Img04 from '../resources/images/winterEvent/eco/img-4.png';
import Img05 from '../resources/images/winterEvent/eco/img-5.png';
import ImgBtn1 from '../resources/images/winterEvent/eco/img-btn1.png';
import ImgBtn2 from '../resources/images/winterEvent/eco/img-btn2.png';
// import ImgNoti from '../resources/images/lucky/img-noti.png';
// import ImgButton from '../resources/images/lucky/img-button.png';
import { Stack } from '../utils/utils';
import DeviceUtil from '../utils/DeviceUtil';

const DefaultBox = styled.div`
  width: 100%;

  margin: 0 auto;
  text-align: center;
  > img {
    max-width: 360px;
  }
  &.gray {
    background: #bbbbbb26;
  }
`;

function WinterEventEco() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [env, setEnv] = useState('production');
  useEffect(() => {
    const env = queryParams.get('env');
    setEnv(env ? env : 'production');
  }, [queryParams]);

  return (
    <div
      style={{
        maxWidth: DeviceUtil.isMobile ? '500px' : '360px',
        background: '#fff',
        margin: '0 auto'
      }}
    >
      <Stack direction="column" style={{ gap: '10px' }}>
        <img src={Img01} alt="" width="100%" />
        <Stack direction="column">
          <img src={Img02} alt="" width="100%" />
          <div style={{ position: 'relative' }}>
            <img src={Img02_1} alt="" width="100%" />
            <div
              style={{ position: 'absolute', left: 0, top: 0 }}
              onClick={() => {
                let url = null;
                if (env === 'production') {
                  url = `https://gasappmobile.page.link/winterEventEco`;
                } else {
                  url = `https://gasappdev.page.link/winterEventEco`;
                }

                window.open(url);
              }}
            >
              <img src={ImgBtn1} alt="" width="100%" />
            </div>
          </div>
          <img src={Img02_2} alt="" width="100%" />
          <img src={Img03} alt="" width="100%" />
          <img src={Img04} alt="" width="100%" />

          <div style={{ position: 'relative' }}>
            <img src={Img05} alt="" width="100%" />
            <div
              style={{ position: 'absolute', left: 0, bottom: 0 }}
              onClick={() => {
                let url = null;
                if (env === 'production') {
                  url = `https://gasappmobile.page.link/winterEventEco`;
                } else {
                  url = `https://gasappdev.page.link/winterEventEco`;
                }

                window.open(url);
              }}
            >
              <img src={ImgBtn2} alt="" width="100%" />
            </div>
          </div>
        </Stack>
      </Stack>
    </div>
  );
}

export default WinterEventEco;
