import React, { useEffect } from 'react';
import EnergyBodyStyled from './EnergyBody.Styled';

export default function EnergyBody02(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <EnergyBodyStyled>
      <div className="contents">
        <div className="content_group">
          <h3>에너지바우처란?</h3>
          <p className="text">
            에너지 취약계층을 위해 에너지바우처(이용권)을 지급하여 전기,
            도시가스, 지역난방, 등유, LPG, 연탄을 구입할 수 있도록 지원하는 제도
          </p>
        </div>
        <div className="content_group">
          <h3>신청 대상</h3>
          <p className="text mb20">
            소득기준과 세대원 특성기준을 모두 충족하는 세대
          </p>
          <p className="s_text mb10">1) 소득 기준</p>
          <p className="s_text">
            「국민기초생활 보장법」에 따른 생계급여/의료급여/주거급여/교육급여
            수급자
          </p>
          <p className="info_text mb20">
            ※ 주거/교육급여 수급자는 '22년 한시적 지원대상
          </p>
          <p className="s_text mb10">2) 세대원 특정 기준</p>
          <ul className="dot_list sm ml30">
            <li>
              <span className="dot"> </span>
              <span>노인 (1957. 12. 31 이전 출생자)</span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>영유아 (2016. 01. 01 이후 출생자)</span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>장애인 : 「장애인복지법」에 따라 등록한 장애인</span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>임산부 : 임신 중이거나 분만 후 6개월 미만인 여성</span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>중증질환자, 희귀질환자, 중증난치질환자</span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>한부모가족</span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>소년소녀가정</span>
            </li>
          </ul>
        </div>
        <div className="content_group">
          <h3>바우처 지원 금액</h3>
          <table className="info_table sm">
            <colgroup>
              <col />
              <col width="22%" />
              <col width="22%" />
              <col width="22%" />
              <col width="24%" />
            </colgroup>
            <thead>
              <tr>
                <th>구분</th>
                <th>1인 세대</th>
                <th>2인 세대</th>
                <th>3인 세대</th>
                <th>
                  4인 이상 <span className="small">세대</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">여름</td>
                <td className="center">29,600원</td>
                <td className="center">44,200원</td>
                <td className="center">65,500원</td>
                <td className="center">93,500원</td>
              </tr>
              <tr>
                <td className="center">겨울</td>
                <td className="center">248,200원</td>
                <td className="center">334,800원</td>
                <td className="center">445,400원</td>
                <td className="center">583,600원</td>
              </tr>
              <tr>
                <td className="center">총액</td>
                <td className="center">277,800원</td>
                <td className="center">379,000원</td>
                <td className="center">510,900원</td>
                <td className="center">677,100원</td>
              </tr>
            </tbody>
          </table>
          <p className="info_text mb10">
            ※ 2022년도 에너지바우처 최종 지원금액 (월별 지원금 아님){' '}
          </p>
          <p className="info_text">
            ※ 「취약계층 난방비 지원대책(’23.1.26, 대통령실)」 발표에 따른
            동절기 에너지바우처 지원 인상 금액 포함, ’23년 2월 8일 오전 9시 이후
            적용
          </p>
        </div>
        <div className="content_group">
          <h3>신청 기간</h3>
          <p className="text">2023년 2월 28일(화)까지</p>
        </div>
        <div className="content_group">
          <h3>신청 방법</h3>
          <p className="text bold">방문 신청</p>
          <p className="text text_pad">
            주민등록상 거주지 읍·면·동 행정복지센터
          </p>
          <p className="text bold">온라인 신청</p>
          <p className="text">복지로 www.bokjiro.go.kr</p>
        </div>
        <div className="content_group">
          <button
            className="primary_btn"
            onClick={() => {
              window.open(
                'https://www.bokjiro.go.kr/ssis-tbu/twataa/wlfareInfo/moveTWAT52011M.do?wlfareInfoId=WLF00000072&wlfareInfoReldBztpCd=01'
              );
            }}
          >
            에너지 바우처 신청 (복지로)
          </button>
          <p className="info_text mt30">
            ※ 자세한 사항은 관할 지자체로 문의하시거나{' '}
            <span
              className="underline"
              onClick={() => {
                window.open('https://www.energyv.or.kr/info/support_info.do');
              }}
            >
              에너지바우처 홈페이지
            </span>
            를 참고해 주세요.
          </p>
        </div>
      </div>
    </EnergyBodyStyled>
  );
}
