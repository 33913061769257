import * as React from 'react';
import { css } from '@emotion/core';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { ami_phone } from '../../../resources/images';
import { ami_indicator } from '../../../resources/images';
import { ami_circle } from '../../../resources/images';
import AmiStyled from './Ami.Styled';
import { contents } from '../../../constants/contents';

const id = 'AMI';

function Ami() {
  return (
    <AmiStyled id={id}>
      <div>
        <div className={`contentsText`}>
          <Controller>
            <Scene indicators={false} duration="200" offset={-250}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  stagger={0.2}
                  staggerFrom={{
                    opacity: 0,
                    y: 100
                  }}
                  staggerTo={{
                    opacity: 1,
                    y: 0
                  }}
                >
                  {contents.kor[id].content1}
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
        <figure>
          <Controller>
            <Scene indicators={false} duration="200" offset={-100}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    scale: 0.5
                  }}
                  to={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <img className={`circle`} src={ami_circle} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-200}>
              <Timeline
                wrapper={
                  <div
                    css={css`
                      overflow: hidden;
                    `}
                  />
                }
              >
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: -120,
                    y: 70,
                    rotation: -20
                  }}
                  to={{
                    opacity: 1,
                    x: 0,
                    y: 0,
                    rotation: 0
                  }}
                >
                  <img className={`indicator`} src={ami_indicator} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
            <Scene indicators={false} duration="200" offset={-150}>
              <Timeline wrapper={<div />}>
                <Tween
                  position="0"
                  from={{
                    opacity: 0,
                    x: 100
                  }}
                  to={{
                    opacity: 1,
                    x: 0
                  }}
                >
                  <img className={`phone`} src={ami_phone} alt={``} />
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </figure>
      </div>
    </AmiStyled>
  );
}

export default Ami;
