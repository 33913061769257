import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const PrivateHomeStyled = styled.section`
  padding: 203px 0;
  text-align: left;
  ${mq[0]} {
    padding: 75px 0;
  }
  > div {
    position: relative;
    padding: 0 40px 0 410px;
    ${mq[0]} {
      padding: 0;
    }
    figure {
      position: relative;
      ${mq[0]} {
        text-align: center;
      }
      .phone {
        position: absolute;
        left: 82px;
        top: 50px;
        width: 230px;
        ${mq[0]} {
          position: relative;
          width: 133px;
          left: auto;
          top: auto;
          margin-left: 17px;
        }
      }
      .phone_scene {
        position: absolute;
        top: 111px;
        left: -66px;
        ${mq[0]} {
          top: 36px;
          left: calc(50% - 150px);
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        li {
          float: left;
          margin-left: 15px;
          width: 155px;
          overflow: hidden;
          border-radius: 8px;
          box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
          ${mq[0]} {
            width: 90px;
          }
          img {
            width: 495px;
            ${mq[0]} {
              width: 285px;
            }
          }
          &.scene2 {
            img {
              margin-left: -170px;
              ${mq[0]} {
                margin-left: -98px;
              }
            }
          }
          &.scene3 {
            img {
              margin-left: -340px;
              ${mq[0]} {
                margin-left: -195px;
              }
            }
          }
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
    .contents {
      padding-left: 340px;
      padding-top: 314px;

      &.pc {
        ${mq[0]} {
          display: none;
        }
      }
      &.m {
        display: none;
        ${mq[0]} {
          display: block;
          padding: 0 14px;
          margin: 0 auto 30px;
          max-width: 380px;
        }
      }
      span {
        display: block;
        font-size: 20px;
        margin-bottom: 15px;
        color: rgba(0, 0, 0, 0.4);
        ${mq[0]} {
          font-size: 10px;
        }
      }
      h2 {
        font-size: 60px;
        margin-bottom: 23px;
        ${mq[0]} {
          font-size: 30px;
          margin-bottom: 15px;
        }
        &.en {
          line-height: 1em;
        }
      }
      p {
        font-family: 'NanumSquareL';
        font-size: 20px;
        line-height: 1.75;
        strong {
          font-family: 'NanumSquareB';
        }
        ${mq[0]} {
          word-break: keep-all;
          font-size: 13px;
          .pc {
            display: none;
          }
        }
      }
    }
  }
`;
export default PrivateHomeStyled;
