import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const IntroStyled = styled.section`
  padding: 450px 0px;
  background: #fff;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  ${mq[0]} {
    padding: 200px 0;
  }
  div {
    padding: 0;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .line {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    span {
      display: inline-block;
      top: 0;
      left: 50%;
      border-left: 1px solid #ffb9b9;
      height: 100%;
    }
  }

  .contents {
    position: relative;
    display: inline-block;
    color: #ff6666;
    background: #fff;
    padding: 133px 40px;
    ${mq[0]} {
      padding: 107px 40px;
    }
    span {
      display: block;
      font-size: 50px;
      ${mq[0]} {
        font-size: 25px;
      }
    }
    p {
      font-family: 'NanumSquareB';
      font-size: 80px;
      line-height: 1;
      ${mq[0]} {
        font-size: 40px;
      }
    }
  }
`;
export default IntroStyled;
