import { css } from '@emotion/core';
import {
  NSL_eot,
  NSL_woff,
  NSL_ttf,
  NSR_eot,
  NSR_woff,
  NSR_ttf,
  NSB_eot,
  NSB_woff,
  NSB_ttf,
  NSEB_eot,
  NSEB_woff,
  NSEB_ttf
} from '../resources/fonts';

export const NanumSquareFont = css`
  @font-face {
    font-family: 'NanumSquareL';
    font-weight: 300;
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareL.eot),
      url(${NSL_eot});
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareL.eot?#iefix)
        format('embedded-opentype'),
      url(${NSL_eot}?#iefix) format('embedded-opentype'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareL.woff)
        format('woff'),
      url(${NSL_woff}) format('woff'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareL.ttf)
        format('truetype'),
      url(${NSL_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'NanumSquareR';
    font-weight: 400;
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareR.eot),
      url(${NSR_eot});
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareR.eot?#iefix)
        format('embedded-opentype'),
      url(${NSR_eot}?#iefix) format('embedded-opentype'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareR.woff)
        format('woff'),
      url(${NSR_woff}) format('woff'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareR.ttf)
        format('truetype'),
      url(${NSR_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'NanumSquareB';
    font-weight: 700;
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareB.eot),
      url(${NSB_eot});
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareB.eot?#iefix)
        format('embedded-opentype'),
      url(${NSB_eot}?#iefix) format('embedded-opentype'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareB.woff)
        format('woff'),
      url(${NSB_woff}) format('woff'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareB.ttf)
        format('truetype'),
      url(${NSB_ttf}) format('truetype');
  }
  @font-face {
    font-family: 'NanumSquareEB';
    font-weight: 800;
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareEB.eot),
      url(${NSEB_eot});
    src: url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareEB.eot?#iefix)
        format('embedded-opentype'),
      url(${NSEB_eot}?#iefix) format('embedded-opentype'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareEB.woff)
        format('woff'),
      url(${NSEB_woff}) format('woff'),
      url(https://cdn-app.gasapp.co.kr/static/media/NanumSquareEB.ttf)
        format('truetype'),
      url(${NSEB_ttf}) format('truetype');
  }
`;

export const SuiteFont = css`
  @import url('https://cdn.jsdelivr.net/gh/sun-typeface/SUITE/fonts/static/woff2/SUITE.css');
`;

export const HakgyoansimDunggeunmisoFont = css`
  @font-face {
    font-family: 'HakgyoansimDunggeunmisoB';
    font-weight: 600;
    src: url(https://fastly.jsdelivr.net/gh/projectnoonnu/2408-5@1.0/HakgyoansimDunggeunmisoTTF-B.woff2)
      format('woff');
  }
  //@import url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-5@1.0/HakgyoansimDunggeunmisoTTF-B.woff2');
`;
