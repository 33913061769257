import styled from '@emotion/styled';
import { toRem } from '../../utils/utils';
import { selectArrow, distribution } from '../../resources/images';

const ChargeUsageStyled = styled.section`
  position: relative;
  font-family: 'NanumSquareR';
  font-size: 15px;
  overflow: inherit;
  @media (max-width: 1279px) {
    padding: 0 20px;
  }
  > div {
    margin: 0 auto;
    padding: 0;
  }
  .title {
    padding: 30px 0;
  }
  .sub_title {
    font-size: 22px;
    font-weight: 700;
  }

  .round_btn {
    width: 100%;
    border-radius: 2rem;
    padding: ${toRem(58)};
    overflow: hidden;
    text-align: center;
    color: #fff !important;
    font-family: 'nanumsquareB';
    font-size: ${toRem(56)};
    display: block;
  }
  .conditions-wrap {
    background: #fff;
    border-radius: 10px;
    padding: 26px 36px;
    display: flex;
    gap: 40px;
    @media (max-width: 769px) {
      gap: 20px;
      padding: 26px 20px;
    }
    .condition {
      display: flex;
      flex: 1 1 50%;
      gap: 30px;
      align-items: center;
      @media (max-width: 769px) {
        gap: 10px;
        flex-direction: column;
        align-items: inherit;
      }
      .label {
        display: block;
        font-size: 16px;
        font-weight: 700;
        word-break: keep-all;
      }

      .detail {
        flex-grow: 1;
        display: flex;
        gap: 10px;
        > div {
          flex: 1 1 33%;
        }
        @media (max-width: 769px) {
          flex-direction: column;
        }
      }
    }
    @media (max-width: 1279px) {
      flex-direction: column;
    }
  }
  .charts-box-wrap {
    margin-top: 10px;
    position: relative;
    border-radius: 10px 10px 0 0;
    background: #fff;
    overflow: hidden;
    height: 370px;
    @media (max-width: 1279px) {
      display: none;
      &.active {
        display: block;
      }
    }
    @media (max-width: 769px) {
      height: 416px;
    }
    .charts-caption {
      position: absolute;
      left: 0;
      top: 40px;
      width: 100%;
      display: grid;
      text-align: center;
      @media (max-width: 1279px) {
        position: inherit;
      }
      .caption {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 30px;

        @media (max-width: 769px) {
          display: block;
          flex-direction: column;
          gap: 10px;
          align-items: end;
          word-break: keep-all;
        }
        p {
          display: flex;
          align-items: center;
          @media (max-width: 769px) {
            display: block;
            text-align: right;
          }
          // br {
          //   display: none;
          //   @media (max-width: 769px) {
          //     display: block;
          //     text-align: left;
          //   }
          // }
          span.square {
            display: inline-block;
            width: 30px;
            height: 15px;
            margin-right: 16px;
            @media (max-width: 769px) {
              width: 20px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
          .text {
            // display: inline-block;
            // word-break: keep-all !important;
          }
          .home {
            height: 4.4px !important;
            background: #29b6f6;
            border-radius: 10px;
          }
          .same {
            background: #29b6f629;
          }
        }
      }
    }
    .charts-year {
      padding: 36px 50px 40px;
      @media (max-width: 1279px) {
        display: none;
        &.active {
          display: block;
        }
        padding: 26px 20px 20px;
      }
    }

    @media (max-width: 1279px) {
      flex-direction: column-reverse;
    }
  }
  .month-chart-wrap {
    height: 390px;
    background: rgba(255, 255, 255, 1);
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 10px 10px;
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 36px 0 50px;
    @media (max-width: 1279px) {
      height: auto;
      display: none;
      &.active {
        display: flex;
        margin-bottom: 20px;
        padding: 26px 0 20px;
      }
    }
    @media (max-width: 769px) {
      flex-direction: column;
    }
    > div {
      flex: 1 1 50%;
    }
    .proper-usage {
      padding: 0 50px;
      position: relative;
      @media (max-width: 1279px) {
        padding: 0 20px;
      }
      .etc {
        font-size: 22px;
        position: absolute;
        left: 50px;
        top: -50px;
        span {
          font-size: 20px;
          font-weight: 400;
        }
      }
      .charts {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .recharts-responsive-container {
          margin: 0 auto;
        }
      }
    }
    .usage-distribution {
      padding: 0 50px;
      border-left: 1px solid #aaaaaa66;
      @media (max-width: 1279px) {
        padding: 30px 20px 0;
      }
      .charts {
        display: flex;
        justify-content: center;
        .recharts-wrapper {
          margin: 0 auto;
          background: url(${distribution}) no-repeat center center;
        }
      }
      @media (max-width: 1279px) {
        border-left: none;
      }
    }
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 150px;
      opacity: 0.35;
      background: linear-gradient(
        180deg,
        #ededed 0%,
        rgba(217, 217, 217, 0) 100%
      );
    }
  }
  .select {
    display: block;
    width: 100%;
    position: relative;
    z-index: 10;
    @media (max-width: 1279px) {
      &.active {
        z-index: 20;
      }
    }
    .selected {
      border-radius: 10px;
      border: 1px solid rgba(170, 170, 170, 0.4);
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;
      .selected-value {
        white-space: nowrap; /* 줄바꿈 안함 */
        overflow: hidden;
        text-overflow: ellipsis; /* 말줄임 적용 */
      }
      .arrow {
        width: 12px;
        height: 7px;
        background: url(${selectArrow}) no-repeat left center;
      }
      @media (max-width: 640px) {
        height: 70px;
      }
    }
    .select-list {
      ul {
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(170, 170, 170, 0.4);
        position: absolute;
        top: 52px;
        background: #fff;
        cursor: pointer;
        max-height: 290px;
        overflow-y: auto;
        display: none;
        @media (max-width: 640px) {
          top: 74px;
        }
        li {
          padding: 16px 20px;
          height: 48px;
          font-size: 14px;
          @media (max-width: 640px) {
            height: 70px;
            padding: 24px 20px;
          }
          &:hover {
            background: rgba(187, 187, 187, 0.15);
          }
        }
        &.active {
          display: block;
        }
      }
    }
  }
  .dimmed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: grid;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #666;
  }
  .description {
    font-size: 18px;
    font-weight: 700;
    color: #242425;
    text-align: center;
    margin-top: 24px;
  }
`;

export default ChargeUsageStyled;
