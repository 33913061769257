import 'core-js/es/map';
import 'core-js/es/set';

import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ThemeContextProvider, theme } from '@gasapp/ui';

ReactDOM.render(
  <ThemeContextProvider theme={theme} mode="light">
    <App />
  </ThemeContextProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
