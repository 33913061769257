import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { toRemLucky, Text } from '../../utils/utils';

import { iconTimer } from '../../resources/images';
import moment from 'moment/moment';

const TimerWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${toRemLucky(136)};
  background: rgba(224, 224, 224, 0.65);
  gap: ${toRemLucky(10)};
  img {
    width: ${toRemLucky(60)};
  }
`;

function Timer({
  targetTime,
  winnersPopDatetime,
  nextRaffleFromDatetime,
  isOpened
}) {
  const [remainingTime, setRemainingTime] = useState('');
  let intervalId = null;
  useEffect(() => {
    // 목표 시간 설정 (예: 2023년 12월 31일 23:59:59)
    if (isOpened) {
      count();
      // 1초마다 현재 시간과 목표 시간 간의 차이 계산
      intervalId = setInterval(() => {
        count();
      }, 1000);
    } else {
      ready();
    }

    // 컴포넌트 언마운트 시 타이머 정리
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);
  const count = () => {
    const currentTime = moment();
    const duration = moment.duration(
      moment(targetTime, 'YYYY-MM-DD HH:mm:ss').diff(currentTime)
    );

    // 시, 분, 초 추출
    const days = duration.days();
    const hours = digitMatch(duration.hours());
    const minutes = digitMatch(duration.minutes());
    const seconds = digitMatch(duration.seconds());

    // 이 값을 활용해 원하는 형식으로 표시 (예: "3일 12시간 34분 56초")
    const formattedTime = `${days}일 ${hours}:${minutes}:${seconds} 남음`;

    setRemainingTime(formattedTime);

    // 목표 시간에 도달하면 타이머 종료
    if (currentTime.isSameOrAfter(targetTime)) {
      if (intervalId) {
        clearInterval(intervalId);
      }

      setRemainingTime(
        `당첨자 발표 : ${moment(winnersPopDatetime).format('YY/MM/DD HH시')}`
      );
    }
  };

  const ready = () => {
    setRemainingTime(
      `${moment(nextRaffleFromDatetime).format('MM/DD A HH시')} 오픈`
    );
  };

  const digitMatch = value => {
    return Number(value) < 10 ? '0' + value : '' + value;
  };

  return (
    <TimerWrap>
      <img src={iconTimer} alt="" />
      <Text fontFamily="SUITE" fontSize={52} fontWeight={700}>
        {remainingTime}
      </Text>
      {/* <Text
                      fontFamily="SUITE"
                      textSize={'52'}
                      textWeight={'b'}
                      textColor="black-fixed"
                    >
                      {remainingTime}
                    </Text> */}
    </TimerWrap>
  );
}

export default Timer;
