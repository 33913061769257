import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const EnergyBillStyled = styled.section`
  position: relative;
  font-family: 'NanumSquareR';
  font-size: 15px;
`;

export default EnergyBillStyled;
