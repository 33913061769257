import React from 'react';
import GateStyled from '../components/cash/Gate.Styled';
import YouTube from 'react-youtube';

function LuckyGate() {
  const videoOptions = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0
    }
  };
  return (
    <React.Fragment>
      <GateStyled>
        <div>
          <p className="sub-label">두근두근 0원 경품 응모 기회</p>
          <h3 className="title market">행운의 마켓</h3>
          <p className="sub-title">
            가스앱 회원 누구나
            <br />
            행운의 주인공이 될 수 있어요!
          </p>
          <div className="image-wrap">
            <YouTube videoId={'seZRMb8RIqo'} opts={videoOptions} />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              window.open('https://gasappmobile.page.link/luckyMarket');
            }}
          >
            가스앱 설치하고 응모하기
          </button>
        </div>
      </GateStyled>
    </React.Fragment>
  );
}

export default LuckyGate;
