import React, { useEffect } from 'react';
import EnergyBodyStyled from './EnergyBody.Styled';

export default function EnergyBody03(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <EnergyBodyStyled>
      <div className="contents">
        <div className="content_group">
          <h3>탄소포인트제란</h3>
          <p className="text">
            가정, 상업, 아파트단지 등에서 에너지(전기, 상수도, 도시가스)의
            사용량을 절감하고 감축률에 따라 탄소포인트를 부여하는 전국민
            온실가스 감축 실천 제도
          </p>
        </div>
        <div className="content_group">
          <h3>탄소포인트 지급 기준</h3>
          <p className="text mb70">
            과거 1~2년간 월별 평균 사용량과 현재 사용량을 비교해 절감비율에 따라
            월 2회 부여 (1 탄소포인트 = 최대 2원)
          </p>
          <div className="flex_box mb10">
            <p className="text  t1">
              <span className="bold">감축 인센티브</span>
            </p>
            <p className="s_text">감축률 5% 이상인 참여자에게 지급</p>
          </div>

          <table className="info_table mb70">
            <colgroup>
              <col />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>감축률</th>
                <th>전기</th>
                <th>상수도</th>
                <th>도시가스</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">5%이상~10%미만</td>
                <td className="center">5,000P</td>
                <td className="center">750P</td>
                <td className="center">3,000P</td>
              </tr>
              <tr>
                <td className="center">10%이상~15%미만</td>
                <td className="center">10,000P</td>
                <td className="center">1,500P</td>
                <td className="center">6,000P</td>
              </tr>
              <tr>
                <td className="center">15%이상</td>
                <td className="center">15,000P</td>
                <td className="center">2,000P</td>
                <td className="center">8,000P</td>
              </tr>
            </tbody>
          </table>
          <div className="flex_box mb10">
            <p className="text  t1">
              <span className="bold">유지 인센티브</span>
            </p>
            <p className="s_text">
              2회 이상 연속 5% 이상 감축하여 인세티브를 받은 후 0~5% 미만 감축률
              유지한 경우
            </p>
          </div>
          <table className="info_table mb70">
            <colgroup>
              <col />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>감축률</th>
                <th>전기</th>
                <th>상수도</th>
                <th>도시가스</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center">0%초과~5%미만</td>
                <td className="center">3,000P</td>
                <td className="center">450P</td>
                <td className="center">1,800P</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="content_group">
          <h3>인센티브 종류</h3>
          <p className="text mb10">
            현금, 상품권, 종량제 봉투, 지방세 납부, 교통카드, 기부, 상장,
            공공시설 이용 바우처
          </p>
          <p className="info_text">
            ※ 해당 지방자치단체에서 시행하는 제공 유형 중 1가지 선택 가능
          </p>
        </div>
        <div className="content_group">
          <h3>참여 조건 및 대상</h3>
          <ul className="dot_list">
            <li>
              <span className="dot"> </span>
              <span>
                에너지 사용량을 확인할 수 있는 계량기가 부착되어 있거나, 다른
                객관적인 방법으로 에너지 사용량 확인이 가능한 경우
              </span>
            </li>
            <li>
              <span className="dot"> </span>
              <span>
                가정의 세대주(세대 구성원) 또는 학교, 상업시설 등의 실제 사용자
              </span>
            </li>
          </ul>
        </div>
        <div className="content_group">
          <h3>신청 방법</h3>
          <p className="text bold">방문 신청</p>
          <p className="text text_pad">
            관할 시·군·구 담당 부서에 방문하여 참여 신청서 작성
          </p>
          <p className="text bold">온라인 신청</p>
          <p className="text">
            탄소포인트제 홈페이지
            <br />
            서울시 거주자의 경우, 에코마일리지 홈페이지
          </p>
        </div>
        <div className="content_group">
          <button
            className="primary_btn"
            onClick={() => {
              window.open('https://ecomileage.seoul.go.kr/home');
            }}
          >
            서울시 거주자 신청
          </button>
          <button
            className="primary_btn"
            onClick={() => {
              window.open('https://cpoint.or.kr/');
            }}
          >
            타지역 거주자 신청
          </button>
          <p className="info_text mt30">
            ※ 자세한 사항은 관할 지자체로 문의하시거나{' '}
            <span
              className="underline"
              onClick={() => {
                window.open('https://cpoint.or.kr');
              }}
            >
              탄소포인트제 홈페이지
            </span>
            를 참고해 주세요.
          </p>
        </div>
      </div>
    </EnergyBodyStyled>
  );
}
