import styled from '@emotion/styled';

const breakpoints = [768, 1024, 1280];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const SideMenuStyled = styled.aside`
  position: fixed;
  left: 0;
  top: calc(50% - 155px);
  width: 100%;
  z-index: 999;
  ${mq[0]} {
    display: none;
  }
  > div {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    ul {
      position: absolute;
      left: 40px;
      top: 0;
      padding-left: 3px;
      li {
        position: relative;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        button {
          padding: 0 24px;
          line-height: 3.34;
          background: transparent;
          font-size: 15px;
          line-height: 45px;
        }
        &.bullet {
          position: absolute;
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
      }
    }
  }
`;

export default SideMenuStyled;
