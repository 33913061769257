import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent, analytics } from './useFirebase';

export const usePageTracking = pageTitle => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname;

    // 전달받은 페이지 타이틀을 설정
    document.title = pageTitle;

    // Firebase Analytics에 페이지 뷰 이벤트 로깅
    logEvent(analytics, pageTitle, {
      page_path: pagePath,
      page_title: pageTitle
    });
  }, [location, pageTitle]);
};

export const clickTracking = buttonName => {
  logEvent(analytics, buttonName, { button_name: buttonName });
};
