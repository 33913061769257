import { css } from '@emotion/core';
import { NanumSquareFont } from './Fonts.Styled';

const ResetGasAppMobileStyled = css`
  ${NanumSquareFont}

  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  table,
  th,
  td,
  form,
  fieldset,
  legend,
  input,
  textarea,
  button,
  select,
  pre {
    -webkit-text-size-adjust: none;
    font-size: 0.96rem;
    letter-spacing: -0.03em;
    line-height: 1.4em;
    font-weight: 400;
    color: #000;
    font-family: 'NanumSquareR', 'noto sans', arial, sans-serif, Arial, dotum,
      '돋움';
    outline: none;
  }

  /*-webkit-text-size-adjust: none(글자크기 자동조정 방지) , auto (화면의 폭에 맞추어서 텍스트의 크기를 자동으로 조절 , 100%(폰트크기를 지정된사이즈로 변경);*/
  html,
  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  table,
  tr,
  td,
  dl,
  dt,
  dd,
  a,
  img,
  input,
  button,
  select,
  textarea {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  address,
  cite,
  dfn,
  em,
  var {
    font-style: normal;
  }

  code,
  kbd,
  samp {
    font-family: dotum, '돋움', courier new, courier, monospace;
  }

  header,
  hgroup,
  article,
  nav,
  footer,
  figure,
  figcaption,
  canvas,
  section,
  menu {
    display: block;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
  }

  /* 모바일에서 꾹 눌렀을때 텍스트 복사 막기 */
  html,
  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  a,
  img,
  div,
  span,
  button,
  legend {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
  }

  body,
  div,
  p,
  ul,
  li,
  input,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  dl,
  dt,
  dd,
  a,
  select,
  textarea {
    box-sizing: border-box;
  }

  img,
  fieldset,
  iframe {
    border: 0 none;
  }

  img {
    border: 0px;
    vertical-align: top;
  }

  table {
    /* border-collapse:collapse;table-layout:fixed; */
    border-collapse: collapse;
    border-spacing: 0;
  }

  legend {
    visibility: hidden;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
  }

  form {
    margin: 0px;
    padding: 0px;
  }

  fieldset {
    padding: 0;
    border: none;
  }

  caption {
    display: none;
  }

  label {
  }

  input,
  button {
    vertical-align: middle;
    border: none;
    background: none;
  }

  input {
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
  }

  input[type='checkbox'],
  input[type='radio'] {
    padding: 0;
    vertical-align: middle;
    margin-top: -3px;
    *margin-top: -2px;
  }

  input[type='text'],
  input[type='password'],
  input[type='file'],
  input[type='tel'],
  input[type='email'] {
    padding: 0;
  }

  input[type='button'],
  input[type='submit'] {
    cursor: pointer;
  }
  input:-internal-autofill-selected,
  input:-webkit-autofill {
    background-color: transparent !important;
  }

  a:link {
    text-decoration: inherit;
    color: inherit;
  }

  a:visited {
    text-decoration: inherit;
    color: inherit;
  }

  a:hover {
    text-decoration: inherit;
    color: inherit;
  }

  a:active {
    text-decoration: inherit;
    color: inherit;
  }

  a,
  button {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-size: 15px;
  }

  @media (max-width: 340px) {
    html {
      font-size: 15px;
    }
  }

  @media (min-width: 341px) and (max-width: 360px) {
    html {
      font-size: 15px;
    }
  }

  @media (min-width: 361px) and (max-width: 380px) {
    html {
      font-size: 16px;
    }
  }

  @media (min-width: 381px) {
    html {
      font-size: 17px;
    }
  }

  * {
    -webkit-tap-highlight-color: transparent;
    /* 터치 하이라이트 제거*/
    -webkit-overflow-scrolling: touch;
    /* ios 스크롤 스무스*/
    -ms-overflow-style: none;
    /* IE에서 스크롤바 감춤*/
  }

  ::-webkit-scrollbar {
    display: none !important;
    /* 윈도우 크롬 등 */
  }

  #root {
    height: 100%;
  }
`;

export default ResetGasAppMobileStyled;
