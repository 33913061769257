import React from 'react';
import GateStyled from '../components/cash/Gate.Styled';
import YouTube from 'react-youtube';
import RelocationImg from '../resources/images/naverGate/img-relocation.png';

function NaverGateRelocation() {
  const videoOptions = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0
    }
  };
  return (
    <React.Fragment>
      <GateStyled>
        <div>
          <h3 className="title">간편한 이사 준비</h3>
          <p className="sub-title">
            가스 철거, 이사 정산, 신규 계약 등<br />
            번거로운 이사 준비도
            <br />
            가스앱으로 한번에 해결하세요!
          </p>
          <div className="image-wrap">
            {/* <img src={RelocationImg} alt="" style={{ marginBottom: '100px' }} /> */}
            <YouTube videoId={'hckYq3KBm4g'} opts={videoOptions} />
          </div>
          <button
            className="round_btn bg_blue"
            onClick={() => {
              window.open('https://mobileservicecenter.page.link/livebill');
            }}
          >
            가스앱 설치하고 간편 이사예약
          </button>
        </div>
      </GateStyled>
    </React.Fragment>
  );
}

export default NaverGateRelocation;
